import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@core/tokens/window.token';

/**
 * Di token for the `isIE` boolean.
 * Represents whether the browser is IE or not.
 */
export const IS_IE = new InjectionToken<boolean>('Is IE', {
  factory: () => {
    const window = inject(WINDOW);
    return (
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1
    );
  },
});
