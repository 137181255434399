<div class="zero-card asset-import-card">
  <span
    class="zero-tag notification ddb"
    aria-label="Asset import help tooltip"
    tippy="This asset exists in DDB. Import it to create a corresponding Zero asset."
    matTooltipPosition="above"
    >DDB</span
  >
  <div class="chart">
    <img src="/assets/card_vector.svg" />
  </div>
  <div class="overview">
    <h1>{{ name }}</h1>
    <span>
      <button mat-stroked-button (click)="import.emit()">Import</button>
    </span>
  </div>
</div>
