import { Component, Input } from '@angular/core';

/**
 * External Link Component.
 *
 * Responsible for rendering an external link.
 * The links text is provided with the use of
 * content projection. The link is rendered
 * alongside an external link icon.
 */
@Component({
  selector: 'zero-external-link[href]',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent {
  /**
   * Href of the link
   */
  @Input() href!: string;
}
