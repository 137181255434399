import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubsystemName } from '@core/models/subsystem';

/**
 * Total Parameter Icon Component.
 * Responsible for rendering the total parameter icon.
 */
@Component({
  selector: 'zero-total-parameter-icon[subsystemName]',
  templateUrl: './total-parameter-icon.component.html',
  styleUrls: ['./total-parameter-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalParameterIconComponent {
  /**
   * Subsystem Name.
   * The name of the subsystem to render the icon for.
   */
  @Input() subsystemName!: SubsystemName;
}
