import { Pipe, PipeTransform } from '@angular/core';
import { SystemModel } from '@asset/models/system/system.model';

/**
 * System Cope Pipe.
 *
 * Responsible for filtering an array of
 * systems (of type `SystemModel`) by its
 * scope property.
 */
@Pipe({
  name: 'systemScope',
})
export class SystemScopePipe implements PipeTransform {
  transform(systems: Array<SystemModel>, scope: SystemModel['scope']) {
    return systems.filter((system) => system.scope === scope);
  }
}
