import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ConfigService } from '@core/services/config.service';
import { IS_IE } from '@core/tokens/is-ie.token';
import { PermissionsService } from '@auth/services/permissions.service';
import { ProjectManageGuard } from '@auth/guards/project-manage.guard';
import { AssetContributorGuard } from '@auth/guards/asset-contributor.guard';
import { AssetManagerGuard } from '@auth/guards/asset-manager.guard';

/**
 * Factory function to provide the MSAL instance
 * `MSAL_INSTANCE`. Requires the configuration
 * service and the `IS_IE` injection tokens as a dependency.
 */
function msalInstanceFactory(configService: ConfigService, isIE: boolean) {
  return new PublicClientApplication({
    auth: configService.config.auth,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
  });
}

/**
 * Factory function to provide the MSAL guard
 * configuration `MSAL_GUARD_CONFIG`.
 */
function msalGuardConfigFactory() {
  return <MsalGuardConfiguration>{
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

/**
 * Factory function to provide the MSAL interceptor
 * configuration `MSAL_INTERCEPTOR_CONFIG`. Requires
 * the configuration service as a dependency.
 */
function msalInterceptorConfigFactory(configService: ConfigService) {
  const config = configService.config;
  const resources = new Map<string, (string | ProtectedResourceScopes)[]>();
  resources.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  resources.set('https://graph.microsoft.com/v1.0/users ', ['user.read']);
  resources.set(`${config.apiURL}/**`, [
    `api://${config.auth.clientId}/user_impersonation`,
  ]);
  resources.set(`${config.veracityURL}/**`, [
    `api://${config.veracityClientId}/user_impersonation`,
  ]);
  return <MsalInterceptorConfiguration>{
    interactionType: InteractionType.Popup,
    protectedResourceMap: resources,
  };
}

/**
 * Auth Module.
 * Responsible for providing authentication services to the application,
 * including the MSAL Angular library, a custom authentication service that
 * provides a simplified interface to the MSAL Angular library, and a custom
 * MGT provider service that provides a simplified interface to the Microsoft
 * MGT Provider used for the MGT Web Components. A callback component is also
 * provided to handle the redirect back from the Microsoft authentication
 * service.
 */
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
      deps: [ConfigService, IS_IE],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
      deps: [ConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    PermissionsService,
    ProjectManageGuard,
    AssetContributorGuard,
    AssetManagerGuard,
  ],
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
