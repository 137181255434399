import { Injectable } from '@angular/core';
import { DetailedModel } from '@detailed-assessment/models/detailed.model';
import { DetailedParameterName } from '@detailed-assessment/types/detailed-paramter-name';
import { Assessment } from '@core/types/assessment';
import { SystemName } from '@asset/enums/system-name';
import { SubsystemName } from '@core/models/subsystem';
import { CARBON_STAGE, CarbonStage } from '@core/types/carbon-stage';
import { TOTAL_CARBON_STAGE } from '@core/types/total-carbon-stage';
import { BenchmarkModel } from '@asset/models/benchmark.model';
import { OperationalModel } from '@operational-assessment/models/operational/operational.model';
import { EmbodiedModel } from '@embodied-assessment/models/embodied.model';
import { EnergyModelModel } from '@energy-model-assessment/models/energy-model/energy-model.model';
import {
  CARBON_PARAMETERS,
  CarbonParameter,
} from '@core/types/carbon-parameter';

/**
 * Carbon Calculation Service.
 * Responsible for all carbon calculations operations.
 */
@Injectable({ providedIn: 'root' })
export class CarbonCalculationService {
  /**
   * Carbon Per Gross Area.
   * Calculates the per gross floor area.
   */
  carbonPerGrossArea(carbon?: number, gross_area?: number) {
    if (!carbon || !gross_area || Number.isNaN(carbon / gross_area))
      return undefined;
    if (carbon / gross_area === 0) return undefined;
    return carbon / gross_area;
  }

  /**
   * Absolute Carbon For Gross Area.
   * Calculates the absolute carbon for gross area.
   */
  absoluteCarbonForGrossArea(carbon?: number, gross_area?: number) {
    if (!carbon || !gross_area || Number.isNaN(carbon * gross_area))
      return undefined;
    if (carbon * gross_area === 0) return undefined;
    return carbon * gross_area;
  }

  /**
   * Benchmark Embodied Carbon For System.
   * Calculates the embodied carbon for a system in a benchmark assessment.
   */
  benchmarkEmbodiedCarbonForSystem(
    benchmark: Assessment<BenchmarkModel>,
    systemName: SystemName,
  ) {
    if (benchmark === null) return 0;
    return (
      benchmark.embodied
        ?.find((param) => param.system_name === systemName)
        ?.params.map((param) => param.value)
        .reduce((accumulator, value) => accumulator + value, 0) ?? 0
    );
  }

  /**
   * Benchmark Operational Carbon For System.
   * Calculates the operational carbon for a system in a benchmark assessment.
   */
  benchmarkOperationalCarbonForSystem(
    benchmark: Assessment<BenchmarkModel>,
    systemName: SystemName,
  ) {
    if (benchmark === null) return 0;
    return (
      benchmark.operational
        ?.find((param) => param.system_name === systemName)
        ?.params.map((param) => param.value)
        .reduce((accumulator, value) => accumulator + value, 0) ?? 0
    );
  }

  /**
   * Benchmark Carbon For System.
   * Calculates the total carbon for a system in a benchmark assessment.
   */
  benchmarkCarbonForSystem(
    benchmark: Assessment<BenchmarkModel>,
    systemName: SystemName,
  ) {
    return (
      this.benchmarkEmbodiedCarbonForSystem(benchmark, systemName) +
      this.benchmarkOperationalCarbonForSystem(benchmark, systemName)
    );
  }

  /**
   * Operational Carbon For System.
   * Calculates the carbon for a system in an operational assessment.
   */
  operationalCarbonForSystem(
    operational: Assessment<OperationalModel>,
    systemName: SystemName,
  ) {
    if (operational === null) return 0;
    switch (systemName) {
      case SystemName.MechanicalServices:
        return (
          operational.total_mech_carbon_decarb ?? operational.total_mech_carbon
        );
      case SystemName.ElectricalServices:
        return (
          operational.total_elec_carbon_decarb ?? operational.total_elec_carbon
        );
      case SystemName.PublicHealthAndHydraulics:
        return (
          operational.total_phh_carbon_decarb ?? operational.total_phh_carbon
        );
      default:
        return 0;
    }
  }

  /**
   * Embodied Carbon For System.
   * Calculates the embodied carbon for a system in an embodied assessment.
   */
  embodiedCarbonForSystem(
    embodied: Assessment<EmbodiedModel>,
    systemName: SystemName,
  ) {
    return (
      embodied?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.map((subsystem) => {
          return subsystem.parameters
            .map((parameter) => parameter.value)
            .reduce((acc, value) => acc + value, 0);
        })
        .reduce((acc, value) => acc + value, 0) ?? 0
    );
  }

  /**
   * Embodied Carbon For System Stage.
   * Calculates the embodied carbon for a system and carbon stage in an embodied assessment.
   */
  embodiedCarbonForSystemStage(
    embodied: Assessment<EmbodiedModel>,
    systemName: SystemName,
    subsystemName: SubsystemName,
    stage: CarbonStage,
  ) {
    return (
      embodied?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.find(
          (subsystem) => subsystem.subsystem_name === subsystemName,
        )
        ?.parameters.map((parameter) => {
          switch (stage) {
            case 'A1-A3':
              return parameter.stage_a1_a3;
            case 'A4':
              return parameter.stage_a4;
            case 'A5':
              return parameter.stage_a5;
            case 'B1':
              return parameter.stage_b1;
            case 'B2':
              return parameter.stage_b2;
            case 'B3':
              return parameter.stage_b3;
            case 'B4':
              return parameter.stage_b4;
            case 'B5':
              return parameter.stage_b5;
            case 'C1':
              return parameter.stage_c1;
            case 'C2':
              return parameter.stage_c2;
            case 'C3':
              return parameter.stage_c3;
            case 'C4':
              return parameter.stage_c4;
            case 'D':
              return parameter.stage_d;
            default:
              return 0;
          }
        })
        .reduce((acc, value) => acc + value, 0) ?? 0
    );
  }

  /**
   * Energy Model Electrical Pump Energy.
   * Calculates the total electrical pump energy from a energy model assessment.
   */
  energyModelElectricalPumpEnergy(energyModel: EnergyModelModel) {
    return (
      energyModel.pump_energy_to_heating_electrical +
      energyModel.pump_energy_to_cooling_electrical +
      energyModel.pump_energy_to_heat_rejection_electrical
    );
  }

  /**
   * Energy Model Electrical Pump Energy Heating Percentage.
   * Calculates the percentage of electrical pump energy that is used for heating
   * from an energy model assessment.
   */
  energyModelElectricalPumpEnergyHeatingPercentage(
    energyModel: EnergyModelModel,
  ) {
    return (
      (energyModel.pump_energy_to_heating_electrical /
        (energyModel.pump_energy_to_heating_electrical +
          energyModel.pump_energy_to_cooling_electrical +
          energyModel.pump_energy_to_heat_rejection_electrical)) *
      100
    );
  }

  /**
   * Energy Model Electrical Pump Energy Cooling Percentage.
   * Calculates the percentage of electrical pump energy that is used for cooling
   * from an energy model assessment.
   */
  energyModelElectricalPumpEnergyCoolingPercentage(
    energyModel: EnergyModelModel,
  ) {
    return (
      (energyModel.pump_energy_to_cooling_electrical /
        (energyModel.pump_energy_to_heating_electrical +
          energyModel.pump_energy_to_cooling_electrical +
          energyModel.pump_energy_to_heat_rejection_electrical)) *
      100
    );
  }

  /**
   * Energy Model Electrical Pump Energy Heat Rejection Percentage.
   * Calculates the percentage of electrical pump energy that is used for heat rejection
   * from an energy model assessment.
   */
  energyModelElectricalPumpEnergyHeatRejectionPercentage(
    energyModel: EnergyModelModel,
  ) {
    return (
      (energyModel.pump_energy_to_heat_rejection_electrical /
        (energyModel.pump_energy_to_heating_electrical +
          energyModel.pump_energy_to_cooling_electrical +
          energyModel.pump_energy_to_heat_rejection_electrical)) *
      100
    );
  }

  /**
   * Carbon Stages For Detailed Parameters.
   * Returns an array of carbon stages for the given parameters. If a total
   * parameter is not zero, but all the parameters it is made up of are zero or
   * undefined, then the total parameter will replace the parameters it is made
   * up of.
   */
  carbonStagesForDetailedParameters(
    parameters?: DetailedModel['systems'][0]['subsystems'][0]['parameters'],
  ) {
    const carbonParameters = [...CARBON_PARAMETERS];
    /**
     * Value Of Parameter.
     * Returns the value of the parameter.
     */
    function valueOfParameter(detailedParameterName: DetailedParameterName) {
      const value = parameters?.find(
        (parameter) => parameter.param_name === detailedParameterName,
      )?.value;
      return value ?? 0;
    }
    /**
     * Replace Parameters.
     * Replace a set of parameters with a total parameter. If the total parameter
     * is not zero, but all the parameters it is made up of are zero or undefined.
     */
    function replaceParameters(
      totalParameter: CarbonParameter,
      ...parameters: CarbonParameter[]
    ) {
      if (
        !parameters
          .map(
            (parameter) =>
              valueOfParameter(parameter.detailedParameterName) !== 0,
          )
          .some((value) => value) &&
        valueOfParameter(totalParameter.detailedParameterName) !== 0
      ) {
        const fromIndex = carbonParameters.indexOf(parameters[0]);
        const toIndex = carbonParameters.indexOf(
          parameters[parameters.length - 1],
        );
        carbonParameters.splice(
          fromIndex,
          toIndex - fromIndex + 1,
          totalParameter,
        );
      }
    }
    replaceParameters(
      CarbonParameter.A1_A5,
      CarbonParameter.A1_A3,
      CarbonParameter.A4,
      CarbonParameter.A5,
    );
    replaceParameters(
      CarbonParameter.B1_B5,
      CarbonParameter.B1,
      CarbonParameter.B2,
      CarbonParameter.B3,
      CarbonParameter.B4,
      CarbonParameter.B5,
    );
    replaceParameters(
      CarbonParameter.B6_B7,
      CarbonParameter.B6,
      CarbonParameter.B7,
    );
    replaceParameters(
      CarbonParameter.C1_C4,
      CarbonParameter.C1,
      CarbonParameter.C2,
      CarbonParameter.C3,
      CarbonParameter.C4,
    );
    return carbonParameters;
  }

  detailedCarbonForStage(
    detailed: Assessment<DetailedModel>,
    systemName: SystemName,
    subsystemName: SubsystemName,
    stage: DetailedParameterName,
  ) {
    return (
      detailed?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.find(
          (subsystem) => subsystem.subsystem_name === subsystemName,
        )
        ?.parameters.find((parameter) => parameter.param_name === stage)
        ?.value ?? 0
    );
  }

  /**
   * Detailed Carbon For System.
   * Returns the carbon for a system for a detailed assessment.
   */
  detailedCarbonForSystem(
    detailed: Assessment<DetailedModel>,
    systemName: SystemName,
  ) {
    return (
      detailed?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.map((subsystem) =>
          this.carbonStagesForDetailedParameters(subsystem.parameters)
            .map(
              (param) =>
                subsystem.parameters.find(
                  (parameter) =>
                    parameter.param_name === param.detailedParameterName,
                )?.value ?? 0,
            )
            .reduce((accumulator, value) => accumulator + value, 0),
        )
        .reduce((accumulator, value) => accumulator + value, 0) ?? 0
    );
  }

  /**
   * Detailed Embodied Carbon For System.
   * Returns the embodied carbon for a system for a detailed assessment.
   */
  detailedEmbodiedCarbonForSystem(
    detailed: Assessment<DetailedModel>,
    systemName: SystemName,
  ) {
    return (
      detailed?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.map((subsystem) =>
          this.carbonStagesForDetailedParameters(subsystem.parameters)
            .filter(
              (param) =>
                param.stage !== CARBON_STAGE.B6 &&
                param.stage !== CARBON_STAGE.B7 &&
                param.stage !== TOTAL_CARBON_STAGE.B6_B7,
            )
            .map(
              (param) =>
                subsystem.parameters.find(
                  (parameter) =>
                    parameter.param_name === param.detailedParameterName,
                )?.value ?? 0,
            )
            .reduce((accumulator, value) => accumulator + value, 0),
        )
        .reduce((accumulator, value) => accumulator + value, 0) ?? 0
    );
  }

  /**
   * Detailed Operational Carbon For System.
   * Calculates the operational carbon for a system from a detailed assessment.
   */
  detailedOperationalCarbonForSystem(
    detailed: Assessment<DetailedModel>,
    systemName: SystemName,
  ) {
    return (
      detailed?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.map((subsystem) =>
          this.carbonStagesForDetailedParameters(subsystem.parameters)
            .filter(
              (param) =>
                param.stage === CARBON_STAGE.B6 ||
                param.stage === CARBON_STAGE.B7 ||
                param.stage === TOTAL_CARBON_STAGE.B6_B7,
            )
            .map(
              (param) =>
                subsystem.parameters.find(
                  (parameter) =>
                    parameter.param_name === param.detailedParameterName,
                )?.value ?? 0,
            )
            .reduce((accumulator, value) => accumulator + value, 0),
        )
        .reduce((accumulator, value) => accumulator + value, 0) ?? 0
    );
  }
}
