import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MgtService } from '@mgt/services/mgt.service';
import { GraphService } from '@mgt/services/graph.service';

/**
 * Mgt Module.
 * Responsible for providing the MgtService.
 */
@NgModule({
  providers: [MgtService, GraphService],
})
export class MgtModule {
  constructor(@Optional() @SkipSelf() parentModule: MgtModule) {
    if (parentModule) {
      throw new Error(
        'MgtModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
