import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Theme } from '@core/models/theme';

/**
 * Settings Store State.
 * Defines the state of the settings store.
 */
interface SettingsStoreState {
  /* Current application theme. */
  theme: Theme;
}

/**
 * Settings Store.
 * Responsible for managing settings state.
 */
@Injectable({ providedIn: 'root' })
export class SettingsStore extends ComponentStore<SettingsStoreState> {
  /**
   * The current application theme.
   */
  readonly theme$ = this.select(({ theme }) => theme);

  constructor() {
    super({ theme: 'light' });
  }

  /**
   * Update Theme.
   * Sets the current application theme in state.
   */
  readonly updateTheme = this.updater<Theme>((state, theme) => ({
    ...state,
    theme,
  }));
}
