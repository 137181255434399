import { Component } from '@angular/core';

@Component({
  selector: 'zero-page-not-found',
  template: `
    <div class="zero-page-xx-large">
      This is not the page you were looking for..
    </div>
  `,
})
export class PageNotFoundComponent {}
