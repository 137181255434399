import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ProjectService } from '@project/services/project.service';
import { AssetModel } from '@asset/models/asset.model';
import { ProjectModel } from '@project/models/project.model';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { CollectionModel } from '@collection/models/collection.model';
import { CollectionService } from '@collection/services/collection.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SYSTEM_NOTIFICATION_CONFIG,
  SystemNotificationComponent,
  SystemNotificationData,
} from '@core/components/system-notification/system-notification.component';
import { HttpDataState } from '@core/types/http-data-state';
import { combineLatest, switchMap, map, withLatestFrom, filter } from 'rxjs';

/**
 * Asset Card State.
 */
interface AssetCardState {
  /* Project for the asset */
  project: HttpDataState<ProjectModel>;
  /* Collections for the asset. */
  collections: HttpDataState<CollectionModel[]>;
}

/**
 * Asset Card Component.
 *
 * Responsible for rending a flippable asset card.
 * The assets project is loaded only once and only
 * if the user flips the card. A new notification
 * can be displayed in the top right of the card.
 */
@Component({
  selector: 'zero-asset-card[asset]',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class AssetCardComponent implements OnInit {
  /**
   * Asset.
   * The asset to display.
   */
  @Input() asset!: AssetModel;

  /*
   * Viewed.
   * Whether the asset has been viewed by the user.
   */
  @Input() viewed = false;

  /**
   * View asset.
   * Event emitted when the user clicks the view asset button.
   */
  @Output() viewAsset = new EventEmitter<void>();

  /**
   * Flipped.
   * Whether the card is flipped.
   */
  flipped = false;

  /**
   * Project.
   * Project for the asset, wrapped in an HttpDataState.
   */
  private readonly _project$ = this.componentStore.select(
    ({ project }) => project,
  );

  /**
   * Collections.
   * Collections for the asset, wrapped in an HttpDataState.
   */
  private readonly _collections$ = this.componentStore.select(
    ({ collections }) => collections,
  );

  /**
   * Assigned Users.
   * Assigned users for the asset collections.
   */
  private readonly _assignedUsers$ = this._collections$.pipe(
    map((collections) => {
      if (collections.status === 'success' && collections.data) {
        return {
          ...collections,
          data: collections.data.map((collection) => collection.assigned_to),
        };
      }
      return collections;
    }),
  );

  /**
   * View Model.
   */
  readonly vm$ = combineLatest([this._project$, this._assignedUsers$]).pipe(
    map(([project, assignedUsers]) => ({ project, assignedUsers })),
  );

  constructor(
    private readonly collectionService: CollectionService,
    private readonly projectService: ProjectService,
    private readonly snackBar: MatSnackBar,
    private readonly componentStore: ComponentStore<AssetCardState>,
  ) {
    this.componentStore.setState({
      project: { status: 'idle' },
      collections: { status: 'idle' },
    });
  }

  /**
   * On Init.
   * Load the project for the asset.
   */
  ngOnInit() {
    this._loadProject();
  }

  /**
   * Load Project.
   * Load the project for the asset into state. A system notification
   * is displayed if the project fails to load.
   */
  private readonly _loadProject = this.componentStore.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        this.componentStore.patchState({ project: { status: 'loading' } });
        return this.projectService.getProject(this.asset.project_number).pipe(
          tapResponse(
            (project) => {
              this.componentStore.patchState({
                project: { status: 'success', data: project },
              });
            },
            () => {
              this.snackBar.openFromComponent(SystemNotificationComponent, {
                ...SYSTEM_NOTIFICATION_CONFIG,
                data: <SystemNotificationData>{
                  type: 'error',
                  title: 'Failed to load project',
                  message: `An unexpected error occurred while loading project details for project number '${this.asset.project_number}'. Please try again later.`,
                },
              });
            },
          ),
        );
      }),
    ),
  );

  /**
   * Handle Details Click.
   * Handles the click event on the details button. Flips the card and
   * loads the collections for the asset if they have not already been
   * loaded. A system notification is displayed if the collections fail
   * to load.
   */
  readonly handleDetailsClick = this.componentStore.effect((event) =>
    event.pipe(
      withLatestFrom(this._collections$),
      filter(([_, collections]) => {
        this.flipped = !this.flipped;
        return collections.status !== 'success';
      }),
      switchMap(() => {
        this.componentStore.patchState({ collections: { status: 'loading' } });
        return this.collectionService.getAssetCollections(this.asset.id).pipe(
          tapResponse(
            (collections) => {
              this.componentStore.patchState({
                collections: { status: 'success', data: collections },
              });
            },
            () => {
              this.snackBar.openFromComponent(SystemNotificationComponent, {
                ...SYSTEM_NOTIFICATION_CONFIG,
                data: <SystemNotificationData>{
                  type: 'error',
                  title: 'Failed to load collections',
                  message: `An unexpected error occurred while loading assigned users for asset '${this.asset.name}'. Please try again later.`,
                },
              });
            },
          ),
        );
      }),
    ),
  );
}
