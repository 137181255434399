import { ErrorHandler, Injectable } from '@angular/core';
import {
  ApplicationInsights,
  ICustomProperties,
  IExceptionTelemetry,
  IMetricTelemetry,
} from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { User } from '@microsoft/microsoft-graph-types';

/**
 * Application Insights Service.
 * Responsible for application insights related operations.
 */
@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
  angularPlugin = new AngularPlugin();
  appInsights?: ApplicationInsights;

  constructor(configService: ConfigService, router: Router) {
    if (configService.config.appInsights.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey:
            configService.config.appInsights.instrumentationKey,
          extensions: [this.angularPlugin],
          extensionConfig: {
            [this.angularPlugin.identifier]: {
              router: router,
              errorServices: [new ErrorHandler()],
            },
          },
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  /**
   * Set User ID.
   * Set the authenticated user id and the account id.
   */
  setUserId(user: User) {
    if (user.userPrincipalName)
      this.appInsights?.setAuthenticatedUserContext(
        user.userPrincipalName,
        undefined,
        true,
      );
  }

  /**
   * Clear User ID.
   * Clears the authenticated user id and the account id.
   */
  clearUserId() {
    this.appInsights?.clearAuthenticatedUserContext();
  }

  /**
   * Track Exception.
   * Track an exception that occurred in the application.
   */
  trackException(
    exception: IExceptionTelemetry,
    customProperties?: ICustomProperties,
  ) {
    this.appInsights?.trackException(exception, customProperties);
  }

  /**
   * Track Metric.
   * Log a numeric value that is not associated with a specific event.
   */
  trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties) {
    this.appInsights?.trackMetric(metric, customProperties);
  }
}
