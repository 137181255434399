import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PermissionsService } from '@auth/services/permissions.service';

/**
 * Project Manage Guard.
 * Checks if the user has permission to manage projects.
 */
@Injectable()
export class ProjectManageGuard implements CanActivate {
  constructor(private permissionsService: PermissionsService) {}

  /**
   * Checks if the user has permission to manage projects.
   */
  canActivate() {
    return this.permissionsService.userCanManageProjects$;
  }
}
