<h1 mat-dialog-title>Update Design Phase</h1>
<zero-form-help>
  Before updating the design phase, ensure that you have created an Asset Record
  to capture asset data representing the current design phase.
</zero-form-help>
<mat-form-field>
  <mat-label>Design Phase</mat-label>
  <mat-select [formControl]="designStageControl">
    <mat-option *ngFor="let status of assetStatuses" [value]="status.value">
      {{ status.display_name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="actions">
  <button mat-flat-button (click)="dialogRef.close('cancel')">Cancel</button>
  <button
    mat-flat-button
    color="accent"
    (click)="
      dialogRef.close(designStageControl.value ?? data.currentDesignStage)
    "
  >
    Update
  </button>
</div>
