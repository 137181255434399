import { Injectable } from '@angular/core';
import { CarbonCalculationService } from '@core/services/carbon-calculation.service';
import { UtilsService } from '@core/services/utils.service';
import { BarChartData, BarChartSystemData } from '@core/models/chart-data';
import { DonutChartDataModel } from '@asset/models/donut-chart-data/donut-chart-data.model';
import { SystemName } from '@asset/enums/system-name';
import { BenchmarkModel } from '@asset/models/benchmark.model';
import { DetailedModel } from '@detailed-assessment/models/detailed.model';
import {
  DETAILED_PARAMETER_NAME,
  DetailedParameterName,
} from '@detailed-assessment/types/detailed-paramter-name';
import {
  CARBON_STAGE,
  CARBON_STAGES,
  CarbonStage,
} from '@core/types/carbon-stage';
import {
  EmbodiedModel,
  EmbodiedSystem,
} from '@embodied-assessment/models/embodied.model';
import { OperationalModel } from '@operational-assessment/models/operational/operational.model';
import { AssetModel } from '@asset/models/asset.model';
import { Assessment } from '@core/types/assessment';
import {
  TOTAL_CARBON_STAGE,
  TOTAL_CARBON_STAGES,
  TotalCarbonStage,
} from '@core/types/total-carbon-stage';
import {
  StageData,
  StageChartData,
  StageChartDataset,
} from '@core/models/chart-data';
import { EnergyModelModel } from '@energy-model-assessment/models/energy-model/energy-model.model';
import { EnergyModelService } from '@energy-model-assessment/services/energy-model.service';
import { AssetService } from '@asset/services/asset.service';

/**
 * Chart Service.
 * Responsible for all chart related operations.
 */
@Injectable({ providedIn: 'root' })
export class ChartService {
  constructor(
    private readonly assetService: AssetService,
    private readonly carbonCalculationService: CarbonCalculationService,
    private readonly energyModelService: EnergyModelService,
    private readonly utilsService: UtilsService,
  ) {}

  /**
   * Convert assessments into bar chart data
   * @param asset
   * @param benchmarkAssessment
   * @param detailedAssessment
   * @param highLevelOperational
   * @param highLevelEmbodied
   */
  barChartDataFromAssessments(
    asset: AssetModel,
    benchmarkAssessment: Assessment<BenchmarkModel>,
    detailedAssessment: Assessment<DetailedModel>,
    highLevelOperational: Assessment<OperationalModel>,
    highLevelEmbodied: Assessment<EmbodiedModel>,
  ): BarChartData {
    const grossArea = this.assetService.calculateGrossArea(asset);
    return <BarChartData>{
      max: Math.max(
        this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.benchmarkCarbonForSystem(
                benchmarkAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ) ?? 0,
        this.carbonCalculationService.carbonPerGrossArea(
          (asset.systems
            .map((system) =>
              this.carbonCalculationService.operationalCarbonForSystem(
                highLevelOperational,
                system.name,
              ),
            )
            .reduce((accumulator, value?) => accumulator! + (value ?? 0), 0) ??
            0) +
            (asset.systems
              .map((system) =>
                this.carbonCalculationService.embodiedCarbonForSystem(
                  highLevelEmbodied,
                  system.name,
                ),
              )
              ?.reduce(
                (accumulator, value?) => accumulator! + (value ?? 0),
                0,
              ) ?? 0),
          grossArea,
        ) ?? 0,
        this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.detailedCarbonForSystem(
                detailedAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ) ?? 0,
      ),
      benchmark: {
        total: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.benchmarkCarbonForSystem(
                benchmarkAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value) => accumulator! + value!, 0),
          grossArea,
        ),
        embodied: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.benchmarkEmbodiedCarbonForSystem(
                benchmarkAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value) => accumulator! + value!, 0),
          grossArea,
        ),
        operational: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map(
              (system) =>
                this.carbonCalculationService.benchmarkOperationalCarbonForSystem(
                  benchmarkAssessment,
                  system.name,
                ) ?? 0,
            )
            ?.reduce((accumulator, value) => accumulator! + value!, 0),
          grossArea,
        ),
      },
      highLevel: {
        total: this.carbonCalculationService.carbonPerGrossArea(
          (asset.systems
            .map((system) =>
              this.carbonCalculationService.operationalCarbonForSystem(
                highLevelOperational,
                system.name,
              ),
            )
            .reduce((accumulator, value?) => accumulator! + (value ?? 0), 0) ??
            0) +
            (asset.systems
              .map((system) =>
                this.carbonCalculationService.embodiedCarbonForSystem(
                  highLevelEmbodied,
                  system.name,
                ),
              )
              ?.reduce(
                (accumulator, value?) => accumulator! + (value ?? 0),
                0,
              ) ?? 0),
          grossArea,
        ),
        embodied: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.embodiedCarbonForSystem(
                highLevelEmbodied,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ),
        operational: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.operationalCarbonForSystem(
                highLevelOperational,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ),
      },
      detailed: {
        total: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.detailedCarbonForSystem(
                detailedAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ),
        embodied: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.detailedEmbodiedCarbonForSystem(
                detailedAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ),
        operational: this.carbonCalculationService.carbonPerGrossArea(
          asset.systems
            .map((system) =>
              this.carbonCalculationService.detailedOperationalCarbonForSystem(
                detailedAssessment,
                system.name,
              ),
            )
            ?.reduce((accumulator, value?) => accumulator! + (value ?? 0), 0),
          grossArea,
        ),
      },
      systems: asset.systems.map(
        (system) =>
          <BarChartSystemData>{
            system_name: system.name,
            system_class: this.utilsService.safeCSSClassName(system.name),
            id: system.ddb_ref_id!,
            benchmark: {
              total: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.benchmarkCarbonForSystem(
                  benchmarkAssessment,
                  system.name,
                ),
                grossArea,
              ),
              embodied: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.benchmarkEmbodiedCarbonForSystem(
                  benchmarkAssessment,
                  system.name,
                ),
                grossArea,
              ),
              operational: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.benchmarkOperationalCarbonForSystem(
                  benchmarkAssessment,
                  system.name,
                ),
                grossArea,
              ),
            },
            highLevel: {
              total: this.carbonCalculationService.carbonPerGrossArea(
                (this.carbonCalculationService.operationalCarbonForSystem(
                  highLevelOperational,
                  system.name,
                ) ?? 0) +
                  (this.carbonCalculationService.embodiedCarbonForSystem(
                    highLevelEmbodied,
                    system.name,
                  ) ?? 0),
                grossArea,
              ),
              embodied: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.embodiedCarbonForSystem(
                  highLevelEmbodied,
                  system.name,
                ),
                grossArea,
              ),
              operational: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.operationalCarbonForSystem(
                  highLevelOperational,
                  system.name,
                ),
                grossArea,
              ),
            },
            detailed: {
              total: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.detailedCarbonForSystem(
                  detailedAssessment,
                  system.name,
                ),
                grossArea,
              ),
              embodied: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.detailedEmbodiedCarbonForSystem(
                  detailedAssessment,
                  system.name,
                ),
                grossArea,
              ),
              operational: this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.detailedOperationalCarbonForSystem(
                  detailedAssessment,
                  system.name,
                ),
                grossArea,
              ),
            },
          },
      ),
    };
  }

  formatHLEDatasets(
    system: EmbodiedSystem,
    highLevelEmbodied: Assessment<EmbodiedModel>,
    grossArea: number,
  ): StageChartDataset[] {
    // generate embodied dataset for subsystems
    return system.subsystems?.map((subsystem: any) => {
      // generate total for each stage
      const data = CARBON_STAGES.map((stage) => {
        return {
          stage,
          total:
            this.carbonCalculationService.carbonPerGrossArea(
              this.carbonCalculationService.embodiedCarbonForSystemStage(
                highLevelEmbodied,
                system.system_name,
                subsystem.subsystem_name,
                stage,
              ),
              grossArea,
            ) ?? 0,
        };
      });

      const className = this.utilsService.safeCSSClassName(
        subsystem.subsystem_name!,
      );
      const cssVar = this.utilsService.getCSSVar(
        `--zero-subsystem-color-${className}`,
      );
      return {
        label: subsystem.subsystem_name,
        backgroundColor: `rgb(${cssVar})`,
        data,
      };
    });
  }

  formatHLODatasets(
    highLevelOperational: Assessment<OperationalModel>,
    systemName: SystemName,
    grossArea: number,
  ) {
    const className = this.utilsService.safeCSSClassName(systemName);
    const cssVar = this.utilsService.getCSSVar(
      `--zero-system-color-${className}`,
    );
    return [
      {
        label: `${systemName} (System)`,
        backgroundColor: `rgb(${cssVar})`,
        data: [
          {
            stage: TOTAL_CARBON_STAGE.B6_B7,
            total:
              this.carbonCalculationService.carbonPerGrossArea(
                this.carbonCalculationService.operationalCarbonForSystem(
                  highLevelOperational,
                  systemName,
                ),
                grossArea,
              ) ?? 0,
          },
        ],
      },
    ];
  }

  /**
   * Convert high level assessments into carbon stage bar chart data
   * @param asset
   * @param highLevelOperational
   * @param highLevelEmbodied
   * @param systemName
   */
  carbonStageBarChartDataFromHighLevelAssessments(
    asset: AssetModel,
    highLevelOperational: Assessment<OperationalModel>,
    highLevelEmbodied: Assessment<EmbodiedModel>,
    systemName: SystemName,
  ): StageChartData {
    const grossArea = this.assetService.calculateGrossArea(asset);
    const system = highLevelEmbodied?.systems.find(
      (system) => system.system_name === systemName,
    );
    let embodiedDataset: StageChartDataset[] = [];
    let operationalDataset: StageChartDataset[] = [];

    // High level embodied
    if (system) {
      embodiedDataset = this.formatHLEDatasets(
        system,
        highLevelEmbodied,
        grossArea,
      );
    }
    // High Level Operational
    if (
      highLevelOperational &&
      [
        SystemName.ElectricalServices,
        SystemName.MechanicalServices,
        SystemName.PublicHealthAndHydraulics,
      ].includes(systemName)
    ) {
      operationalDataset = this.formatHLODatasets(
        highLevelOperational,
        systemName,
        grossArea,
      );
    }

    if (embodiedDataset.length > 0 || operationalDataset.length > 0) {
      let stageDataset = this.aggregateStages([
        ...embodiedDataset,
        ...operationalDataset,
      ]);
      // add stages that don't exist in the dataset
      stageDataset = this.fillOutMissingStages(stageDataset);
      // sort alphabetically
      stageDataset = this.sortStages(stageDataset);
      return {
        labels: stageDataset[0]?.data.map((d) => d.stage) ?? [],
        datasets: stageDataset,
      };
    } else {
      return { labels: [], datasets: [] };
    }
  }

  /**
   * Determine if the stage provided needs to be aggregated
   * Is true if the dataset contains a total value
   * @param datasets
   * @param totalid
   * @param individualIds
   */
  rollUpTotalsRequired(
    datasets: StageChartDataset[],
    totalid: TotalCarbonStage,
    individualIds: (CarbonStage | TotalCarbonStage)[] = [],
  ): boolean {
    const checkRollUpsRequired = datasets.map(
      (subsystemData: StageChartDataset) => {
        const stages = subsystemData.data.map((d: StageData) => d.stage);
        return (
          stages.includes(totalid) &&
          !stages.some((stage) => individualIds.includes(stage))
        );
      },
    );
    return checkRollUpsRequired.includes(true) ? true : false;
  }

  /**
   * Aggregate the individual values into a total for the stage range provided
   * @param data
   * @param totalid
   * @param individualIds
   */
  rollUpTotals(
    data: StageData[],
    totalid: TotalCarbonStage,
    individualIds: (CarbonStage | TotalCarbonStage)[],
  ): StageData[] {
    // add up the individual values and add the total value as a new datapoint
    const individualValues = data.filter((d) =>
      individualIds.includes(d.stage),
    );
    if (individualValues.length > 0) {
      data = data.filter((d) => d.stage !== totalid);
      const total = individualValues.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0,
      );
      data.push({ stage: totalid, total });
    }
    // remove the individual values
    return data.filter((d) => !individualIds.includes(d.stage));
  }

  /**
   * Apply relevant aggregations to the whole dataset
   * @param stage
   */
  aggregateStages(stageDataset: StageChartDataset[]) {
    // Check which sections need to be rolled up
    const rollupA = this.rollUpTotalsRequired(
      stageDataset,
      TOTAL_CARBON_STAGE.A1_A5,
      [CARBON_STAGE.A1_A3, CARBON_STAGE.A4, CARBON_STAGE.A5],
    );

    const rollupB = this.rollUpTotalsRequired(
      stageDataset,
      TOTAL_CARBON_STAGE.B1_B5,
      [
        CARBON_STAGE.B1,
        CARBON_STAGE.B2,
        CARBON_STAGE.B3,
        CARBON_STAGE.B4,
        CARBON_STAGE.B5,
      ],
    );

    const rollupB6B7 = this.rollUpTotalsRequired(
      stageDataset,
      TOTAL_CARBON_STAGE.B6_B7,
      [CARBON_STAGE.B6, CARBON_STAGE.B7],
    );

    const rollupC = this.rollUpTotalsRequired(
      stageDataset,
      TOTAL_CARBON_STAGE.C1_C4,
      [CARBON_STAGE.C1, CARBON_STAGE.C2, CARBON_STAGE.C3, CARBON_STAGE.C4],
    );

    // Roll up the relevant sections
    if (rollupA) {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: this.rollUpTotals(
            subsystemData.data,
            TOTAL_CARBON_STAGE.A1_A5,
            [CARBON_STAGE.A1_A3, CARBON_STAGE.A4, CARBON_STAGE.A5],
          ),
        };
      });
    } else {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: subsystemData.data.filter(
            (d) => d.stage !== TOTAL_CARBON_STAGE.A1_A5,
          ),
        };
      });
    }
    if (rollupB) {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: this.rollUpTotals(
            subsystemData.data,
            TOTAL_CARBON_STAGE.B1_B5,
            [
              CARBON_STAGE.B1,
              CARBON_STAGE.B2,
              CARBON_STAGE.B3,
              CARBON_STAGE.B4,
              CARBON_STAGE.B5,
            ],
          ),
        };
      });
    } else {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: subsystemData.data.filter(
            (d) => d.stage !== TOTAL_CARBON_STAGE.B1_B5,
          ),
        };
      });
    }
    if (rollupB6B7) {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: this.rollUpTotals(
            subsystemData.data,
            TOTAL_CARBON_STAGE.B6_B7,
            [CARBON_STAGE.B6, CARBON_STAGE.B7],
          ),
        };
      });
    } else {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: subsystemData.data.filter(
            (d) => d.stage !== TOTAL_CARBON_STAGE.B6_B7,
          ),
        };
      });
    }
    if (rollupC) {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: this.rollUpTotals(
            subsystemData.data,
            TOTAL_CARBON_STAGE.C1_C4,
            [
              CARBON_STAGE.C1,
              CARBON_STAGE.C2,
              CARBON_STAGE.C3,
              CARBON_STAGE.C4,
            ],
          ),
        };
      });
    } else {
      stageDataset = stageDataset.map((subsystemData) => {
        return {
          ...subsystemData,
          data: subsystemData.data.filter(
            (d) => d.stage !== TOTAL_CARBON_STAGE.C1_C4,
          ),
        };
      });
    }
    return stageDataset;
  }

  /**
   * Add in missing stages to all datasets
   * @param datasets
   */
  fillOutMissingStages(datasets: StageChartDataset[]) {
    return datasets.map((subsystemData) => {
      const aStages: CarbonStage[] = [
        CARBON_STAGE.A1_A3,
        CARBON_STAGE.A4,
        CARBON_STAGE.A5,
      ];
      const bStages: CarbonStage[] = [
        CARBON_STAGE.B1,
        CARBON_STAGE.B2,
        CARBON_STAGE.B3,
        CARBON_STAGE.B4,
        CARBON_STAGE.B5,
      ];
      const operationalStages: CarbonStage[] = [
        CARBON_STAGE.B6,
        CARBON_STAGE.B7,
      ];
      const cStages: CarbonStage[] = [
        CARBON_STAGE.C1,
        CARBON_STAGE.C2,
        CARBON_STAGE.C3,
        CARBON_STAGE.C4,
      ];
      const relevantStages = CARBON_STAGES.filter((stage) => {
        return (
          (aStages.includes(stage) &&
            !subsystemData.data.find(
              (d) => d.stage === TOTAL_CARBON_STAGE.A1_A5,
            )) ||
          (bStages.includes(stage) &&
            !subsystemData.data.find(
              (d) => d.stage === TOTAL_CARBON_STAGE.B1_B5,
            )) ||
          (operationalStages.includes(stage) &&
            !subsystemData.data.find(
              (d) => d.stage === TOTAL_CARBON_STAGE.B6_B7,
            )) ||
          (cStages.includes(stage) &&
            !subsystemData.data.find(
              (d) => d.stage === TOTAL_CARBON_STAGE.C1_C4,
            )) ||
          ![...aStages, ...bStages, ...operationalStages, ...cStages].includes(
            stage,
          )
        );
      });
      const filledOutData = relevantStages.map((stage: CarbonStage) => {
        const findStage = subsystemData.data.find((d) => d.stage === stage);
        if (findStage) {
          return findStage;
        } else {
          return { stage, total: 0 };
        }
      });
      const totals = subsystemData.data.filter((stageDataPoint) =>
        TOTAL_CARBON_STAGES.some((stage) => stage === stageDataPoint.stage),
      );
      return { ...subsystemData, data: [...filledOutData, ...totals] };
    });
  }

  /**
   * Sort the stages data in a dataset into the correct order
   * @param datasets
   */
  sortStages(datasets: StageChartDataset[]): StageChartDataset[] {
    return datasets.map((subsystemData) => {
      const sortedData = subsystemData.data.sort((a, b) => {
        var textA = a.stage;
        var textB = b.stage;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      // move B and D to the end
      sortedData.push(
        sortedData.splice(
          sortedData.findIndex((d) => d.stage === CARBON_STAGE.B),
          1,
        )[0],
      );
      sortedData.push(
        sortedData.splice(
          sortedData.findIndex((d) => d.stage === 'D'),
          1,
        )[0],
      );
      return {
        ...subsystemData,
        data: sortedData,
      };
    });
  }

  carbonStageBarChartDataFromDetailedAssessment(
    asset: AssetModel,
    detailed: Assessment<DetailedModel>,
    detailedEnergyModelOverride: boolean,
    systemName: SystemName,
  ): StageChartData {
    const grossArea = this.assetService.calculateGrossArea(asset);
    function getCarbonStageForParam(
      paramName: DetailedParameterName,
    ): CarbonStage | TotalCarbonStage {
      switch (paramName) {
        case DETAILED_PARAMETER_NAME.A1_A3:
          return 'A1-A3';
        case DETAILED_PARAMETER_NAME.A4:
          return 'A4';
        case DETAILED_PARAMETER_NAME.A5:
          return 'A5';
        case DETAILED_PARAMETER_NAME.A1_A5:
          return 'A1-A5';
        case DETAILED_PARAMETER_NAME.B1:
          return 'B1';
        case DETAILED_PARAMETER_NAME.B2:
          return 'B2';
        case DETAILED_PARAMETER_NAME.B3:
          return 'B3';
        case DETAILED_PARAMETER_NAME.B4:
          return 'B4';
        case DETAILED_PARAMETER_NAME.B5:
          return 'B5';
        case DETAILED_PARAMETER_NAME.B1_B5:
          return 'B1-B5';
        case DETAILED_PARAMETER_NAME.B6:
          return 'B6';
        case DETAILED_PARAMETER_NAME.B7:
          return 'B7';
        case DETAILED_PARAMETER_NAME.B6_B7:
          return 'B6-B7';
        case DETAILED_PARAMETER_NAME.C1:
          return 'C1';
        case DETAILED_PARAMETER_NAME.C2:
          return 'C2';
        case DETAILED_PARAMETER_NAME.C3:
          return 'C3';
        case DETAILED_PARAMETER_NAME.C4:
          return 'C4';
        case DETAILED_PARAMETER_NAME.C1_C4:
          return 'C1-C4';
        case DETAILED_PARAMETER_NAME.B:
          return CARBON_STAGE.B;
        case DETAILED_PARAMETER_NAME.D:
          return 'D';
      }
    }

    const system = detailed?.systems.find(
      (system) => system.system_name === systemName,
    );
    if (system) {
      let stageDataset: StageChartDataset[] = system.subsystems?.map(
        (subsystem) => {
          // generate total for each stage
          const data = subsystem.parameters?.map((param) => {
            return {
              stage: getCarbonStageForParam(param.param_name!),
              total:
                this.carbonCalculationService.carbonPerGrossArea(
                  param.value,
                  grossArea,
                ) ?? 0,
            };
          });
          // pull background colour from css variables
          const className = this.utilsService.safeCSSClassName(
            subsystem.subsystem_name!,
          );
          const cssVar = this.utilsService.getCSSVar(
            `--zero-subsystem-color-${className}`,
          );
          return {
            label: detailedEnergyModelOverride
              ? `${subsystem.subsystem_name} (Energy Model)`
              : subsystem.subsystem_name,
            backgroundColor: `rgb(${cssVar})`,
            data,
          };
        },
      );
      // aggregate stages
      stageDataset = this.aggregateStages(stageDataset);
      // add stages that don't exist in the dataset
      stageDataset = this.fillOutMissingStages(stageDataset);
      // sort alphabetically
      stageDataset = this.sortStages(stageDataset);

      return {
        labels: stageDataset[0].data.map((d) => d.stage),
        datasets: stageDataset,
      };
    } else {
      return { labels: [], datasets: [] };
    }
  }

  /**
   * add up subsystem data to get a value for the whole system
   * @param systemData
   */
  mergeSubsystemDataIntoSystemData(systemData: StageChartData): StageData[] {
    return systemData.labels.map((label) => {
      const datasets = systemData.datasets.map((dataset) => {
        return dataset.data.find((d) => d.stage === label);
      });
      const total = datasets.reduce(function (acc, obj) {
        return acc + (obj?.total ?? 0);
      }, 0);
      return { stage: label, total };
    });
  }

  /**
   * Sort the stages data in a dataset into the correct order
   * @param datasets
   */
  sortSystemsInScope(
    datasets: StageChartDataset[],
    asset: AssetModel,
  ): StageChartDataset[] {
    return datasets.sort((a, b) => {
      const systemInScope = asset.systems.find(
        (assetSystem) => assetSystem.name === a.label,
      )?.scope;
      const prevSystemInScope = asset.systems.find(
        (assetSystem) => assetSystem.name === b.label,
      )?.scope;
      return systemInScope === prevSystemInScope ? 0 : systemInScope ? -1 : 1;
    });
  }

  lifecycleStageBarChartDataFromDetailedAssessment(
    asset: AssetModel,
    detailed: Assessment<DetailedModel>,
    energyModel: Assessment<EnergyModelModel>,
  ) {
    if (asset && detailed) {
      let lifecycleDatasets: StageChartDataset[] = detailed!.systems.map(
        (system) => {
          // generate subsystem stage data
          const systemData = this.carbonStageBarChartDataFromDetailedAssessment(
            asset,
            detailed,
            this.energyModelService.systemHasEnergyModelOverride(
              energyModel,
              system.system_name,
            ),
            system.system_name,
          );
          // merge the subsystem data into system level data
          const mergeSubsystemData =
            this.mergeSubsystemDataIntoSystemData(systemData);
          // get system color
          const className = this.utilsService.safeCSSClassName(
            system.system_name,
          );
          let backgroundColor = 'rgba(1,1,1,0)';
          let borderColor = undefined;
          let borderWidth = undefined;
          if (
            asset.systems.find(
              (assetSystem) => assetSystem.name === system.system_name,
            )?.scope
          ) {
            backgroundColor = `rgb(${this.utilsService.getCSSVar(
              `--zero-system-color-${className}`,
            )})`;
          } else {
            borderColor = `rgb(${this.utilsService.getCSSVar(
              `--zero-system-color-${className}`,
            )})`;
            borderWidth = 3;
          }
          return {
            label: system.system_name,
            backgroundColor,
            borderColor,
            borderWidth,
            data: mergeSubsystemData,
          };
        },
      );
      // aggregate relevant stages
      lifecycleDatasets = this.aggregateStages(lifecycleDatasets);
      // sort so that they are all in the correct order
      lifecycleDatasets = this.sortStages(lifecycleDatasets);
      // sort systems to have in scope first, then out of scope
      lifecycleDatasets = this.sortSystemsInScope(lifecycleDatasets, asset);
      return {
        labels: lifecycleDatasets[0]?.data.map((d) => d.stage) ?? [],
        datasets: lifecycleDatasets,
      };
    } else {
      return {
        labels: [],
        datasets: [],
      };
    }
  }

  generateSystemsListForLifecycleHighLevel(
    highLevelEmbodied: Assessment<EmbodiedModel>,
    highLevelOperational: Assessment<OperationalModel>,
  ): SystemName[] {
    const systems =
      highLevelEmbodied?.systems.map((system) => system.system_name) ?? [];
    if (highLevelOperational) {
      if (
        (highLevelOperational.total_elec_carbon_decarb ??
          highLevelOperational.total_elec_carbon) !== 0 &&
        systems.findIndex(
          (system) => system === SystemName.ElectricalServices,
        ) === -1
      ) {
        systems.push(SystemName.ElectricalServices);
      }
      if (
        (highLevelOperational.total_mech_carbon_decarb ??
          highLevelOperational.total_mech_carbon) !== 0 &&
        systems.findIndex(
          (system) => system === SystemName.MechanicalServices,
        ) === -1
      ) {
        systems.push(SystemName.MechanicalServices);
      }
      if (
        (highLevelOperational.total_phh_carbon_decarb ??
          highLevelOperational.total_phh_carbon) !== 0 &&
        systems.findIndex(
          (system) => system === SystemName.PublicHealthAndHydraulics,
        ) === -1
      ) {
        systems.push(SystemName.PublicHealthAndHydraulics);
      }
    }

    return systems;
  }

  lifecycleStageBarChartDataFromHighLevelAssessment(
    asset: AssetModel,
    highLevelOperational: Assessment<OperationalModel>,
    highLevelEmbodied: Assessment<EmbodiedModel>,
  ) {
    if (asset && (highLevelEmbodied || highLevelOperational)) {
      const systems = this.generateSystemsListForLifecycleHighLevel(
        highLevelEmbodied,
        highLevelOperational,
      );
      let lifecycleDatasets: StageChartDataset[] = systems.map((system) => {
        // generate subsystem stage data
        const systemData = this.carbonStageBarChartDataFromHighLevelAssessments(
          asset,
          highLevelOperational,
          highLevelEmbodied,
          system,
        );
        // merge the subsystem data into system level data
        const mergeSubsystemData =
          this.mergeSubsystemDataIntoSystemData(systemData);
        // get system color
        const className = this.utilsService.safeCSSClassName(system);
        let backgroundColor = 'rgba(1,1,1,0)';
        let borderColor = undefined;
        let borderWidth = undefined;
        if (
          asset.systems.find((assetSystem) => assetSystem.name === system)
            ?.scope
        ) {
          backgroundColor = `rgb(${this.utilsService.getCSSVar(
            `--zero-system-color-${className}`,
          )})`;
        } else {
          borderColor = `rgb(${this.utilsService.getCSSVar(
            `--zero-system-color-${className}`,
          )})`;
          borderWidth = 3;
        }
        return {
          label: system,
          backgroundColor,
          borderColor,
          borderWidth,
          data: mergeSubsystemData,
        };
      });
      // aggregate relevant stages
      lifecycleDatasets = this.aggregateStages(lifecycleDatasets);
      // sort so that they are all in the correct order
      lifecycleDatasets = this.sortStages(lifecycleDatasets);
      // sort systems to have in scope first, then out of scope
      lifecycleDatasets = this.sortSystemsInScope(lifecycleDatasets, asset);
      return {
        labels: lifecycleDatasets[0]?.data.map((d) => d.stage) ?? [],
        datasets: lifecycleDatasets,
      };
    } else {
      return {
        labels: [],
        datasets: null,
      };
    }
  }

  /**
   * Convert assessments into donut chart data
   * @param asset
   * @param benchmarkAssessment
   * @param detailedAssessment
   * @param highLevelOperational
   * @param highLevelEmbodied
   */
  donutChartDataFromAssessments(
    asset: AssetModel,
    benchmarkAssessment: Assessment<BenchmarkModel>,
    detailedAssessment: Assessment<DetailedModel>,
    highLevelOperational: Assessment<OperationalModel>,
    highLevelEmbodied: Assessment<EmbodiedModel>,
  ): DonutChartDataModel {
    const operationalChildren = asset.systems!.map((system) => {
      const benchmark =
        this.carbonCalculationService.benchmarkOperationalCarbonForSystem(
          benchmarkAssessment,
          system.name!,
        );
      const highLevel =
        this.carbonCalculationService.operationalCarbonForSystem(
          highLevelOperational,
          system.name!,
        );
      const detailed =
        this.carbonCalculationService.detailedOperationalCarbonForSystem(
          detailedAssessment,
          system.name!,
        );
      const grossArea = this.assetService.calculateGrossArea(asset);
      return {
        name: system.name,
        size:
          (detailed
            ? this.carbonCalculationService.carbonPerGrossArea(
                detailed,
                grossArea,
              )
            : highLevel
            ? this.carbonCalculationService.carbonPerGrossArea(
                highLevel,
                grossArea,
              )
            : this.carbonCalculationService.carbonPerGrossArea(
                benchmark,
                grossArea,
              )) || 0,
        level: detailed ? 2 : highLevel ? 1 : 0,
        levelName: detailed
          ? 'Detailed'
          : highLevel
          ? 'High Level'
          : 'Benchmark',
        inScope: system.scope!,
        type: 'Operational',
      };
    });

    const embodiedChildren = asset.systems!.map((system) => {
      const benchmark =
        this.carbonCalculationService.benchmarkEmbodiedCarbonForSystem(
          benchmarkAssessment,
          system.name!,
        );
      const highLevel = this.carbonCalculationService.embodiedCarbonForSystem(
        highLevelEmbodied,
        system.name!,
      );
      const detailed =
        this.carbonCalculationService.detailedEmbodiedCarbonForSystem(
          detailedAssessment,
          system.name!,
        );
      const grossArea = this.assetService.calculateGrossArea(asset);
      return {
        name: system.name,
        size:
          (detailed
            ? this.carbonCalculationService.carbonPerGrossArea(
                detailed,
                grossArea,
              )
            : highLevel
            ? this.carbonCalculationService.carbonPerGrossArea(
                highLevel,
                grossArea,
              )
            : this.carbonCalculationService.carbonPerGrossArea(
                benchmark,
                grossArea,
              )) || 0,
        level: detailed ? 2 : highLevel ? 1 : 0,
        levelName: detailed
          ? 'Detailed'
          : highLevel
          ? 'High Level'
          : 'Benchmark',
        inScope: system.scope!,
        type: 'Embodied',
      };
    });

    return {
      name: 'root',
      children: [
        {
          name: 'Operational',
          total: operationalChildren.reduce(
            (prev, curr) => prev + curr.size,
            0,
          ),
          level: 0,
          inScope: true,
          children: operationalChildren!,
        },
        {
          name: 'Embodied',
          total: embodiedChildren.reduce((prev, curr) => prev + curr.size, 0),
          level: 0,
          inScope: true,
          children: embodiedChildren!,
        },
      ],
    };
  }

  /**
   * Convert bar chart data into bar chart system data
   * @param chartAssetData
   * @param systemName
   */
  barChartSystemDataFromBarChartData(
    chartAssetData: BarChartData,
    systemName: SystemName,
  ): BarChartSystemData {
    return chartAssetData.systems?.find(
      (systemData: BarChartSystemData) => systemData.system_name === systemName,
    )!;
  }
}
