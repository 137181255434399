import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

export const ENERGY_MODEL_ENDPOINT = new InjectionToken<string>(
  'ENERGY_MODEL_ENDPOINT',
  {
    factory: () =>
      `${inject(ConfigService).config.apiURL}/assessments/detailed/energy`,
  },
);
