import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HttpDataState } from '@core/types/http-data-state';
import { CampaignModel } from '@campaign/models/campaign.model';
import { Subject, takeUntil } from 'rxjs';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

/**
 * Campaign Control Value.
 * Defines the type of the value of the campaign control.
 */
export type CampaignControlValue = CampaignModel | null;

/**
 * Campaign Selection Component.
 * Renders a loading select for the campaigns. It emits the new value of the upcoming campaign when it changes.
 */
@Component({
  selector: 'zero-campaign-control[campaigns]',
  templateUrl: './campaign-control.component.html',
  styleUrls: ['./campaign-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampaignControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CampaignControlComponent),
      multi: true,
    },
  ],
})
export class CampaignControlComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  /**
   * Destroying$.
   * The subject that emits when the component is destroyed.
   */
  private readonly _destroying$ = new Subject<void>();

  /**
   * Campaigns.
   * The campaigns to display in the campaign select.
   */
  @Input() campaigns!: HttpDataState<CampaignModel[]>;

  /**
   * On Change.
   * The on change callback.
   */
  private _onChange?: (value: CampaignControlValue) => void;

  /**
   * On Change.
   * The on change callback.
   */
  private _onTouched?: () => void;

  /**
   * Control.
   * The control for the campaign. This control is required.
   */
  readonly control = new FormControl<CampaignModel | null>(null, [
    Validators.required,
  ]);

  /**
   * On Init.
   * Subscribes to the control value changes and calls the on change
   */
  ngOnInit() {
    this.control.valueChanges
      .pipe(takeUntil(this._destroying$))
      .subscribe((campaign) => {
        this._onTouched?.();
        this._onChange?.(campaign);
      });
  }

  /**
   * On Destroy.
   * Emits the destroying$ subject when the component is destroyed.
   */
  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

  /**
   * Format Campaign Option.
   * Format the campaign option for the campaign select.
   */
  formatCampaignOption(campaign: CampaignModel) {
    const startDate = new Date(campaign.start_date).toLocaleDateString();
    const endDate = new Date(campaign.end_date).toLocaleDateString();
    return `${campaign.name} (${startDate} - ${endDate})`;
  }

  /**
   * Register On Change.
   * Registers the on change callback.
   */
  registerOnChange(callback: (value: CampaignControlValue) => void) {
    this._onChange = callback;
  }

  /**
   * Register On Touched.
   * Registers the on touched callback.
   */
  registerOnTouched(callback: () => void) {
    this._onTouched = callback;
  }

  /**
   * Set Disabled State.
   * Sets the disabled state of the campaign control.
   */
  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  /**
   * Validate.
   * Validates the campaign control.
   */
  validate(): ValidationErrors | null {
    return this.control.valid ? null : { invalid: true };
  }

  /**
   * Write Value.
   * Writes the value of the campaign control. If the value is null, the form
   * control is reset.
   */
  writeValue(value: CampaignControlValue) {
    value !== null
      ? this.control.patchValue(value, { emitEvent: false })
      : this.control.reset(null, { emitEvent: false });
  }
}
