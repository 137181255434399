import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MatDialog } from '@angular/material/dialog';
import { ArcContainer, ArcNavbar } from '@arc-web/components';
import { AuthStore } from '@auth/state/auth.store';
import { SettingsStore } from '@core/state/settings.store';
import { ConfigService } from '@core/services/config.service';
import {
  ACKNOWLEDGEMENT_MODAL_CONFIG,
  AcknowledgementModalComponent,
  AcknowledgementModalData,
  AcknowledgementModalResult,
  acknowledgementModalResultIsObject,
} from '@core/components/acknowledgement-modal/acknowledgement-modal.component';
import {
  SYSTEM_NOTIFICATION_CONFIG,
  SystemNotificationComponent,
  SystemNotificationData,
} from '@core/components/system-notification/system-notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '@core/services/local-storage.service';
import { IS_IFRAME } from '@core/tokens/is-iframe.token';
import { MgtService } from '@mgt/services/mgt.service';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { ContainerTheme } from '@arc-web/components/dist/components/container/constants/ContainerConstants';
import { WINDOW } from '@core/tokens/window.token';
import { PosthogService } from '@monitoring/services/posthog.service';
import { PermissionsService } from '@auth/services/permissions.service';
import { combineLatest, filter, map, of, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

/**
 * App Component.
 * Renders the app's root component that contains the ArcContainer component with the application header
 * and footer along with the main entry router-outlet. Also handles displaying any global messages and
 * acknowledgements to the user.
 */
@Component({
  selector: 'zero-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  /**
   * Observable that emits when the component is destroyed.
   */
  private readonly _destroying$ = new Subject<void>();

  /**
   * Small Screen.
   * Observable that emits true if the screen is small.
   */
  private readonly _smallScreen$ = this.breakpointObserver
    .observe(['(max-width: 720px)'])
    .pipe(map((result) => result.matches));

  /**
   * Observable that emits the view model for the component.
   */
  readonly vm$ = combineLatest([
    this.authStore.authenticated$,
    this.permissionService.userCanManageProjects$,
    of(this.isIframe),
    of(this.configService.config.maintenance),
    this._smallScreen$,
  ]).pipe(
    map(
      ([
        authenticated,
        userCanManageProjects,
        isIframe,
        maintenance,
        smallScreen,
      ]) => ({
        authenticated,
        userCanManageProjects,
        isIframe,
        maintenance,
        smallScreen,
      }),
    ),
  );

  /**
   * ArcContainer element.
   */
  @ViewChild('container') container!: ElementRef<ArcContainer>;

  /**
   * ArcNavbar element.
   */
  @ViewChild('navbar') navbar!: ElementRef<ArcNavbar>;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authStore: AuthStore,
    private readonly settingsStore: SettingsStore,
    private permissionService: PermissionsService,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private readonly mgtService: MgtService,
    private posthogService: PosthogService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private renderer: Renderer2,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_IFRAME) public isIframe: boolean,
  ) {}

  /**
   * After the component is initialized, call the MgtService to initialize
   * the MGT component a configuration and global provider. Then calls the
   * AuthService to update the active account of the user, and the MSAL
   * service instance to enable account storage events to be broadcast.
   * Finally, it subscribes to the MSAL broadcast service to listen for
   * account storage events and update the active account.
   */
  ngOnInit() {
    this.mgtService.initialize();
    this.authStore.updateActiveAccount();
    this.msalService.instance.enableAccountStorageEvents();
    this.posthogService.initialize();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => this.authStore.updateActiveAccount());
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => this.authStore.updateActiveAccount());
  }

  /**
   * After the view is initialized, add event listeners for the accessibility change event
   * to the ArcContainer component. Then, open the test environment acknowledgement modal
   * if the user is in the test environment. Finally, open the system notification snackbar
   * if there is a system notification to display.
   */
  ngAfterViewInit() {
    this.container.nativeElement.addEventListener(
      'arc-accessibility-change',
      this.handleAccessibilityChanged.bind(this) as EventListener,
    );
    this._openTestEnvironmentAcknowledgement();
    this._openSystemNotification();
  }

  /**
   * After the component is destroyed, unsubscribe from all observables.
   */
  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  /**
   * Open System Notification.
   * Opens the system notification snackbar, with the given notification data
   * from the config service, if it exists.
   */
  private _openSystemNotification() {
    if (this.configService.config.appDefaults.notification) {
      this.snackBar.openFromComponent(SystemNotificationComponent, {
        ...SYSTEM_NOTIFICATION_CONFIG,
        data: <SystemNotificationData>{
          type: this.configService.config.appDefaults.notification.type,
          title: this.configService.config.appDefaults.notification.title,
          message: this.configService.config.appDefaults.notification.message,
        },
      });
    }
  }

  /**
   * Open Test Environment Acknowledgement Modal.
   * Opens the acknowledgement modal for the test environment.
   * If the user selects to skip the message, the message will not be displayed again.
   * The skip message is stored in local storage. If the user clears their local storage,
   * the message will be displayed again.
   */
  private _openTestEnvironmentAcknowledgement() {
    if (
      this.configService.config.env !== 'test' ||
      this.localStorageService.getItem<boolean>('arup.zero.skip-test-message')
    ) {
      return;
    }
    this.dialog
      .open(AcknowledgementModalComponent, {
        ...ACKNOWLEDGEMENT_MODAL_CONFIG,
        data: <AcknowledgementModalData>{
          title: 'This is a test environment. Any asset data will not stored.',
          message:
            'This is an environment for you to get familiar with the platform before submitting your final assessments for the current data collection period. Any data submitted through this view will not be stored.',
          action: 'Okay, got it!',
          skippable: true,
        },
      })
      .afterClosed()
      .subscribe((result: AcknowledgementModalResult) => {
        if (acknowledgementModalResultIsObject(result)) {
          this.localStorageService.setItem(
            'arup.zero.skip-test-message',
            result.skip,
          );
        }
      });
  }

  /**
   * Event handler for the show accessibility event emitted by the ArcIconButton component
   * in the ArcBottomBar components. Passes the event to the ArcContainer component to
   * show the accessibility panel.
   */
  handleShowAccessibility() {
    this.container.nativeElement.showAccessibility();
  }

  /**
   * Handle the accessibility change event emitted by the ArcContainer component.
   * Call the updateTheme method to update the theme of the application.
   */
  handleAccessibilityChanged(
    event: CustomEvent<{ preferences: { theme: ContainerTheme } }>,
  ) {
    this.updateTheme(event.detail.preferences.theme);
  }

  /**
   * Update the application theme by getting the current theme from the ArcContainer component
   * and checking if the theme is set to auto. If it is, then check the user's system preferences
   * for the theme. Then update the theme of the Arc, Angular Material and MGT components. The
   * ConfigService theme state is also updated.
   */
  updateTheme(theme: ContainerTheme) {
    if (theme === 'auto')
      theme = this.window.matchMedia('(prefers-color-scheme: light)').matches
        ? 'light'
        : 'dark';
    this.settingsStore.updateTheme(theme);
    this.renderer.setAttribute(this.document.body, 'theme', theme);
    this.renderer.removeClass(
      this.document.body,
      theme === 'light' ? 'mgt-dark' : 'mgt-light',
    );
    this.renderer.addClass(
      this.document.body,
      theme === 'light' ? 'mgt-light' : 'mgt-dark',
    );
    this.container.nativeElement.setAttribute('theme', theme);
    this.navbar.nativeElement.setAttribute(
      'logo',
      theme === 'light' && this.configService.config.env !== 'test'
        ? this.configService.config.appDefaults.lightLogoHeaderURL
        : this.configService.config.appDefaults.darkLogoHeaderURL,
    );
    this.navbar.nativeElement.setAttribute(
      'env',
      this.configService.config.env,
    );
  }
}
