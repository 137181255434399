import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DetailedModel } from '@detailed-assessment/models/detailed.model';
import {
  DETAILED_PARAMETER_NAME,
  DetailedParameterName,
} from '@detailed-assessment/types/detailed-paramter-name';
import { EnergyModelModel } from '@energy-model-assessment/models/energy-model/energy-model.model';
import { SubsystemName } from '@core/models/subsystem';
import { SystemName } from '@asset/enums/system-name';
import { HttpService } from '@core/services/http.service';
import { UtilsService } from '@core/services/utils.service';
import { EnergyModelUploadModel } from '@energy-model-assessment/models/energy-model-upload/energy-model-upload.model';
import { Assessment } from '@core/types/assessment';
import { ENERGY_MODEL_ENDPOINT } from '@energy-model-assessment/tokens/energy-model-endpoint.token';
import { ASSESSMENT_TYPE } from '@core/types/assessment-type';

/**
 * Energy Model Service.
 * Responsible for handling energy model assessment related operations.
 */
@Injectable({ providedIn: 'root' })
export class EnergyModelService {
  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly httpService: HttpService,
    @Inject(ENERGY_MODEL_ENDPOINT) private readonly energyModelEndpoint: string,
  ) {}

  /**
   * Get Energy Model.
   * Makes API call to get energy model assessment for given asset id.
   */
  getEnergyModel(assetId: number) {
    return this.http
      .get<EnergyModelModel>(`${this.energyModelEndpoint}/${assetId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssessmentError(ASSESSMENT_TYPE.EnergyModel),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Upload Energy Model.
   * Makes API call to upload energy model assessment.
   */
  uploadEnergyModel(energyModel: EnergyModelUploadModel) {
    return this.http
      .put<EnergyModelModel>(
        this.energyModelEndpoint,
        this.utilsService.convertObjectPropsToApiDateFormat(energyModel, [
          'assessment_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Override Detailed Assessment With Energy Model.
   * Overrides the detailed assessment with the energy model assessment.
   */
  overrideDetailedAssessmentWithEnergyModel(
    energyModel: Assessment<EnergyModelModel>,
    detailed: Assessment<DetailedModel>,
  ): Assessment<DetailedModel> {
    if (energyModel === null && detailed === null) return null;
    // Create temp assessments
    const tempDetailed: Partial<DetailedModel> &
      Pick<DetailedModel, 'systems' | 'created_at' | 'completed_by'> =
      detailed !== null
        ? { ...detailed }
        : {
            completed_by: energyModel?.completed_by ?? '',
            created_at: energyModel?.created_at ?? '',
            systems: [],
          };
    const tempEnergyModel =
      energyModel !== null
        ? [
            {
              system_name: SystemName.ElectricalServices,
              subsystems: energyModel.ElectricalServices.map(
                (
                  subsystem,
                ): {
                  subsystem_name: SubsystemName;
                  parameters: {
                    param_name: DetailedParameterName;
                    value: number;
                  }[];
                } => ({
                  subsystem_name: subsystem.subsystem!,
                  parameters: [
                    {
                      param_name: DETAILED_PARAMETER_NAME.B6,
                      value: subsystem.stage_B6_decarb ?? subsystem.stage_B6,
                    },
                    {
                      param_name: DETAILED_PARAMETER_NAME.B7,
                      value: subsystem.stage_B7_decarb ?? subsystem.stage_B7,
                    },
                  ],
                }),
              ),
            },
            {
              system_name: SystemName.MechanicalServices,
              subsystems: energyModel.MechanicalServices.map(
                (
                  subsystem,
                ): {
                  subsystem_name: SubsystemName;
                  parameters: {
                    param_name: DetailedParameterName;
                    value: number;
                  }[];
                } => ({
                  subsystem_name: subsystem.subsystem!,
                  parameters: [
                    {
                      param_name: DETAILED_PARAMETER_NAME.B6,
                      value: subsystem.stage_B6_decarb ?? subsystem.stage_B6,
                    },
                    {
                      param_name: DETAILED_PARAMETER_NAME.B7,
                      value: subsystem.stage_B7_decarb ?? subsystem.stage_B7,
                    },
                  ],
                }),
              ),
            },
            {
              system_name: SystemName.PublicHealthAndHydraulics,
              subsystems: energyModel.PublicHealthHydraulics.map(
                (
                  subsystem,
                ): {
                  subsystem_name: SubsystemName;
                  parameters: {
                    param_name: DetailedParameterName;
                    value: number;
                  }[];
                } => ({
                  subsystem_name: subsystem.subsystem!,
                  parameters: [
                    {
                      param_name: DETAILED_PARAMETER_NAME.B6,
                      value: subsystem.stage_B6_decarb ?? subsystem.stage_B6,
                    },
                    {
                      param_name: DETAILED_PARAMETER_NAME.B7,
                      value: subsystem.stage_B7_decarb ?? subsystem.stage_B7,
                    },
                  ],
                }),
              ),
            },
          ]
        : [];

    // for each temp energy model system
    tempEnergyModel.forEach((s) => {
      // find the system from the temp detailed assessment
      const tempSystem = tempDetailed.systems.find(
        (ts) => ts.system_name === s.system_name,
      );
      if (tempSystem) {
        // for each subsystem in temp energy model system
        s.subsystems.forEach((ss) => {
          // find the subsystem from temp detailed system
          const tempSubsystem = tempSystem.subsystems.find(
            (tss) => tss.subsystem_name === ss.subsystem_name,
          );
          if (tempSubsystem) {
            ss.parameters.forEach((p) => {
              // find the parameter from temp detailed system subsystems
              const tempParameter = tempSubsystem.parameters.find(
                (tp) => tp.param_name === p.param_name,
              );
              if (tempParameter) {
                // replace temp detailed system subsystem param with temp energy model param
                tempParameter.value = p.value;
              } else {
                // add temp energy model parameter to temp detailed system subsystem params
                tempSubsystem.parameters.push(p);
              }
            });
          } else {
            // add temp energy model subsystem to temp detailed system subsystems
            tempSystem.subsystems.push(ss);
          }
        });
      } else {
        // add temp energy model system to temp detailed assessment
        tempDetailed.systems.push(s);
      }
    });

    return tempDetailed as DetailedModel;
  }

  /**
   * System Has Energy Model Override.
   * Checks if the system has an energy model override.
   */
  systemHasEnergyModelOverride(
    energyModel: Assessment<EnergyModelModel>,
    systemName: SystemName,
  ) {
    if (energyModel === null) return false;
    switch (systemName) {
      case SystemName.ElectricalServices:
        return energyModel!.ElectricalServices.length > 0;
      case SystemName.MechanicalServices:
        return energyModel!.MechanicalServices.length > 0;
      case SystemName.PublicHealthAndHydraulics:
        return energyModel!.PublicHealthHydraulics.length > 0;
      default:
        return false;
    }
  }

  /**
   * Subsystem Contained In Energy Model.
   * Returns an array of subsystem names contained in the energy model assessment.
   */
  subsystemsContainedInEnergyModel(
    energyModel: Assessment<EnergyModelModel>,
    systemName: SystemName,
  ) {
    if (energyModel === null) return [];
    switch (systemName) {
      case SystemName.MechanicalServices:
        return energyModel.MechanicalServices.map(
          (subsystem) => subsystem.subsystem,
        );
      case SystemName.ElectricalServices:
        return energyModel.ElectricalServices.map(
          (subsystem) => subsystem.subsystem,
        );
      case SystemName.PublicHealthAndHydraulics:
        return energyModel.PublicHealthHydraulics.map(
          (subsystem) => subsystem.subsystem,
        );
      default:
        return [];
    }
  }
}
