import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Optional,
} from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Loading Modal ID.
 * Defined the ID of the loading modal.
 */
export const LOADING_MODAL_ID = 'loading-modal';

/**
 * Loading Modal Config.
 * Defines the configuration of the loading modal.
 */
export const LOADING_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
  id: LOADING_MODAL_ID,
};

/**
 * Loading Modal Operation.
 * Defines the operation that is passed to the loading modal.
 */
export type LoadingModalOperation = Symbol;

/**
 * Loading Modal Message.
 * Defines the message that is passed to the loading modal.
 */
export type LoadingModalMessage = string;

/**
 * Loading Modal Data.
 * Defines the data that is passed to the loading modal.
 */
export type LoadingModalData = {
  /* The operation to be performed. */
  operation: LoadingModalOperation;
  /* The message to be displayed in the loading modal. */
  message?: LoadingModalMessage;
};

/**
 * Loading Modal Component.
 * Responsible for rendering a loading spinner.
 */
@Component({
  selector: 'zero-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingModalComponent {
  constructor(
    public readonly ref: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: LoadingModalData,
  ) {}
}
