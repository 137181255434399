<div class="under-construction">
  <div class="content">
    <img
      alt="Zero logo"
      class="zero-logo"
      src="/src/assets/zero-logo-light.svg"
    />
    <h1>
      Sorry, this does not exist yet,<br />
      but you can see what<br />
      we’ve got planned...
    </h1>
    <span>
      <a
        mat-stroked-button
        class="zero-button-outlined"
        color="accent"
        href="https://docs.zero.arup.com"
      >
        Find out more
      </a>
    </span>
  </div>
  <svg
    class="background-left"
    fill="none"
    viewBox="0 0 2140 3125"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask fill="white" id="path-1-inside-1_1864_21251">
      <path
        d="M885.846 73.3066C825.601 113.11 772.974 163.384 730.458 221.747L860.099 316.187C891.336 273.309 930.001 236.373 974.262 207.129L885.846 73.3066Z"
      />
    </mask>
    <path
      d="M885.846 73.3066C825.601 113.11 772.974 163.384 730.458 221.747L860.099 316.187C891.336 273.309 930.001 236.373 974.262 207.129L885.846 73.3066Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-1-inside-1_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-2-inside-2_1864_21251">
      <path
        d="M507.031 171.91C450.694 267.492 421.746 376.733 423.357 487.671C424.968 598.608 457.077 706.963 516.165 800.868L772.011 639.88C742.467 592.927 726.413 538.75 725.607 483.281C724.802 427.813 739.276 373.192 767.444 325.401L507.031 171.91Z"
      />
    </mask>
    <path
      d="M507.031 171.91C450.694 267.492 421.746 376.733 423.357 487.671C424.968 598.608 457.077 706.963 516.165 800.868L772.011 639.88C742.467 592.927 726.413 538.75 725.607 483.281C724.802 427.813 739.276 373.192 767.444 325.401L507.031 171.91Z"
      mask="url(#path-2-inside-2_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-3-inside-3_1864_21251">
      <path
        d="M631.651 812.212C675.685 878.486 732.369 935.417 798.452 979.738L966.826 728.691C933.784 706.53 905.442 678.065 883.425 644.928L631.651 812.212Z"
      />
    </mask>
    <path
      d="M631.651 812.212C675.685 878.486 732.369 935.417 798.452 979.738L966.826 728.691C933.784 706.53 905.442 678.065 883.425 644.928L631.651 812.212Z"
      mask="url(#path-3-inside-3_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-4-inside-4_1864_21251">
      <path
        d="M1132.13 -126.912C1245.94 -127.49 1357.59 -95.9331 1454.26 -35.871C1550.93 24.191 1628.68 110.319 1678.57 212.606L1406.89 345.125C1381.94 293.981 1343.06 250.917 1294.73 220.886C1246.4 190.855 1190.57 175.077 1133.67 175.366L1132.13 -126.912Z"
      />
    </mask>
    <path
      d="M1132.13 -126.912C1245.94 -127.49 1357.59 -95.9331 1454.26 -35.871C1550.93 24.191 1628.68 110.319 1678.57 212.606L1406.89 345.125C1381.94 293.981 1343.06 250.917 1294.73 220.886C1246.4 190.855 1190.57 175.077 1133.67 175.366L1132.13 -126.912Z"
      mask="url(#path-4-inside-4_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M754.374 -203.04C860.695 -264.706 980.869 -298.521 1103.75 -301.35L1111.08 60.4961C1049.82 62.0716 989.91 78.9281 936.811 109.534L754.374 -203.04Z"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-6-inside-5_1864_21251">
      <path
        d="M1579.85 273.257C1594.06 303.748 1605.2 335.581 1613.1 368.283L1435.98 411.065C1430.93 390.136 1423.8 369.763 1414.7 350.249L1579.85 273.257Z"
      />
    </mask>
    <path
      d="M1579.85 273.257C1594.06 303.748 1605.2 335.581 1613.1 368.283L1435.98 411.065C1430.93 390.136 1423.8 369.763 1414.7 350.249L1579.85 273.257Z"
      mask="url(#path-6-inside-5_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-7-inside-6_1864_21251">
      <path
        d="M1614.48 374.126C1618.59 392.11 1621.73 410.305 1623.86 428.631L1442.87 449.688C1441.5 437.96 1439.5 426.315 1436.86 414.805L1614.48 374.126Z"
      />
    </mask>
    <path
      d="M1614.48 374.126C1618.59 392.11 1621.73 410.305 1623.86 428.631L1442.87 449.688C1441.5 437.96 1439.5 426.315 1436.86 414.805L1614.48 374.126Z"
      mask="url(#path-7-inside-6_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-8-inside-7_1864_21251">
      <path
        d="M1749.71 435.404C1752.02 458.068 1752.79 480.863 1752.03 503.632L1569.92 497.52C1570.41 482.948 1569.91 468.36 1568.44 453.854L1749.71 435.404Z"
      />
    </mask>
    <path
      d="M1749.71 435.404C1752.02 458.068 1752.79 480.863 1752.03 503.632L1569.92 497.52C1570.41 482.948 1569.91 468.36 1568.44 453.854L1749.71 435.404Z"
      mask="url(#path-8-inside-7_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="248.928"
      id="path-9-outside-8_1864_21251"
      maskUnits="userSpaceOnUse"
      width="248.928"
      x="1384.19"
      y="446.854"
    >
      <rect
        fill="white"
        height="248.928"
        width="248.928"
        x="1384.19"
        y="446.854"
      />
      <path
        d="M1571.74 508.466C1567.97 559.909 1555.09 610.274 1533.71 657.215L1431.38 610.605C1447.24 575.774 1456.8 538.404 1459.6 500.233L1571.74 508.466Z"
      />
    </mask>
    <path
      d="M1571.74 508.466C1567.97 559.909 1555.09 610.274 1533.71 657.215L1431.38 610.605C1447.24 575.774 1456.8 538.404 1459.6 500.233L1571.74 508.466Z"
      mask="url(#path-9-outside-8_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="232.23"
      id="path-10-outside-9_1864_21251"
      maskUnits="userSpaceOnUse"
      width="236.904"
      x="1332.39"
      y="576.362"
    >
      <rect
        fill="white"
        height="232.23"
        width="236.904"
        x="1332.39"
        y="576.362"
      />
      <path
        d="M1531.12 662.786C1508.89 709.825 1478.42 752.505 1441.15 788.803L1362.69 708.243C1390.35 681.309 1412.96 649.641 1429.46 614.738L1531.12 662.786Z"
      />
    </mask>
    <path
      d="M1531.12 662.786C1508.89 709.825 1478.42 752.505 1441.15 788.803L1362.69 708.243C1390.35 681.309 1412.96 649.641 1429.46 614.738L1531.12 662.786Z"
      mask="url(#path-10-outside-9_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-11-inside-10_1864_21251">
      <path
        d="M989.77 228.485C1050.09 193.276 1121.19 181.229 1189.87 194.58C1258.54 207.93 1320.12 245.772 1363.19 301.082C1406.26 356.392 1427.89 425.412 1424.06 495.334C1420.22 565.256 1391.2 631.329 1342.36 681.292L1282.47 622.529C1317.15 587.048 1337.76 540.126 1340.48 490.472C1343.2 440.817 1327.85 391.802 1297.26 352.524C1266.68 313.246 1222.94 286.372 1174.17 276.891C1125.41 267.41 1074.92 275.965 1032.08 300.969L989.77 228.485Z"
      />
    </mask>
    <path
      d="M989.77 228.485C1050.09 193.276 1121.19 181.229 1189.87 194.58C1258.54 207.93 1320.12 245.772 1363.19 301.082C1406.26 356.392 1427.89 425.412 1424.06 495.334C1420.22 565.256 1391.2 631.329 1342.36 681.292L1282.47 622.529C1317.15 587.048 1337.76 540.126 1340.48 490.472C1343.2 440.817 1327.85 391.802 1297.26 352.524C1266.68 313.246 1222.94 286.372 1174.17 276.891C1125.41 267.41 1074.92 275.965 1032.08 300.969L989.77 228.485Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-11-inside-10_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-12-inside-11_1864_21251">
      <path
        d="M1574.75 975.704C1477.87 1076.28 1349.15 1140.21 1210.48 1156.64C1071.8 1173.07 931.711 1140.99 814.008 1065.84L976.674 811.061C1035.53 848.635 1105.57 864.677 1174.91 856.461C1244.25 848.245 1308.6 816.279 1357.05 765.992L1574.75 975.704Z"
      />
    </mask>
    <path
      d="M1574.75 975.704C1477.87 1076.28 1349.15 1140.21 1210.48 1156.64C1071.8 1173.07 931.711 1140.99 814.008 1065.84L976.674 811.061C1035.53 848.635 1105.57 864.677 1174.91 856.461C1244.25 848.245 1308.6 816.279 1357.05 765.992L1574.75 975.704Z"
      mask="url(#path-12-inside-11_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-13-inside-12_1864_21251">
      <path
        d="M1366.73 707.892C1334.1 740.85 1294.79 766.455 1251.46 782.992C1208.12 799.529 1161.76 806.616 1115.46 803.78C1069.16 800.943 1024.01 788.248 983.017 766.545C942.025 744.841 906.143 714.63 877.775 677.935C849.406 641.239 829.204 598.906 818.523 553.769C807.842 508.633 806.928 461.736 815.841 416.217C824.755 370.699 843.291 327.611 870.208 289.837C897.125 252.063 931.802 220.477 971.916 197.192L1018.94 278.196C990.361 294.783 965.659 317.283 946.485 344.191C927.311 371.099 914.107 401.792 907.757 434.217C901.408 466.641 902.059 500.048 909.668 532.201C917.276 564.353 931.667 594.509 951.875 620.649C972.083 646.789 997.643 668.309 1026.84 683.769C1056.04 699.23 1088.21 708.273 1121.19 710.293C1154.17 712.314 1187.2 707.265 1218.07 695.485C1248.93 683.705 1276.93 665.466 1300.18 641.988L1366.73 707.892Z"
      />
    </mask>
    <path
      d="M1366.73 707.892C1334.1 740.85 1294.79 766.455 1251.46 782.992C1208.12 799.529 1161.76 806.616 1115.46 803.78C1069.16 800.943 1024.01 788.248 983.017 766.545C942.025 744.841 906.143 714.63 877.775 677.935C849.406 641.239 829.204 598.906 818.523 553.769C807.842 508.633 806.928 461.736 815.841 416.217C824.755 370.699 843.291 327.611 870.208 289.837C897.125 252.063 931.802 220.477 971.916 197.192L1018.94 278.196C990.361 294.783 965.659 317.283 946.485 344.191C927.311 371.099 914.107 401.792 907.757 434.217C901.408 466.641 902.059 500.048 909.668 532.201C917.276 564.353 931.667 594.509 951.875 620.649C972.083 646.789 997.643 668.309 1026.84 683.769C1056.04 699.23 1088.21 708.273 1121.19 710.293C1154.17 712.314 1187.2 707.265 1218.07 695.485C1248.93 683.705 1276.93 665.466 1300.18 641.988L1366.73 707.892Z"
      mask="url(#path-13-inside-12_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-14-inside-13_1864_21251">
      <path
        d="M297.449 37.3611C339.216 45.8947 382.174 46.877 424.288 40.2615L409.593 -53.286C378.652 -48.4256 347.091 -49.1474 316.405 -55.417L297.449 37.3611Z"
      />
    </mask>
    <path
      d="M297.449 37.3611C339.216 45.8947 382.174 46.877 424.288 40.2615L409.593 -53.286C378.652 -48.4256 347.091 -49.1474 316.405 -55.417L297.449 37.3611Z"
      mask="url(#path-14-inside-13_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-15-inside-14_1864_21251">
      <path
        d="M496.758 154.341C560.179 137.958 617.869 104.437 663.51 57.4517C709.15 10.466 740.981 -48.1731 755.515 -112.044L581.5 -151.644C574.232 -119.709 558.317 -90.389 535.497 -66.8962C512.676 -43.4033 483.831 -26.6433 452.121 -18.4515L496.758 154.341Z"
      />
    </mask>
    <path
      d="M496.758 154.341C560.179 137.958 617.869 104.437 663.51 57.4517C709.15 10.466 740.981 -48.1731 755.515 -112.044L581.5 -151.644C574.232 -119.709 558.317 -90.389 535.497 -66.8962C512.676 -43.4033 483.831 -26.6433 452.121 -18.4515L496.758 154.341Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-15-inside-14_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-16-inside-15_1864_21251">
      <path
        d="M111.047 18.1301C63.2955 -29.1391 29.8559 -88.9268 14.5744 -154.357C-0.707204 -219.786 2.78984 -288.201 24.6634 -351.732L193.407 -293.633C182.47 -261.868 180.721 -227.661 188.362 -194.946C196.003 -162.231 212.723 -132.337 236.599 -108.703L111.047 18.1301Z"
      />
    </mask>
    <path
      d="M111.047 18.1301C63.2955 -29.1391 29.8559 -88.9268 14.5744 -154.357C-0.707204 -219.786 2.78984 -288.201 24.6634 -351.732L193.407 -293.633C182.47 -261.868 180.721 -227.661 188.362 -194.946C196.003 -162.231 212.723 -132.337 236.599 -108.703L111.047 18.1301Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-16-inside-15_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M236.385 206.61C166.962 188.013 103.306 152.314 51.2347 102.776L198.099 -50.1667C224.125 -25.6298 255.789 -7.87243 290.298 1.539L236.385 206.61Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-18-inside-16_1864_21251">
      <path
        d="M133.914 712.818C160.608 705.38 185.754 693.221 208.16 676.915L171.941 627.145C155.479 639.124 137.005 648.058 117.393 653.522L133.914 712.818Z"
      />
    </mask>
    <path
      d="M133.914 712.818C160.608 705.38 185.754 693.221 208.16 676.915L171.941 627.145C155.479 639.124 137.005 648.058 117.393 653.522L133.914 712.818Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-18-inside-16_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-19-inside-17_1864_21251">
      <path
        d="M283.889 721.448C315.73 693.178 339.176 656.691 351.662 615.984C364.147 575.276 365.19 531.918 354.675 490.657L242.26 519.304C247.517 539.934 246.996 561.613 240.753 581.967C234.511 602.321 222.787 620.564 206.867 634.699L283.889 721.448Z"
      />
    </mask>
    <path
      d="M283.889 721.448C315.73 693.178 339.176 656.691 351.662 615.984C364.147 575.276 365.19 531.918 354.675 490.657L242.26 519.304C247.517 539.934 246.996 561.613 240.753 581.967C234.511 602.321 222.787 620.564 206.867 634.699L283.889 721.448Z"
      mask="url(#path-19-inside-17_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-20-inside-18_1864_21251">
      <path
        d="M313.833 472.904C305.561 443.509 291.567 416.036 272.655 392.061L181.574 463.909C191.03 475.896 198.027 489.633 202.163 504.33L313.833 472.904Z"
      />
    </mask>
    <path
      d="M313.833 472.904C305.561 443.509 291.567 416.036 272.655 392.061L181.574 463.909C191.03 475.896 198.027 489.633 202.163 504.33L313.833 472.904Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-20-inside-18_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-21-inside-19_1864_21251">
      <path
        d="M20.3674 756.92C-21.2659 743.719 -58.3277 719.035 -86.5553 685.707C-114.783 652.378 -133.03 611.758 -139.198 568.52L-24.3524 552.138C-21.2685 573.757 -12.145 594.067 1.96877 610.731C16.0826 627.396 34.6135 639.738 55.4301 646.338L20.3674 756.92Z"
      />
    </mask>
    <path
      d="M20.3674 756.92C-21.2659 743.719 -58.3277 719.035 -86.5553 685.707C-114.783 652.378 -133.03 611.758 -139.198 568.52L-24.3524 552.138C-21.2685 573.757 -12.145 594.067 1.96877 610.731C16.0826 627.396 34.6135 639.738 55.4301 646.338L20.3674 756.92Z"
      mask="url(#path-21-inside-19_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M147.895 828.297C102.774 837.869 55.9827 836.098 11.7142 823.144L50.9701 692.477C72.9887 698.756 96.1975 699.635 118.628 695.037L147.895 828.297Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask
      fill="black"
      height="89.5572"
      id="path-23-outside-20_1864_21251"
      maskUnits="userSpaceOnUse"
      width="90.3194"
      x="-79.9165"
      y="412.792"
    >
      <rect
        fill="white"
        height="89.5572"
        width="90.3194"
        x="-79.9165"
        y="412.792"
      />
      <path
        d="M-65.3141 473.052C-57.8722 454.708 -47.2332 437.832 -33.8923 423.207L-2.00982 452.29C-11.9088 463.142 -19.8029 475.665 -25.3248 489.275L-65.3141 473.052Z"
      />
    </mask>
    <path
      d="M-65.3141 473.052C-57.8722 454.708 -47.2332 437.832 -33.8923 423.207L-2.00982 452.29C-11.9088 463.142 -19.8029 475.665 -25.3248 489.275L-65.3141 473.052Z"
      mask="url(#path-23-outside-20_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="83.0533"
      id="path-24-outside-21_1864_21251"
      maskUnits="userSpaceOnUse"
      width="86.1021"
      x="-42.5182"
      y="378.602"
    >
      <rect
        fill="white"
        height="83.0533"
        width="86.1021"
        x="-42.5182"
        y="378.602"
      />
      <path
        d="M-32.2919 421.477C-18.6289 406.916 -2.46925 394.92 15.4221 386.055L34.5815 424.724C21.3061 431.301 9.31563 440.203 -0.822314 451.007L-32.2919 421.477Z"
      />
    </mask>
    <path
      d="M-32.2919 421.477C-18.6289 406.916 -2.46925 394.92 15.4221 386.055L34.5815 424.724C21.3061 431.301 9.31563 440.203 -0.822314 451.007L-32.2919 421.477Z"
      fill="rgb(var(--zero-color-background-svg))"
    />
    <path
      d="M-32.2919 421.477C-18.6289 406.916 -2.46925 394.92 15.4221 386.055L34.5815 424.724C21.3061 431.301 9.31563 440.203 -0.822314 451.007L-32.2919 421.477Z"
      mask="url(#path-24-outside-21_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-25-inside-22_1864_21251">
      <path
        d="M114.245 643.895C88.0631 649.646 60.6766 645.667 37.1683 632.698C13.66 619.728 -4.37356 598.649 -13.5853 573.373C-22.7969 548.097 -22.5612 520.34 -12.9217 495.254C-3.28221 470.168 15.1063 449.456 38.8309 436.964L53.7804 465.484C36.9324 474.356 23.8738 489.064 17.0284 506.879C10.1829 524.693 10.0155 544.405 16.5571 562.355C23.0988 580.305 35.9053 595.274 52.5997 604.484C69.2941 613.694 88.7426 616.52 107.336 612.436L114.245 643.895Z"
      />
    </mask>
    <path
      d="M114.245 643.895C88.0631 649.646 60.6766 645.667 37.1683 632.698C13.66 619.728 -4.37356 598.649 -13.5853 573.373C-22.7969 548.097 -22.5612 520.34 -12.9217 495.254C-3.28221 470.168 15.1063 449.456 38.8309 436.964L53.7804 465.484C36.9324 474.356 23.8738 489.064 17.0284 506.879C10.1829 524.693 10.0155 544.405 16.5571 562.355C23.0988 580.305 35.9053 595.274 52.5997 604.484C69.2941 613.694 88.7426 616.52 107.336 612.436L114.245 643.895Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-25-inside-22_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-26-inside-23_1864_21251">
      <path
        d="M-11.3479 302.048C35.889 276.733 90.4332 268.551 143.019 278.893C195.604 289.234 242.988 317.462 277.121 358.779L187.684 432.663C170.618 412.005 146.926 397.891 120.633 392.72C94.3405 387.549 67.0684 391.64 43.4499 404.298L-11.3479 302.048Z"
      />
    </mask>
    <path
      d="M-11.3479 302.048C35.889 276.733 90.4332 268.551 143.019 278.893C195.604 289.234 242.988 317.462 277.121 358.779L187.684 432.663C170.618 412.005 146.926 397.891 120.633 392.72C94.3405 387.549 67.0684 391.64 43.4499 404.298L-11.3479 302.048Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-26-inside-23_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-27-inside-24_1864_21251">
      <path
        d="M33.0646 424.376C48.8685 416.185 66.2401 411.465 84.0162 410.532C101.792 409.599 119.562 412.475 136.137 418.967C152.711 425.459 167.708 435.417 180.121 448.175C192.535 460.932 202.08 476.195 208.116 492.941C214.153 509.686 216.542 527.528 215.124 545.272C213.706 563.016 208.513 580.252 199.893 595.827C191.273 611.401 179.426 624.954 165.143 635.578C150.861 646.202 134.473 653.651 117.078 657.428L109.451 622.302C121.843 619.611 133.516 614.304 143.69 606.737C153.864 599.169 162.304 589.515 168.444 578.42C174.584 567.326 178.283 555.048 179.294 542.409C180.304 529.769 178.602 517.059 174.302 505.131C170.001 493.202 163.202 482.33 154.36 473.242C145.517 464.154 134.834 457.061 123.028 452.436C111.221 447.812 98.5626 445.763 85.9 446.428C73.2374 447.092 60.8629 450.455 49.6051 456.289L33.0646 424.376Z"
      />
    </mask>
    <path
      d="M33.0646 424.376C48.8685 416.185 66.2401 411.465 84.0162 410.532C101.792 409.599 119.562 412.475 136.137 418.967C152.711 425.459 167.708 435.417 180.121 448.175C192.535 460.932 202.08 476.195 208.116 492.941C214.153 509.686 216.542 527.528 215.124 545.272C213.706 563.016 208.513 580.252 199.893 595.827C191.273 611.401 179.426 624.954 165.143 635.578C150.861 646.202 134.473 653.651 117.078 657.428L109.451 622.302C121.843 619.611 133.516 614.304 143.69 606.737C153.864 599.169 162.304 589.515 168.444 578.42C174.584 567.326 178.283 555.048 179.294 542.409C180.304 529.769 178.602 517.059 174.302 505.131C170.001 493.202 163.202 482.33 154.36 473.242C145.517 464.154 134.834 457.061 123.028 452.436C111.221 447.812 98.5626 445.763 85.9 446.428C73.2374 447.092 60.8629 450.455 49.6051 456.289L33.0646 424.376Z"
      mask="url(#path-27-inside-24_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-28-inside-25_1864_21251">
      <path
        d="M214.931 1531.34C260.403 1540.63 307.172 1541.7 353.022 1534.5L337.023 1432.65C303.338 1437.95 268.977 1437.16 235.568 1430.33L214.931 1531.34Z"
      />
    </mask>
    <path
      d="M214.931 1531.34C260.403 1540.63 307.172 1541.7 353.022 1534.5L337.023 1432.65C303.338 1437.95 268.977 1437.16 235.568 1430.33L214.931 1531.34Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-28-inside-25_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-29-inside-26_1864_21251">
      <path
        d="M431.919 1658.7C500.966 1640.86 563.774 1604.37 613.464 1553.22C663.153 1502.06 697.807 1438.22 713.631 1368.69L524.178 1325.57C516.266 1360.34 498.939 1392.26 474.094 1417.84C449.25 1443.42 417.846 1461.66 383.322 1470.58L431.919 1658.7Z"
      />
    </mask>
    <path
      d="M431.919 1658.7C500.966 1640.86 563.774 1604.37 613.464 1553.22C663.153 1502.06 697.807 1438.22 713.631 1368.69L524.178 1325.57C516.266 1360.34 498.939 1392.26 474.094 1417.84C449.25 1443.42 417.846 1461.66 383.322 1470.58L431.919 1658.7Z"
      mask="url(#path-29-inside-26_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-30-inside-27_1864_21251">
      <path
        d="M666.298 1311.04C676.406 1260.9 676.518 1209.27 666.627 1159.09L475.998 1196.66C480.944 1221.75 480.888 1247.57 475.834 1272.64L666.298 1311.04Z"
      />
    </mask>
    <path
      d="M666.298 1311.04C676.406 1260.9 676.518 1209.27 666.627 1159.09L475.998 1196.66C480.944 1221.75 480.888 1247.57 475.834 1272.64L666.298 1311.04Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-30-inside-27_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-31-inside-28_1864_21251">
      <path
        d="M11.9906 1510.41C-39.9971 1458.94 -76.4032 1393.85 -93.0404 1322.62C-109.678 1251.38 -105.87 1176.9 -82.0564 1107.73L101.657 1170.99C89.7496 1205.57 87.8459 1242.81 96.1646 1278.43C104.483 1314.05 122.686 1346.59 148.68 1372.32L11.9906 1510.41Z"
      />
    </mask>
    <path
      d="M11.9906 1510.41C-39.9971 1458.94 -76.4032 1393.85 -93.0404 1322.62C-109.678 1251.38 -105.87 1176.9 -82.0564 1107.73L101.657 1170.99C89.7496 1205.57 87.8459 1242.81 96.1646 1278.43C104.483 1314.05 122.686 1346.59 148.68 1372.32L11.9906 1510.41Z"
      mask="url(#path-31-inside-28_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M148.574 1715.83C72.8392 1695.57 3.39943 1656.63 -53.3792 1602.57L106.759 1435.8C135.139 1462.6 169.695 1481.98 207.359 1492.22L148.574 1715.83Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-33-inside-29_1864_21251">
      <path
        d="M-9.61894 1125.04C-2.22127 1104.72 7.18385 1085.19 18.4568 1066.73L118.403 1127.79C111.188 1139.6 105.169 1152.1 100.435 1165.11L-9.61894 1125.04Z"
      />
    </mask>
    <path
      d="M-9.61894 1125.04C-2.22127 1104.72 7.18385 1085.19 18.4568 1066.73L118.403 1127.79C111.188 1139.6 105.169 1152.1 100.435 1165.11L-9.61894 1125.04Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-33-inside-29_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-34-inside-30_1864_21251">
      <path
        d="M20.4877 1063.45C26.7893 1053.41 33.6355 1043.72 40.9956 1034.42L132.828 1107.11C128.118 1113.06 123.736 1119.26 119.703 1125.69L20.4877 1063.45Z"
      />
    </mask>
    <path
      d="M20.4877 1063.45C26.7893 1053.41 33.6355 1043.72 40.9956 1034.42L132.828 1107.11C128.118 1113.06 123.736 1119.26 119.703 1125.69L20.4877 1063.45Z"
      mask="url(#path-34-inside-30_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-35-inside-31_1864_21251">
      <path
        d="M-13.1269 974.137C-3.87424 962.787 6.13413 952.075 16.83 942.074L96.8223 1027.62C89.977 1034.02 83.5716 1040.88 77.6499 1048.14L-13.1269 974.137Z"
      />
    </mask>
    <path
      d="M-13.1269 974.137C-3.87424 962.787 6.13413 952.075 16.83 942.074L96.8223 1027.62C89.977 1034.02 83.5716 1040.88 77.6499 1048.14L-13.1269 974.137Z"
      mask="url(#path-35-inside-31_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="146.567"
      id="path-36-outside-32_1864_21251"
      maskUnits="userSpaceOnUse"
      width="146.567"
      x="84.6202"
      y="948.719"
    >
      <rect
        fill="white"
        height="146.567"
        width="146.567"
        x="84.6202"
        y="948.719"
      />
      <path
        d="M100.968 1021.82C126.065 1000.15 154.809 983.115 185.862 971.497L211.189 1039.19C188.148 1047.81 166.819 1060.46 148.197 1076.53L100.968 1021.82Z"
      />
    </mask>
    <path
      d="M100.968 1021.82C126.065 1000.15 154.809 983.115 185.862 971.497L211.189 1039.19C188.148 1047.81 166.819 1060.46 148.197 1076.53L100.968 1021.82Z"
      mask="url(#path-36-outside-32_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="136.133"
      id="path-37-outside-33_1864_21251"
      maskUnits="userSpaceOnUse"
      width="141.123"
      x="168.951"
      y="924.726"
    >
      <rect
        fill="white"
        height="136.133"
        width="141.123"
        x="168.951"
        y="924.726"
      />
      <path
        d="M189.569 970.14C221.053 958.865 254.301 953.317 287.74 953.76L286.782 1026.03C261.97 1025.7 237.3 1029.82 213.939 1038.19L189.569 970.14Z"
      />
    </mask>
    <path
      d="M189.569 970.14C221.053 958.865 254.301 953.317 287.74 953.76L286.782 1026.03C261.97 1025.7 237.3 1029.82 213.939 1038.19L189.569 970.14Z"
      mask="url(#path-37-outside-33_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-38-inside-34_1864_21251">
      <path
        d="M238.224 1413.58C194.804 1402.17 157.014 1375.33 131.87 1338.05C106.725 1300.77 95.9335 1255.58 101.497 1210.86C107.061 1166.15 128.602 1124.95 162.123 1094.91C195.645 1064.87 238.869 1048.04 283.773 1047.52L284.287 1101.45C252.398 1101.82 221.702 1113.77 197.897 1135.11C174.092 1156.44 158.795 1185.69 154.844 1217.45C150.893 1249.2 158.556 1281.29 176.413 1307.77C194.269 1334.24 221.105 1353.3 251.94 1361.41L238.224 1413.58Z"
      />
    </mask>
    <path
      d="M238.224 1413.58C194.804 1402.17 157.014 1375.33 131.87 1338.05C106.725 1300.77 95.9335 1255.58 101.497 1210.86C107.061 1166.15 128.602 1124.95 162.123 1094.91C195.645 1064.87 238.869 1048.04 283.773 1047.52L284.287 1101.45C252.398 1101.82 221.702 1113.77 197.897 1135.11C174.092 1156.44 158.795 1185.69 154.844 1217.45C150.893 1249.2 158.556 1281.29 176.413 1307.77C194.269 1334.24 221.105 1353.3 251.94 1361.41L238.224 1413.58Z"
      mask="url(#path-38-inside-34_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-39-inside-35_1864_21251">
      <path
        d="M311.965 808.089C401.709 806.411 489.268 835.853 559.766 891.415C630.263 946.976 679.352 1025.23 698.694 1112.88L508.961 1154.75C499.291 1110.92 474.746 1071.8 439.497 1044.01C404.248 1016.23 360.469 1001.51 315.597 1002.35L311.965 808.089Z"
      />
    </mask>
    <path
      d="M311.965 808.089C401.709 806.411 489.268 835.853 559.766 891.415C630.263 946.976 679.352 1025.23 698.694 1112.88L508.961 1154.75C499.291 1110.92 474.746 1071.8 439.497 1044.01C404.248 1016.23 360.469 1001.51 315.597 1002.35L311.965 808.089Z"
      mask="url(#path-39-inside-35_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-40-inside-36_1864_21251">
      <path
        d="M284.787 1024.36C314.6 1024.21 344.1 1030.43 371.312 1042.61C398.524 1054.79 422.82 1072.65 442.573 1094.98C462.325 1117.31 477.079 1143.6 485.845 1172.1C494.612 1200.59 497.189 1230.63 493.405 1260.2C489.62 1289.78 479.561 1318.2 463.901 1343.57C448.241 1368.94 427.342 1390.67 402.602 1407.31C377.863 1423.94 349.854 1435.1 320.452 1440.04C291.049 1444.97 260.932 1443.57 232.117 1435.92L247.563 1377.73C268.09 1383.18 289.543 1384.18 310.488 1380.66C331.432 1377.15 351.384 1369.2 369.007 1357.35C386.63 1345.5 401.517 1330.02 412.673 1311.95C423.828 1293.87 430.993 1273.63 433.689 1252.56C436.385 1231.5 434.549 1210.1 428.304 1189.8C422.059 1169.5 411.55 1150.77 397.479 1134.87C383.409 1118.96 366.102 1106.24 346.718 1097.56C327.333 1088.89 306.319 1084.45 285.082 1084.56L284.787 1024.36Z"
      />
    </mask>
    <path
      d="M284.787 1024.36C314.6 1024.21 344.1 1030.43 371.312 1042.61C398.524 1054.79 422.82 1072.65 442.573 1094.98C462.325 1117.31 477.079 1143.6 485.845 1172.1C494.612 1200.59 497.189 1230.63 493.405 1260.2C489.62 1289.78 479.561 1318.2 463.901 1343.57C448.241 1368.94 427.342 1390.67 402.602 1407.31C377.863 1423.94 349.854 1435.1 320.452 1440.04C291.049 1444.97 260.932 1443.57 232.117 1435.92L247.563 1377.73C268.09 1383.18 289.543 1384.18 310.488 1380.66C331.432 1377.15 351.384 1369.2 369.007 1357.35C386.63 1345.5 401.517 1330.02 412.673 1311.95C423.828 1293.87 430.993 1273.63 433.689 1252.56C436.385 1231.5 434.549 1210.1 428.304 1189.8C422.059 1169.5 411.55 1150.77 397.479 1134.87C383.409 1118.96 366.102 1106.24 346.718 1097.56C327.333 1088.89 306.319 1084.45 285.082 1084.56L284.787 1024.36Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-40-inside-36_1864_21251)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M969 1126C850.637 1255.12 646.172 1626.37 775.221 2078.4C936.533 2643.44 323.071 2693.5 295.389 2964.74C273.243 3181.73 313.958 3225.03 337.084 3219.56"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="10"
    />
    <path
      d="M300 1593C359.008 1722.12 460.939 2093.37 396.604 2545.4C316.186 3110.44 622.014 3160.5 635.815 3431.74C646.856 3648.73 626.558 3692.03 615.029 3686.56"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="10"
    />
  </svg>
  <svg
    class="background-right"
    fill="none"
    viewBox="0 0 2503 2110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask fill="white" id="path-1-inside-1_1864_21250">
      <path
        d="M1980.36 1108.94C1908.27 1112.95 1837.47 1129.84 1771.34 1158.82L1835.69 1305.73C1884.29 1284.44 1936.3 1272.03 1989.27 1269.09L1980.36 1108.94Z"
      />
    </mask>
    <path
      d="M1980.36 1108.94C1908.27 1112.95 1837.47 1129.84 1771.34 1158.82L1835.69 1305.73C1884.29 1284.44 1936.3 1272.03 1989.27 1269.09L1980.36 1108.94Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-1-inside-1_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-2-inside-2_1864_21250">
      <path
        d="M1603.49 1003.15C1506.66 1057.3 1426.59 1137.06 1372.06 1233.68C1317.53 1330.3 1290.64 1440.07 1294.34 1550.96L1596.45 1540.89C1594.6 1485.44 1608.05 1430.56 1635.31 1382.25C1662.58 1333.94 1702.61 1294.06 1751.03 1266.98L1603.49 1003.15Z"
      />
    </mask>
    <path
      d="M1603.49 1003.15C1506.66 1057.3 1426.59 1137.06 1372.06 1233.68C1317.53 1330.3 1290.64 1440.07 1294.34 1550.96L1596.45 1540.89C1594.6 1485.44 1608.05 1430.56 1635.31 1382.25C1662.58 1333.94 1702.61 1294.06 1751.03 1266.98L1603.49 1003.15Z"
      mask="url(#path-2-inside-2_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-3-inside-3_1864_21250">
      <path
        d="M1388.36 1618.97C1392.98 1698.4 1413.24 1776.15 1447.97 1847.74L1719.93 1715.79C1702.57 1680 1692.44 1641.13 1690.13 1601.41L1388.36 1618.97Z"
      />
    </mask>
    <path
      d="M1388.36 1618.97C1392.98 1698.4 1413.24 1776.15 1447.97 1847.74L1719.93 1715.79C1702.57 1680 1692.44 1641.13 1690.13 1601.41L1388.36 1618.97Z"
      mask="url(#path-3-inside-3_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-4-inside-4_1864_21250">
      <path
        d="M2293.99 1060.17C2392.57 1117.04 2473.1 1200.58 2526.31 1301.18C2579.52 1401.78 2603.26 1515.36 2594.79 1628.85L2293.34 1606.35C2297.58 1549.6 2285.71 1492.81 2259.11 1442.51C2232.5 1392.21 2192.24 1350.44 2142.95 1322.01L2293.99 1060.17Z"
      />
    </mask>
    <path
      d="M2293.99 1060.17C2392.57 1117.04 2473.1 1200.58 2526.31 1301.18C2579.52 1401.78 2603.26 1515.36 2594.79 1628.85L2293.34 1606.35C2297.58 1549.6 2285.71 1492.81 2259.11 1442.51C2232.5 1392.21 2192.24 1350.44 2142.95 1322.01L2293.99 1060.17Z"
      mask="url(#path-4-inside-4_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M2006.12 804.003C2129.03 804.339 2249.86 835.712 2357.41 895.209L2181.35 1211.42C2127.64 1181.89 2067.4 1166.26 2006.12 1165.92V804.003Z"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-6-inside-5_1864_21250">
      <path
        d="M2478.95 1631.47C2475.86 1664.96 2469.43 1698.07 2459.77 1730.3L2285.23 1677.96C2291.42 1657.34 2295.53 1636.15 2297.51 1614.71L2478.95 1631.47Z"
      />
    </mask>
    <path
      d="M2478.95 1631.47C2475.86 1664.96 2469.43 1698.07 2459.77 1730.3L2285.23 1677.96C2291.42 1657.34 2295.53 1636.15 2297.51 1614.71L2478.95 1631.47Z"
      mask="url(#path-6-inside-5_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-7-inside-6_1864_21250">
      <path
        d="M2458.01 1736.04C2452.5 1753.64 2446.04 1770.94 2438.64 1787.84L2271.71 1714.79C2276.44 1703.97 2280.58 1692.91 2284.11 1681.64L2458.01 1736.04Z"
      />
    </mask>
    <path
      d="M2458.01 1736.04C2452.5 1753.64 2446.04 1770.94 2438.64 1787.84L2271.71 1714.79C2276.44 1703.97 2280.58 1692.91 2284.11 1681.64L2458.01 1736.04Z"
      mask="url(#path-7-inside-6_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-8-inside-7_1864_21250">
      <path
        d="M2543.92 1857.13C2534.49 1877.87 2523.67 1897.94 2511.53 1917.22L2357.33 1820.15C2365.1 1807.81 2372.02 1794.96 2378.06 1781.69L2543.92 1857.13Z"
      />
    </mask>
    <path
      d="M2543.92 1857.13C2534.49 1877.87 2523.67 1897.94 2511.53 1917.22L2357.33 1820.15C2365.1 1807.81 2372.02 1794.96 2378.06 1781.69L2543.92 1857.13Z"
      mask="url(#path-8-inside-7_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="182"
      id="path-9-outside-8_1864_21250"
      maskUnits="userSpaceOnUse"
      width="182"
      x="2176.21"
      y="1762"
    >
      <rect fill="white" height="182" width="182" x="2176.21" y="1762" />
      <path
        d="M2353.39 1830.52C2324.19 1873.05 2287.68 1910.05 2245.56 1939.82L2180.67 1847.98C2211.93 1825.89 2239.02 1798.43 2260.68 1766.88L2353.39 1830.52Z"
      />
    </mask>
    <path
      d="M2353.39 1830.52C2324.19 1873.05 2287.68 1910.05 2245.56 1939.82L2180.67 1847.98C2211.93 1825.89 2239.02 1798.43 2260.68 1766.88L2353.39 1830.52Z"
      mask="url(#path-9-outside-8_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="165"
      id="path-10-outside-9_1864_21250"
      maskUnits="userSpaceOnUse"
      width="178"
      x="2067.21"
      y="1846"
    >
      <rect fill="white" height="165" width="178" x="2067.21" y="1846" />
      <path
        d="M2240.52 1943.32C2197.6 1972.74 2149.77 1994.24 2099.28 2006.8L2072.14 1897.68C2109.6 1888.36 2145.09 1872.41 2176.93 1850.58L2240.52 1943.32Z"
      />
    </mask>
    <path
      d="M2240.52 1943.32C2197.6 1972.74 2149.77 1994.24 2099.28 2006.8L2072.14 1897.68C2109.6 1888.36 2145.09 1872.41 2176.93 1850.58L2240.52 1943.32Z"
      mask="url(#path-10-outside-9_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-11-inside-10_1864_21250">
      <path
        d="M1991.89 1295.35C2061.74 1295.35 2129.22 1320.78 2181.8 1366.93C2234.38 1413.08 2268.49 1476.81 2277.81 1546.29C2287.12 1615.76 2271.01 1686.27 2232.45 1744.73C2193.9 1803.19 2135.52 1845.62 2068.16 1864.15L2046.05 1783.21C2093.89 1770.05 2135.35 1739.92 2162.73 1698.41C2190.11 1656.89 2201.55 1606.82 2194.94 1557.48C2188.32 1508.14 2164.1 1462.88 2126.76 1430.11C2089.42 1397.34 2041.5 1379.28 1991.89 1379.28V1295.35Z"
      />
    </mask>
    <path
      d="M1991.89 1295.35C2061.74 1295.35 2129.22 1320.78 2181.8 1366.93C2234.38 1413.08 2268.49 1476.81 2277.81 1546.29C2287.12 1615.76 2271.01 1686.27 2232.45 1744.73C2193.9 1803.19 2135.52 1845.62 2068.16 1864.15L2046.05 1783.21C2093.89 1770.05 2135.35 1739.92 2162.73 1698.41C2190.11 1656.89 2201.55 1606.82 2194.94 1557.48C2188.32 1508.14 2164.1 1462.88 2126.76 1430.11C2089.42 1397.34 2041.5 1379.28 1991.89 1379.28V1295.35Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-11-inside-10_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-12-inside-11_1864_21250">
      <path
        d="M2120.46 2235.57C1986.09 2273.59 1842.69 2263.93 1714.64 2208.22C1586.59 2152.5 1481.77 2054.18 1418 1929.94L1686.92 1791.9C1718.81 1854.01 1771.21 1903.18 1835.24 1931.03C1899.27 1958.89 1970.96 1963.72 2038.15 1944.71L2120.46 2235.57Z"
      />
    </mask>
    <path
      d="M2120.46 2235.57C1986.09 2273.59 1842.69 2263.93 1714.64 2208.22C1586.59 2152.5 1481.77 2054.18 1418 1929.94L1686.92 1791.9C1718.81 1854.01 1771.21 1903.18 1835.24 1931.03C1899.27 1958.89 1970.96 1963.72 2038.15 1944.71L2120.46 2235.57Z"
      mask="url(#path-12-inside-11_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-13-inside-12_1864_21250">
      <path
        d="M2075.8 1899.41C2031 1911.43 1984.15 1913.73 1938.39 1906.17C1892.63 1898.61 1849.01 1881.35 1810.45 1855.57C1771.9 1829.78 1739.3 1796.05 1714.84 1756.65C1690.37 1717.24 1674.61 1673.06 1668.61 1627.07C1662.61 1581.07 1666.5 1534.33 1680.03 1489.96C1693.55 1445.6 1716.4 1404.63 1747.05 1369.81C1777.69 1334.99 1815.42 1307.12 1857.71 1288.07C1900 1269.01 1945.87 1259.21 1992.25 1259.32L1992.03 1352.99C1958.99 1352.91 1926.31 1359.89 1896.19 1373.46C1866.06 1387.04 1839.19 1406.89 1817.36 1431.69C1795.53 1456.49 1779.25 1485.68 1769.62 1517.28C1759.98 1548.88 1757.21 1582.18 1761.48 1614.94C1765.76 1647.71 1776.99 1679.18 1794.41 1707.25C1811.84 1735.32 1835.06 1759.34 1862.52 1777.71C1889.99 1796.08 1921.06 1808.37 1953.66 1813.76C1986.26 1819.14 2019.63 1817.5 2051.54 1808.95L2075.8 1899.41Z"
      />
    </mask>
    <path
      d="M2075.8 1899.41C2031 1911.43 1984.15 1913.73 1938.39 1906.17C1892.63 1898.61 1849.01 1881.35 1810.45 1855.57C1771.9 1829.78 1739.3 1796.05 1714.84 1756.65C1690.37 1717.24 1674.61 1673.06 1668.61 1627.07C1662.61 1581.07 1666.5 1534.33 1680.03 1489.96C1693.55 1445.6 1716.4 1404.63 1747.05 1369.81C1777.69 1334.99 1815.42 1307.12 1857.71 1288.07C1900 1269.01 1945.87 1259.21 1992.25 1259.32L1992.03 1352.99C1958.99 1352.91 1926.31 1359.89 1896.19 1373.46C1866.06 1387.04 1839.19 1406.89 1817.36 1431.69C1795.53 1456.49 1779.25 1485.68 1769.62 1517.28C1759.98 1548.88 1757.21 1582.18 1761.48 1614.94C1765.76 1647.71 1776.99 1679.18 1794.41 1707.25C1811.84 1735.32 1835.06 1759.34 1862.52 1777.71C1889.99 1796.08 1921.06 1808.37 1953.66 1813.76C1986.26 1819.14 2019.63 1817.5 2051.54 1808.95L2075.8 1899.41Z"
      mask="url(#path-13-inside-12_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-14-inside-13_1864_21250">
      <path
        d="M1490.31 781.299C1522.08 809.723 1558.68 832.226 1598.39 847.741L1632.85 759.541C1603.68 748.142 1576.79 731.609 1553.45 710.726L1490.31 781.299Z"
      />
    </mask>
    <path
      d="M1490.31 781.299C1522.08 809.723 1558.68 832.226 1598.39 847.741L1632.85 759.541C1603.68 748.142 1576.79 731.609 1553.45 710.726L1490.31 781.299Z"
      mask="url(#path-14-inside-13_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-15-inside-14_1864_21250">
      <path
        d="M1603.47 982.797C1666.51 1000.62 1733.23 1000.75 1796.33 983.175C1859.43 965.602 1916.48 931.003 1961.23 883.168L1830.9 761.248C1808.53 785.166 1780 802.466 1748.45 811.252C1716.9 820.039 1683.54 819.973 1652.02 811.063L1603.47 982.797Z"
      />
    </mask>
    <path
      d="M1603.47 982.797C1666.51 1000.62 1733.23 1000.75 1796.33 983.175C1859.43 965.602 1916.48 931.003 1961.23 883.168L1830.9 761.248C1808.53 785.166 1780 802.466 1748.45 811.252C1716.9 820.039 1683.54 819.973 1652.02 811.063L1603.47 982.797Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-15-inside-14_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-16-inside-15_1864_21250">
      <path
        d="M1950.37 815.524C1981.6 780.432 2005.6 739.52 2020.99 695.135L1852.37 636.68C1844.67 658.873 1832.68 679.329 1817.06 696.875L1950.37 815.524Z"
      />
    </mask>
    <path
      d="M1950.37 815.524C1981.6 780.432 2005.6 739.52 2020.99 695.135L1852.37 636.68C1844.67 658.873 1832.68 679.329 1817.06 696.875L1950.37 815.524Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-16-inside-15_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-17-inside-16_1864_21250">
      <path
        d="M1339.01 670.729C1321.6 605.834 1322.86 537.341 1342.64 473.129C1362.43 408.917 1399.93 351.594 1450.85 307.751L1567.3 442.988C1541.84 464.909 1523.09 493.571 1513.19 525.677C1503.3 557.783 1502.67 592.03 1511.38 624.477L1339.01 670.729Z"
      />
    </mask>
    <path
      d="M1339.01 670.729C1321.6 605.834 1322.86 537.341 1342.64 473.129C1362.43 408.917 1399.93 351.594 1450.85 307.751L1567.3 442.988C1541.84 464.909 1523.09 493.571 1513.19 525.677C1503.3 557.783 1502.67 592.03 1511.38 624.477L1339.01 670.729Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-17-inside-16_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M1352.25 896.691C1301.67 845.635 1264.69 782.715 1244.69 713.684L1448.62 655.625C1458.73 689.936 1477.13 721.233 1502.19 746.757L1352.25 896.691Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-19-inside-17_1864_21250">
      <path
        d="M1500.3 355.017C1515.58 342.323 1532.08 331.184 1549.57 321.765L1600.58 416.476C1589.39 422.504 1578.83 429.633 1569.05 437.757L1500.3 355.017Z"
      />
    </mask>
    <path
      d="M1500.3 355.017C1515.58 342.323 1532.08 331.184 1549.57 321.765L1600.58 416.476C1589.39 422.504 1578.83 429.633 1569.05 437.757L1500.3 355.017Z"
      mask="url(#path-19-inside-17_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-20-inside-18_1864_21250">
      <path
        d="M1552.7 320.103C1562.35 315.052 1572.27 310.532 1582.41 306.563L1621.6 406.747C1615.11 409.287 1608.76 412.179 1602.59 415.412L1552.7 320.103Z"
      />
    </mask>
    <path
      d="M1552.7 320.103C1562.35 315.052 1572.27 310.532 1582.41 306.563L1621.6 406.747C1615.11 409.287 1608.76 412.179 1602.59 415.412L1552.7 320.103Z"
      mask="url(#path-20-inside-18_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-21-inside-19_1864_21250">
      <path
        d="M1567.38 233.685C1579.98 228.966 1592.88 225.102 1605.99 222.121L1629.84 327.021C1621.45 328.929 1613.19 331.402 1605.13 334.423L1567.38 233.685Z"
      />
    </mask>
    <path
      d="M1567.38 233.685C1579.98 228.966 1592.88 225.102 1605.99 222.121L1629.84 327.021C1621.45 328.929 1613.19 331.402 1605.13 334.423L1567.38 233.685Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-21-inside-19_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="156.978"
      id="path-22-outside-20_1864_21250"
      maskUnits="userSpaceOnUse"
      width="156.978"
      x="1602.94"
      y="272.307"
    >
      <rect
        fill="white"
        height="156.978"
        width="156.978"
        x="1602.94"
        y="272.307"
      />
      <path
        d="M1635.82 324.335C1665.76 318.77 1696.45 318.563 1726.46 323.725L1715.21 389.153C1692.94 385.323 1670.17 385.476 1647.95 389.606L1635.82 324.335Z"
      />
    </mask>
    <path
      d="M1635.82 324.335C1665.76 318.77 1696.45 318.563 1726.46 323.725L1715.21 389.153C1692.94 385.323 1670.17 385.476 1647.95 389.606L1635.82 324.335Z"
      fill="rgb(var(--zero-color-background-svg))"
    />
    <path
      d="M1635.82 324.335C1665.76 318.77 1696.45 318.563 1726.46 323.725L1715.21 389.153C1692.94 385.323 1670.17 385.476 1647.95 389.606L1635.82 324.335Z"
      mask="url(#path-22-outside-20_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="148.492"
      id="path-23-outside-21_1864_21250"
      maskUnits="userSpaceOnUse"
      width="148.492"
      x="1684.97"
      y="291.399"
    >
      <rect
        fill="white"
        height="148.492"
        width="148.492"
        x="1684.97"
        y="291.399"
      />
      <path
        d="M1730.03 324.364C1760.23 329.997 1789.17 340.99 1815.49 356.824L1781.27 413.713C1761.74 401.964 1740.26 393.807 1717.86 389.627L1730.03 324.364Z"
      />
    </mask>
    <path
      d="M1730.03 324.364C1760.23 329.997 1789.17 340.99 1815.49 356.824L1781.27 413.713C1761.74 401.964 1740.26 393.807 1717.86 389.627L1730.03 324.364Z"
      mask="url(#path-23-outside-21_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-24-inside-22_1864_21250">
      <path
        d="M1563.31 698.665C1534.15 669.506 1516.6 630.719 1513.92 589.503C1511.23 548.287 1523.59 507.441 1548.71 474.547C1573.82 441.653 1609.99 418.944 1650.49 410.636C1690.99 402.327 1733.07 408.983 1768.93 429.368L1744.37 472.387C1718.9 457.911 1689.02 453.184 1660.26 459.084C1631.5 464.985 1605.81 481.111 1587.98 504.471C1570.14 527.831 1561.36 556.837 1563.27 586.107C1565.18 615.376 1577.64 642.921 1598.35 663.628L1563.31 698.665Z"
      />
    </mask>
    <path
      d="M1563.31 698.665C1534.15 669.506 1516.6 630.719 1513.92 589.503C1511.23 548.287 1523.59 507.441 1548.71 474.547C1573.82 441.653 1609.99 418.944 1650.49 410.636C1690.99 402.327 1733.07 408.983 1768.93 429.368L1744.37 472.387C1718.9 457.911 1689.02 453.184 1660.26 459.084C1631.5 464.985 1605.81 481.111 1587.98 504.471C1570.14 527.831 1561.36 556.837 1563.27 586.107C1565.18 615.376 1577.64 642.921 1598.35 663.628L1563.31 698.665Z"
      mask="url(#path-24-inside-22_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-25-inside-23_1864_21250">
      <path
        d="M1902.15 252.482C1974.12 292.704 2029.95 356.6 2060.15 433.317C2090.35 510.033 2093.06 594.839 2067.82 673.327L1897.92 618.692C1910.54 579.448 1909.19 537.045 1894.09 498.687C1878.99 460.328 1851.08 428.38 1815.09 408.27L1902.15 252.482Z"
      />
    </mask>
    <path
      d="M1902.15 252.482C1974.12 292.704 2029.95 356.6 2060.15 433.317C2090.35 510.033 2093.06 594.839 2067.82 673.327L1897.92 618.692C1910.54 579.448 1909.19 537.045 1894.09 498.687C1878.99 460.328 1851.08 428.38 1815.09 408.27L1902.15 252.482Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-25-inside-23_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-26-inside-24_1864_21250">
      <path
        d="M1780.46 411.46C1804.18 425.148 1824.7 443.745 1840.65 466.006C1856.59 488.267 1867.6 513.679 1872.93 540.539C1878.26 567.4 1877.79 595.089 1871.55 621.753C1865.31 648.416 1853.45 673.439 1836.76 695.146C1820.06 716.853 1798.92 734.743 1774.75 747.617C1750.58 760.491 1723.94 768.053 1696.61 769.797C1669.29 771.54 1641.9 767.424 1616.29 757.725C1590.68 748.026 1567.44 732.967 1548.12 713.558L1587.32 674.55C1601.08 688.377 1617.63 699.103 1635.88 706.012C1654.12 712.921 1673.63 715.853 1693.09 714.611C1712.56 713.37 1731.54 707.983 1748.76 698.812C1765.97 689.641 1781.03 676.898 1792.92 661.435C1804.81 645.972 1813.27 628.147 1817.71 609.154C1822.15 590.16 1822.49 570.436 1818.69 551.302C1814.9 532.168 1807.05 514.067 1795.69 498.209C1784.33 482.352 1769.72 469.104 1752.82 459.354L1780.46 411.46Z"
      />
    </mask>
    <path
      d="M1780.46 411.46C1804.18 425.148 1824.7 443.745 1840.65 466.006C1856.59 488.267 1867.6 513.679 1872.93 540.539C1878.26 567.4 1877.79 595.089 1871.55 621.753C1865.31 648.416 1853.45 673.439 1836.76 695.146C1820.06 716.853 1798.92 734.743 1774.75 747.617C1750.58 760.491 1723.94 768.053 1696.61 769.797C1669.29 771.54 1641.9 767.424 1616.29 757.725C1590.68 748.026 1567.44 732.967 1548.12 713.558L1587.32 674.55C1601.08 688.377 1617.63 699.103 1635.88 706.012C1654.12 712.921 1673.63 715.853 1693.09 714.611C1712.56 713.37 1731.54 707.983 1748.76 698.812C1765.97 689.641 1781.03 676.898 1792.92 661.435C1804.81 645.972 1813.27 628.147 1817.71 609.154C1822.15 590.16 1822.49 570.436 1818.69 551.302C1814.9 532.168 1807.05 514.067 1795.69 498.209C1784.33 482.352 1769.72 469.104 1752.82 459.354L1780.46 411.46Z"
      mask="url(#path-26-inside-24_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-27-inside-25_1864_21250">
      <path
        d="M1008.59 1282.23C1035.39 1289.26 1063.24 1291.43 1090.81 1288.65L1084.61 1227.4C1064.36 1229.45 1043.9 1227.85 1024.21 1222.69L1008.59 1282.23Z"
      />
    </mask>
    <path
      d="M1008.59 1282.23C1035.39 1289.26 1063.24 1291.43 1090.81 1288.65L1084.61 1227.4C1064.36 1229.45 1043.9 1227.85 1024.21 1222.69L1008.59 1282.23Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-27-inside-25_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-28-inside-26_1864_21250">
      <path
        d="M1133.76 1365.28C1175.51 1356.92 1214.15 1337.22 1245.46 1308.36C1276.76 1279.49 1299.52 1242.57 1311.24 1201.64L1199.71 1169.71C1193.85 1190.18 1182.47 1208.64 1166.82 1223.07C1151.17 1237.51 1131.85 1247.35 1110.97 1251.53L1133.76 1365.28Z"
      />
    </mask>
    <path
      d="M1133.76 1365.28C1175.51 1356.92 1214.15 1337.22 1245.46 1308.36C1276.76 1279.49 1299.52 1242.57 1311.24 1201.64L1199.71 1169.71C1193.85 1190.18 1182.47 1208.64 1166.82 1223.07C1151.17 1237.51 1131.85 1247.35 1110.97 1251.53L1133.76 1365.28Z"
      mask="url(#path-28-inside-26_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-29-inside-27_1864_21250">
      <path
        d="M1284.91 1165.72C1292.58 1136.16 1294.35 1105.38 1290.1 1075.14L1175.22 1091.28C1177.34 1106.4 1176.46 1121.79 1172.62 1136.57L1284.91 1165.72Z"
      />
    </mask>
    <path
      d="M1284.91 1165.72C1292.58 1136.16 1294.35 1105.38 1290.1 1075.14L1175.22 1091.28C1177.34 1106.4 1176.46 1121.79 1172.62 1136.57L1284.91 1165.72Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-29-inside-27_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-30-inside-28_1864_21250">
      <path
        d="M888.29 1263.08C858.987 1230.69 839.421 1190.69 831.843 1147.68C824.264 1104.66 828.981 1060.39 845.449 1019.93L952.894 1063.68C944.66 1083.9 942.301 1106.04 946.091 1127.55C949.88 1149.06 959.663 1169.06 974.314 1185.25L888.29 1263.08Z"
      />
    </mask>
    <path
      d="M888.29 1263.08C858.987 1230.69 839.421 1190.69 831.843 1147.68C824.264 1104.66 828.981 1060.39 845.449 1019.93L952.894 1063.68C944.66 1083.9 942.301 1106.04 946.091 1127.55C949.88 1149.06 959.663 1169.06 974.314 1185.25L888.29 1263.08Z"
      mask="url(#path-30-inside-28_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M962.45 1389.01C918.656 1374.53 879.137 1349.42 847.434 1315.91L947.204 1222.85C963.056 1239.37 982.657 1251.83 1004.35 1259.17L962.45 1389.01Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-32-inside-29_1864_21250">
      <path
        d="M888.065 1032.63C893.143 1020.76 899.391 1009.42 906.718 998.793L964.296 1038.48C959.607 1045.28 955.608 1052.53 952.359 1060.13L888.065 1032.63Z"
      />
    </mask>
    <path
      d="M888.065 1032.63C893.143 1020.76 899.391 1009.42 906.718 998.793L964.296 1038.48C959.607 1045.28 955.608 1052.53 952.359 1060.13L888.065 1032.63Z"
      mask="url(#path-32-inside-29_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-33-inside-30_1864_21250">
      <path
        d="M908.036 996.904C912.123 991.122 916.523 985.568 921.216 980.267L973.575 1026.62C970.572 1030.01 967.756 1033.57 965.14 1037.27L908.036 996.904Z"
      />
    </mask>
    <path
      d="M908.036 996.904C912.123 991.122 916.523 985.568 921.216 980.267L973.575 1026.62C970.572 1030.01 967.756 1033.57 965.14 1037.27L908.036 996.904Z"
      mask="url(#path-33-inside-30_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-34-inside-31_1864_21250">
      <path
        d="M890.93 942.553C896.819 936.091 903.138 930.033 909.842 924.422L954.721 978.05C950.43 981.641 946.386 985.518 942.617 989.653L890.93 942.553Z"
      />
    </mask>
    <path
      d="M890.93 942.553C896.819 936.091 903.138 930.033 909.842 924.422L954.721 978.05C950.43 981.641 946.386 985.518 942.617 989.653L890.93 942.553Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-34-inside-31_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="94.7143"
      id="path-35-outside-32_1864_21250"
      maskUnits="userSpaceOnUse"
      width="95.3589"
      x="943.899"
      y="929.401"
    >
      <rect
        fill="white"
        height="94.7143"
        width="95.3589"
        x="943.899"
        y="929.401"
      />
      <path
        d="M957.383 974.725C973.057 962.634 990.753 953.421 1009.65 947.515L1022.52 988.705C1008.5 993.087 995.372 999.923 983.742 1008.89L957.383 974.725Z"
      />
    </mask>
    <path
      d="M957.383 974.725C973.057 962.634 990.753 953.421 1009.65 947.515L1022.52 988.705C1008.5 993.087 995.372 999.923 983.742 1008.89L957.383 974.725Z"
      mask="url(#path-35-outside-32_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="88.083"
      id="path-36-outside-33_1864_21250"
      maskUnits="userSpaceOnUse"
      width="90.6614"
      x="995.599"
      y="918.168"
    >
      <rect
        fill="white"
        height="88.083"
        width="90.6614"
        x="995.599"
        y="918.168"
      />
      <path
        d="M1011.9 946.828C1031.04 941.14 1051.04 938.925 1070.96 940.287L1068.02 983.342C1053.24 982.331 1038.4 983.974 1024.19 988.195L1011.9 946.828Z"
      />
    </mask>
    <path
      d="M1011.9 946.828C1031.04 941.14 1051.04 938.925 1070.96 940.287L1068.02 983.342C1053.24 982.331 1038.4 983.974 1024.19 988.195L1011.9 946.828Z"
      fill="rgb(var(--zero-color-background-svg))"
    />
    <path
      d="M1011.9 946.828C1031.04 941.14 1051.04 938.925 1070.96 940.287L1068.02 983.342C1053.24 982.331 1038.4 983.974 1024.19 988.195L1011.9 946.828Z"
      mask="url(#path-36-outside-33_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-37-inside-34_1864_21250">
      <path
        d="M1026.34 1212.79C1000.83 1204.56 979.184 1187.31 965.418 1164.26C951.653 1141.21 946.704 1113.92 951.489 1087.44C956.275 1060.97 970.47 1037.12 991.441 1020.31C1012.41 1003.5 1038.73 994.885 1065.52 996.055L1064.05 1028.22C1045.03 1027.39 1026.34 1033.51 1011.45 1045.45C996.555 1057.38 986.474 1074.32 983.076 1093.12C979.677 1111.92 983.192 1131.31 992.968 1147.68C1002.74 1164.05 1018.12 1176.29 1036.23 1182.13L1026.34 1212.79Z"
      />
    </mask>
    <path
      d="M1026.34 1212.79C1000.83 1204.56 979.184 1187.31 965.418 1164.26C951.653 1141.21 946.704 1113.92 951.489 1087.44C956.275 1060.97 970.47 1037.12 991.441 1020.31C1012.41 1003.5 1038.73 994.885 1065.52 996.055L1064.05 1028.22C1045.03 1027.39 1026.34 1033.51 1011.45 1045.45C996.555 1057.38 986.474 1074.32 983.076 1093.12C979.677 1111.92 983.192 1131.31 992.968 1147.68C1002.74 1164.05 1018.12 1176.29 1036.23 1182.13L1026.34 1212.79Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-37-inside-34_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-38-inside-35_1864_21250">
      <path
        d="M1090.19 854.24C1143.75 856.188 1194.98 876.616 1235.18 912.055C1275.39 947.494 1302.08 995.758 1310.73 1048.65L1196.25 1067.38C1191.92 1040.93 1178.57 1016.8 1158.47 999.079C1138.37 981.359 1112.75 971.145 1085.98 970.171L1090.19 854.24Z"
      />
    </mask>
    <path
      d="M1090.19 854.24C1143.75 856.188 1194.98 876.616 1235.18 912.055C1275.39 947.494 1302.08 995.758 1310.73 1048.65L1196.25 1067.38C1191.92 1040.93 1178.57 1016.8 1158.47 999.079C1138.37 981.359 1112.75 971.145 1085.98 970.171L1090.19 854.24Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-38-inside-35_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-39-inside-36_1864_21250">
      <path
        d="M1066.89 982.277C1084.66 983.169 1102.05 987.849 1117.87 996.004C1133.69 1004.16 1147.59 1015.6 1158.63 1029.56C1169.67 1043.52 1177.6 1059.68 1181.9 1076.96C1186.19 1094.23 1186.74 1112.23 1183.51 1129.73C1180.28 1147.24 1173.35 1163.85 1163.18 1178.46C1153.01 1193.07 1139.84 1205.34 1124.54 1214.45C1109.25 1223.55 1092.18 1229.28 1074.49 1231.26C1056.8 1233.24 1038.89 1231.41 1021.97 1225.9L1033.09 1191.72C1045.14 1195.64 1057.9 1196.95 1070.5 1195.54C1083.1 1194.13 1095.26 1190.05 1106.16 1183.56C1117.05 1177.07 1126.43 1168.33 1133.68 1157.93C1140.92 1147.52 1145.86 1135.69 1148.16 1123.22C1150.46 1110.75 1150.07 1097.93 1147.01 1085.62C1143.95 1073.32 1138.3 1061.81 1130.44 1051.86C1122.57 1041.91 1112.67 1033.76 1101.4 1027.96C1090.13 1022.15 1077.75 1018.81 1065.08 1018.18L1066.89 982.277Z"
      />
    </mask>
    <path
      d="M1066.89 982.277C1084.66 983.169 1102.05 987.849 1117.87 996.004C1133.69 1004.16 1147.59 1015.6 1158.63 1029.56C1169.67 1043.52 1177.6 1059.68 1181.9 1076.96C1186.19 1094.23 1186.74 1112.23 1183.51 1129.73C1180.28 1147.24 1173.35 1163.85 1163.18 1178.46C1153.01 1193.07 1139.84 1205.34 1124.54 1214.45C1109.25 1223.55 1092.18 1229.28 1074.49 1231.26C1056.8 1233.24 1038.89 1231.41 1021.97 1225.9L1033.09 1191.72C1045.14 1195.64 1057.9 1196.95 1070.5 1195.54C1083.1 1194.13 1095.26 1190.05 1106.16 1183.56C1117.05 1177.07 1126.43 1168.33 1133.68 1157.93C1140.92 1147.52 1145.86 1135.69 1148.16 1123.22C1150.46 1110.75 1150.07 1097.93 1147.01 1085.62C1143.95 1073.32 1138.3 1061.81 1130.44 1051.86C1122.57 1041.91 1112.67 1033.76 1101.4 1027.96C1090.13 1022.15 1077.75 1018.81 1065.08 1018.18L1066.89 982.277Z"
      mask="url(#path-39-inside-36_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-40-inside-37_1864_21250">
      <path
        d="M665.953 2029.99C700.542 2060.94 740.396 2085.43 783.625 2102.33L821.146 2006.3C789.386 1993.89 760.106 1975.89 734.693 1953.16L665.953 2029.99Z"
      />
    </mask>
    <path
      d="M665.953 2029.99C700.542 2060.94 740.396 2085.43 783.625 2102.33L821.146 2006.3C789.386 1993.89 760.106 1975.89 734.693 1953.16L665.953 2029.99Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-40-inside-37_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-41-inside-38_1864_21250">
      <path
        d="M789.157 2249.36C857.781 2268.76 930.422 2268.91 999.122 2249.77C1067.82 2230.64 1129.93 2192.97 1178.65 2140.9L1036.76 2008.16C1012.4 2034.2 981.347 2053.03 946.997 2062.6C912.647 2072.17 876.327 2072.09 842.014 2062.39L789.157 2249.36Z"
      />
    </mask>
    <path
      d="M789.157 2249.36C857.781 2268.76 930.422 2268.91 999.122 2249.77C1067.82 2230.64 1129.93 2192.97 1178.65 2140.9L1036.76 2008.16C1012.4 2034.2 981.347 2053.03 946.997 2062.6C912.647 2072.17 876.327 2072.09 842.014 2062.39L789.157 2249.36Z"
      mask="url(#path-41-inside-38_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-42-inside-39_1864_21250">
      <path
        d="M1166.83 2067.25C1200.83 2029.04 1226.96 1984.5 1243.71 1936.18L1060.13 1872.54C1051.76 1896.7 1038.69 1918.97 1021.69 1938.07L1166.83 2067.25Z"
      />
    </mask>
    <path
      d="M1166.83 2067.25C1200.83 2029.04 1226.96 1984.5 1243.71 1936.18L1060.13 1872.54C1051.76 1896.7 1038.69 1918.97 1021.69 1938.07L1166.83 2067.25Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-42-inside-39_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-43-inside-40_1864_21250">
      <path
        d="M501.236 1909.61C482.278 1838.96 483.647 1764.39 505.186 1694.48C526.725 1624.57 567.559 1562.16 622.991 1514.43L749.772 1661.67C722.056 1685.53 701.639 1716.74 690.869 1751.69C680.1 1786.64 679.415 1823.93 688.894 1859.25L501.236 1909.61Z"
      />
    </mask>
    <path
      d="M501.236 1909.61C482.278 1838.96 483.647 1764.39 505.186 1694.48C526.725 1624.57 567.559 1562.16 622.991 1514.43L749.772 1661.67C722.056 1685.53 701.639 1716.74 690.869 1751.69C680.1 1786.64 679.415 1823.93 688.894 1859.25L501.236 1909.61Z"
      mask="url(#path-43-inside-40_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M515.649 2155.87C460.451 2100.2 420.109 2031.56 398.322 1956.25L620.69 1892.95C631.693 1930.4 651.769 1964.55 679.135 1992.38L515.649 2155.87Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-45-inside-41_1864_21250">
      <path
        d="M676.829 1565.89C693.461 1552.07 711.429 1539.94 730.467 1529.69L786.009 1632.8C773.825 1639.36 762.325 1647.13 751.681 1655.97L676.829 1565.89Z"
      />
    </mask>
    <path
      d="M676.829 1565.89C693.461 1552.07 711.429 1539.94 730.467 1529.69L786.009 1632.8C773.825 1639.36 762.325 1647.13 751.681 1655.97L676.829 1565.89Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-45-inside-41_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-46-inside-42_1864_21250">
      <path
        d="M733.875 1527.88C744.381 1522.38 755.18 1517.46 766.224 1513.14L808.893 1622.21C801.825 1624.97 794.914 1628.12 788.19 1631.64L733.875 1527.88Z"
      />
    </mask>
    <path
      d="M733.875 1527.88C744.381 1522.38 755.18 1517.46 766.224 1513.14L808.893 1622.21C801.825 1624.97 794.914 1628.12 788.19 1631.64L733.875 1527.88Z"
      mask="url(#path-46-inside-42_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-47-inside-43_1864_21250">
      <path
        d="M749.866 1433.8C763.578 1428.66 777.622 1424.45 791.901 1421.2L817.864 1535.41C808.725 1537.49 799.737 1540.18 790.961 1543.47L749.866 1433.8Z"
      />
    </mask>
    <path
      d="M749.866 1433.8C763.578 1428.66 777.622 1424.45 791.901 1421.2L817.864 1535.41C808.725 1537.49 799.737 1540.18 790.961 1543.47L749.866 1433.8Z"
      mask="url(#path-47-inside-43_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="169.706"
      id="path-48-outside-44_1864_21250"
      maskUnits="userSpaceOnUse"
      width="169.706"
      x="788.597"
      y="1476.5"
    >
      <rect
        fill="white"
        height="169.706"
        width="169.706"
        x="788.597"
        y="1476.5"
      />
      <path
        d="M824.37 1532.49C856.967 1526.43 890.38 1526.2 923.055 1531.82L910.804 1603.06C886.559 1598.89 861.767 1599.05 837.58 1603.55L824.37 1532.49Z"
      />
    </mask>
    <path
      d="M824.37 1532.49C856.967 1526.43 890.38 1526.2 923.055 1531.82L910.804 1603.06C886.559 1598.89 861.767 1599.05 837.58 1603.55L824.37 1532.49Z"
      mask="url(#path-48-outside-44_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="160.513"
      id="path-49-outside-45_1864_21250"
      maskUnits="userSpaceOnUse"
      width="160.513"
      x="878.4"
      y="1497"
    >
      <rect fill="white" height="160.513" width="160.513" x="878.4" y="1497" />
      <path
        d="M926.94 1532.52C959.815 1538.65 991.327 1550.62 1019.98 1567.86L982.725 1629.79C961.462 1617 938.08 1608.12 913.687 1603.57L926.94 1532.52Z"
      />
    </mask>
    <path
      d="M926.94 1532.52C959.815 1538.65 991.327 1550.62 1019.98 1567.86L982.725 1629.79C961.462 1617 938.08 1608.12 913.687 1603.57L926.94 1532.52Z"
      mask="url(#path-49-outside-45_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-50-inside-46_1864_21250">
      <path
        d="M745.432 1940.03C713.686 1908.28 694.577 1866.05 691.654 1821.18C688.73 1776.31 702.189 1731.84 729.533 1696.02C756.878 1660.21 796.25 1635.49 840.342 1626.44C884.435 1617.4 930.253 1624.64 969.293 1646.84L942.552 1693.67C914.828 1677.91 882.29 1672.77 850.978 1679.19C819.665 1685.61 791.705 1703.17 772.287 1728.6C752.868 1754.04 743.31 1785.62 745.387 1817.48C747.463 1849.35 761.033 1879.34 783.577 1901.88L745.432 1940.03Z"
      />
    </mask>
    <path
      d="M745.432 1940.03C713.686 1908.28 694.577 1866.05 691.654 1821.18C688.73 1776.31 702.189 1731.84 729.533 1696.02C756.878 1660.21 796.25 1635.49 840.342 1626.44C884.435 1617.4 930.253 1624.64 969.293 1646.84L942.552 1693.67C914.828 1677.91 882.29 1672.77 850.978 1679.19C819.665 1685.61 791.705 1703.17 772.287 1728.6C752.868 1754.04 743.31 1785.62 745.387 1817.48C747.463 1849.35 761.033 1879.34 783.577 1901.88L745.432 1940.03Z"
      mask="url(#path-50-inside-46_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-51-inside-47_1864_21250">
      <path
        d="M1114.34 1454.26C1192.69 1498.05 1253.47 1567.61 1286.35 1651.14C1319.23 1734.66 1322.18 1826.99 1294.7 1912.44L1109.73 1852.96C1123.47 1810.23 1121.99 1764.07 1105.55 1722.31C1089.11 1680.54 1058.73 1645.76 1019.55 1623.87L1114.34 1454.26Z"
      />
    </mask>
    <path
      d="M1114.34 1454.26C1192.69 1498.05 1253.47 1567.61 1286.35 1651.14C1319.23 1734.66 1322.18 1826.99 1294.7 1912.44L1109.73 1852.96C1123.47 1810.23 1121.99 1764.07 1105.55 1722.31C1089.11 1680.54 1058.73 1645.76 1019.55 1623.87L1114.34 1454.26Z"
      mask="url(#path-51-inside-47_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-52-inside-48_1864_21250">
      <path
        d="M981.847 1627.34C1007.67 1642.24 1030.01 1662.49 1047.37 1686.73C1064.73 1710.96 1076.72 1738.63 1082.52 1767.87C1088.32 1797.11 1087.81 1827.26 1081.02 1856.29C1074.23 1885.32 1061.31 1912.56 1043.13 1936.19C1024.96 1959.83 1001.94 1979.3 975.631 1993.32C949.318 2007.33 920.314 2015.57 890.561 2017.47C860.808 2019.36 830.993 2014.88 803.112 2004.32C775.231 1993.76 749.928 1977.37 728.898 1956.24L771.569 1913.77C786.55 1928.82 804.574 1940.5 824.435 1948.02C844.296 1955.54 865.534 1958.74 886.728 1957.38C907.922 1956.03 928.583 1950.17 947.327 1940.18C966.071 1930.2 982.465 1916.33 995.412 1899.49C1008.36 1882.66 1017.56 1863.25 1022.4 1842.57C1027.24 1821.89 1027.6 1800.42 1023.47 1779.59C1019.33 1758.76 1010.8 1739.05 998.43 1721.79C986.063 1704.52 970.149 1690.1 951.755 1679.48L981.847 1627.34Z"
      />
    </mask>
    <path
      d="M981.847 1627.34C1007.67 1642.24 1030.01 1662.49 1047.37 1686.73C1064.73 1710.96 1076.72 1738.63 1082.52 1767.87C1088.32 1797.11 1087.81 1827.26 1081.02 1856.29C1074.23 1885.32 1061.31 1912.56 1043.13 1936.19C1024.96 1959.83 1001.94 1979.3 975.631 1993.32C949.318 2007.33 920.314 2015.57 890.561 2017.47C860.808 2019.36 830.993 2014.88 803.112 2004.32C775.231 1993.76 749.928 1977.37 728.898 1956.24L771.569 1913.77C786.55 1928.82 804.574 1940.5 824.435 1948.02C844.296 1955.54 865.534 1958.74 886.728 1957.38C907.922 1956.03 928.583 1950.17 947.327 1940.18C966.071 1930.2 982.465 1916.33 995.412 1899.49C1008.36 1882.66 1017.56 1863.25 1022.4 1842.57C1027.24 1821.89 1027.6 1800.42 1023.47 1779.59C1019.33 1758.76 1010.8 1739.05 998.43 1721.79C986.063 1704.52 970.149 1690.1 951.755 1679.48L981.847 1627.34Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-52-inside-48_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-53-inside-49_1864_21250">
      <path
        d="M695.276 1244.82C696.836 1228.77 695.508 1212.58 691.357 1197L656.756 1206.23C659.806 1217.67 660.781 1229.57 659.635 1241.36L695.276 1244.82Z"
      />
    </mask>
    <path
      d="M695.276 1244.82C696.836 1228.77 695.508 1212.58 691.357 1197L656.756 1206.23C659.806 1217.67 660.781 1229.57 659.635 1241.36L695.276 1244.82Z"
      mask="url(#path-53-inside-49_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-54-inside-50_1864_21250">
      <path
        d="M731.398 1165.24C722.731 1142.04 707.846 1121.67 688.373 1106.36C668.899 1091.05 645.589 1081.39 620.994 1078.45L612.975 1145.46C625.272 1146.93 636.928 1151.76 646.664 1159.41C656.401 1167.07 663.844 1177.25 668.177 1188.86L731.398 1165.24Z"
      />
    </mask>
    <path
      d="M731.398 1165.24C722.731 1142.04 707.846 1121.67 688.373 1106.36C668.899 1091.05 645.589 1081.39 620.994 1078.45L612.975 1145.46C625.272 1146.93 636.928 1151.76 646.664 1159.41C656.401 1167.07 663.844 1177.25 668.177 1188.86L731.398 1165.24Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-54-inside-50_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-55-inside-51_1864_21250">
      <path
        d="M602.799 1096.89C585.113 1095.22 567.271 1097.06 550.296 1102.29L570.193 1166.78C578.68 1164.16 587.601 1163.24 596.444 1164.08L602.799 1096.89Z"
      />
    </mask>
    <path
      d="M602.799 1096.89C585.113 1095.22 567.271 1097.06 550.296 1102.29L570.193 1166.78C578.68 1164.16 587.601 1163.24 596.444 1164.08L602.799 1096.89Z"
      mask="url(#path-55-inside-51_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-56-inside-52_1864_21250">
      <path
        d="M695.407 1315.68C679.516 1335.51 658.352 1350.45 634.349 1358.78C610.345 1367.11 584.478 1368.5 559.721 1362.78L574.905 1297.02C587.283 1299.88 600.217 1299.19 612.219 1295.02C624.221 1290.86 634.803 1283.39 642.748 1273.47L695.407 1315.68Z"
      />
    </mask>
    <path
      d="M695.407 1315.68C679.516 1335.51 658.352 1350.45 634.349 1358.78C610.345 1367.11 584.478 1368.5 559.721 1362.78L574.905 1297.02C587.283 1299.88 600.217 1299.19 612.219 1295.02C624.221 1290.86 634.803 1283.39 642.748 1273.47L695.407 1315.68Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-56-inside-52_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M759.912 1262.14C755.625 1287.91 745.147 1312.25 729.377 1333.08L668.013 1285.41C675.679 1275.04 680.845 1263.04 683.107 1250.34L759.912 1262.14Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-58-inside-53_1864_21250">
      <path
        d="M563.071 1337.13C555.783 1335.31 548.695 1332.77 541.912 1329.55L559.377 1292.81C563.718 1294.87 568.255 1296.5 572.919 1297.66L563.071 1337.13Z"
      />
    </mask>
    <path
      d="M563.071 1337.13C555.783 1335.31 548.695 1332.77 541.912 1329.55L559.377 1292.81C563.718 1294.87 568.255 1296.5 572.919 1297.66L563.071 1337.13Z"
      mask="url(#path-58-inside-53_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-59-inside-54_1864_21250">
      <path
        d="M540.705 1328.96C537.006 1327.15 533.409 1325.14 529.93 1322.93L551.709 1288.57C553.935 1289.98 556.237 1291.27 558.604 1292.43L540.705 1328.96Z"
      />
    </mask>
    <path
      d="M540.705 1328.96C537.006 1327.15 533.409 1325.14 529.93 1322.93L551.709 1288.57C553.935 1289.98 556.237 1291.27 558.604 1292.43L540.705 1328.96Z"
      mask="url(#path-59-inside-54_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-60-inside-55_1864_21250">
      <path
        d="M511.071 1343.82C506.815 1341.03 502.751 1337.96 498.908 1334.63L525.557 1303.9C528.017 1306.03 530.617 1307.99 533.341 1309.77L511.071 1343.82Z"
      />
    </mask>
    <path
      d="M511.071 1343.82C506.815 1341.03 502.751 1337.96 498.908 1334.63L525.557 1303.9C528.017 1306.03 530.617 1307.99 533.341 1309.77L511.071 1343.82Z"
      mask="url(#path-60-inside-55_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="54.58"
      id="path-61-outside-56_1864_21250"
      maskUnits="userSpaceOnUse"
      width="53.7434"
      x="494.433"
      y="1255.3"
    >
      <rect
        fill="white"
        height="54.58"
        width="53.7434"
        x="494.433"
        y="1255.3"
      />
      <path
        d="M523.402 1302.67C515.007 1294.79 508.079 1285.48 502.939 1275.17L525.405 1263.97C529.219 1271.62 534.359 1278.52 540.588 1284.37L523.402 1302.67Z"
      />
    </mask>
    <path
      d="M523.402 1302.67C515.007 1294.79 508.079 1285.48 502.939 1275.17L525.405 1263.97C529.219 1271.62 534.359 1278.52 540.588 1284.37L523.402 1302.67Z"
      mask="url(#path-61-outside-56_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="53.136"
      id="path-62-outside-57_1864_21250"
      maskUnits="userSpaceOnUse"
      width="50.6264"
      x="483.403"
      y="1229.3"
    >
      <rect
        fill="white"
        height="53.136"
        width="50.6264"
        x="483.403"
        y="1229.3"
      />
      <path
        d="M502.336 1273.94C497.298 1263.48 494.176 1252.19 493.116 1240.62L518.116 1238.33C518.903 1246.92 521.22 1255.29 524.957 1263.06L502.336 1273.94Z"
      />
    </mask>
    <path
      d="M502.336 1273.94C497.298 1263.48 494.176 1252.19 493.116 1240.62L518.116 1238.33C518.903 1246.92 521.22 1255.29 524.957 1263.06L502.336 1273.94Z"
      mask="url(#path-62-outside-57_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-63-inside-58_1864_21250">
      <path
        d="M653.751 1241.05C651.384 1256.46 643.484 1270.49 631.518 1280.53C619.552 1290.56 604.333 1295.93 588.686 1295.63C573.038 1295.33 558.025 1289.39 546.432 1278.9C534.84 1268.41 527.455 1254.09 525.649 1238.59L544.259 1236.46C545.542 1247.46 550.786 1257.63 559.019 1265.08C567.251 1272.53 577.913 1276.75 589.025 1276.96C600.137 1277.18 610.945 1273.37 619.442 1266.24C627.94 1259.11 633.55 1249.15 635.231 1238.2L653.751 1241.05Z"
      />
    </mask>
    <path
      d="M653.751 1241.05C651.384 1256.46 643.484 1270.49 631.518 1280.53C619.552 1290.56 604.333 1295.93 588.686 1295.63C573.038 1295.33 558.025 1289.39 546.432 1278.9C534.84 1268.41 527.455 1254.09 525.649 1238.59L544.259 1236.46C545.542 1247.46 550.786 1257.63 559.019 1265.08C567.251 1272.53 577.913 1276.75 589.025 1276.96C600.137 1277.18 610.945 1273.37 619.442 1266.24C627.94 1259.11 633.55 1249.15 635.231 1238.2L653.751 1241.05Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-63-inside-58_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-64-inside-59_1864_21250">
      <path
        d="M441.916 1237.54C438.08 1206.6 445.074 1175.29 461.709 1148.92C478.344 1122.55 503.595 1102.75 533.171 1092.89L554.518 1156.91C539.729 1161.84 527.104 1171.74 518.787 1184.93C510.469 1198.11 506.972 1213.77 508.89 1229.24L441.916 1237.54Z"
      />
    </mask>
    <path
      d="M441.916 1237.54C438.08 1206.6 445.074 1175.29 461.709 1148.92C478.344 1122.55 503.595 1102.75 533.171 1092.89L554.518 1156.91C539.729 1161.84 527.104 1171.74 518.787 1184.93C510.469 1198.11 506.972 1213.77 508.89 1229.24L441.916 1237.54Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-64-inside-59_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-65-inside-60_1864_21250">
      <path
        d="M517.609 1239.08C516.477 1228.79 517.557 1218.37 520.777 1208.53C523.997 1198.69 529.283 1189.65 536.28 1182.02C543.277 1174.38 551.825 1168.33 561.35 1164.27C570.875 1160.21 581.159 1158.23 591.511 1158.46C601.864 1158.7 612.047 1161.14 621.379 1165.63C630.71 1170.12 638.975 1176.55 645.62 1184.49C652.265 1192.43 657.136 1201.7 659.907 1211.68C662.678 1221.66 663.286 1232.11 661.689 1242.35L641.028 1239.12C642.166 1231.83 641.733 1224.39 639.759 1217.28C637.785 1210.17 634.315 1203.57 629.582 1197.91C624.849 1192.25 618.961 1187.67 612.314 1184.47C605.666 1181.27 598.413 1179.53 591.038 1179.37C583.663 1179.2 576.338 1180.61 569.553 1183.5C562.768 1186.4 556.679 1190.71 551.694 1196.15C546.71 1201.58 542.945 1208.02 540.651 1215.03C538.357 1222.04 537.588 1229.46 538.395 1236.8L517.609 1239.08Z"
      />
    </mask>
    <path
      d="M517.609 1239.08C516.477 1228.79 517.557 1218.37 520.777 1208.53C523.997 1198.69 529.283 1189.65 536.28 1182.02C543.277 1174.38 551.825 1168.33 561.35 1164.27C570.875 1160.21 581.159 1158.23 591.511 1158.46C601.864 1158.7 612.047 1161.14 621.379 1165.63C630.71 1170.12 638.975 1176.55 645.62 1184.49C652.265 1192.43 657.136 1201.7 659.907 1211.68C662.678 1221.66 663.286 1232.11 661.689 1242.35L641.028 1239.12C642.166 1231.83 641.733 1224.39 639.759 1217.28C637.785 1210.17 634.315 1203.57 629.582 1197.91C624.849 1192.25 618.961 1187.67 612.314 1184.47C605.666 1181.27 598.413 1179.53 591.038 1179.37C583.663 1179.2 576.338 1180.61 569.553 1183.5C562.768 1186.4 556.679 1190.71 551.694 1196.15C546.71 1201.58 542.945 1208.02 540.651 1215.03C538.357 1222.04 537.588 1229.46 538.395 1236.8L517.609 1239.08Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-65-inside-60_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-66-inside-61_1864_21250">
      <path
        d="M2363.69 697.744C2371.6 683.696 2376.93 668.345 2379.42 652.418L2344.04 646.878C2342.21 658.579 2338.3 669.858 2332.49 680.179L2363.69 697.744Z"
      />
    </mask>
    <path
      d="M2363.69 697.744C2371.6 683.696 2376.93 668.345 2379.42 652.418L2344.04 646.878C2342.21 658.579 2338.3 669.858 2332.49 680.179L2363.69 697.744Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-66-inside-61_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-67-inside-62_1864_21250">
      <path
        d="M2428.88 639.538C2430.32 614.81 2424.94 590.161 2413.3 568.291C2401.67 546.421 2384.25 528.174 2362.94 515.547L2328.54 573.607C2339.19 579.92 2347.9 589.044 2353.72 599.979C2359.54 610.914 2362.23 623.238 2361.51 635.602L2428.88 639.538Z"
      />
    </mask>
    <path
      d="M2428.88 639.538C2430.32 614.81 2424.94 590.161 2413.3 568.291C2401.67 546.421 2384.25 528.174 2362.94 515.547L2328.54 573.607C2339.19 579.92 2347.9 589.044 2353.72 599.979C2359.54 610.914 2362.23 623.238 2361.51 635.602L2428.88 639.538Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-67-inside-62_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-68-inside-63_1864_21250">
      <path
        d="M2338.84 525.068C2323.34 516.394 2306.28 510.866 2288.64 508.801L2280.79 575.83C2289.61 576.862 2298.14 579.626 2305.89 583.963L2338.84 525.068Z"
      />
    </mask>
    <path
      d="M2338.84 525.068C2323.34 516.394 2306.28 510.866 2288.64 508.801L2280.79 575.83C2289.61 576.862 2298.14 579.626 2305.89 583.963L2338.84 525.068Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-68-inside-63_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-69-inside-64_1864_21250">
      <path
        d="M2335.19 762.621C2312.64 774.339 2287.25 779.456 2261.93 777.382C2236.6 775.308 2212.38 766.129 2192.04 750.899L2232.49 696.879C2242.66 704.494 2254.77 709.083 2267.43 710.12C2280.1 711.157 2292.79 708.599 2304.07 702.74L2335.19 762.621Z"
      />
    </mask>
    <path
      d="M2335.19 762.621C2312.64 774.339 2287.25 779.456 2261.93 777.382C2236.6 775.308 2212.38 766.129 2192.04 750.899L2232.49 696.879C2242.66 704.494 2254.77 709.083 2267.43 710.12C2280.1 711.157 2292.79 708.599 2304.07 702.74L2335.19 762.621Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-69-inside-64_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M2415.83 739.695C2401.49 761.538 2382.08 779.576 2359.24 792.259L2322.36 723.863C2333.56 717.473 2343.13 708.579 2350.33 697.878L2415.83 739.695Z"
      fill="rgb(var(--zero-color-background-svg))"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="4"
    />
    <mask fill="white" id="path-71-inside-65_1864_21250">
      <path
        d="M2205.47 728.788C2199.53 724.181 2194.08 718.994 2189.17 713.304L2219.99 686.749C2223.13 690.39 2226.62 693.71 2230.42 696.658L2205.47 728.788Z"
      />
    </mask>
    <path
      d="M2205.47 728.788C2199.53 724.181 2194.08 718.994 2189.17 713.304L2219.99 686.749C2223.13 690.39 2226.62 693.71 2230.42 696.658L2205.47 728.788Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-71-inside-65_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-72-inside-66_1864_21250">
      <path
        d="M2188.3 712.284C2185.65 709.132 2183.18 705.837 2180.88 702.415L2214.68 679.779C2216.15 681.97 2217.74 684.078 2219.43 686.096L2188.3 712.284Z"
      />
    </mask>
    <path
      d="M2188.3 712.284C2185.65 709.132 2183.18 705.837 2180.88 702.415L2214.68 679.779C2216.15 681.97 2217.74 684.078 2219.43 686.096L2188.3 712.284Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-72-inside-66_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-73-inside-67_1864_21250">
      <path
        d="M2155.2 713.901C2152.43 709.635 2149.95 705.186 2147.78 700.586L2184.57 683.233C2185.96 686.177 2187.55 689.024 2189.32 691.754L2155.2 713.901Z"
      />
    </mask>
    <path
      d="M2155.2 713.901C2152.43 709.635 2149.95 705.186 2147.78 700.586L2184.57 683.233C2185.96 686.177 2187.55 689.024 2189.32 691.754L2155.2 713.901Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-73-inside-67_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="65.7496"
      id="path-74-outside-68_1864_21250"
      maskUnits="userSpaceOnUse"
      width="65.4449"
      x="2156.56"
      y="629.153"
    >
      <rect
        fill="white"
        height="65.7496"
        width="65.4449"
        x="2156.56"
        y="629.153"
      />
      <path
        d="M2183.09 681.244C2178.6 670.642 2176.02 659.326 2175.48 647.822L2200.56 646.647C2200.96 655.182 2202.87 663.579 2206.21 671.446L2183.09 681.244Z"
      />
    </mask>
    <path
      d="M2183.09 681.244C2178.6 670.642 2176.02 659.326 2175.48 647.822L2200.56 646.647C2200.96 655.182 2202.87 663.579 2206.21 671.446L2183.09 681.244Z"
      fill="rgb(var(--zero-color-background-svg))"
    />
    <path
      d="M2183.09 681.244C2178.6 670.642 2176.02 659.326 2175.48 647.822L2200.56 646.647C2200.96 655.182 2202.87 663.579 2206.21 671.446L2183.09 681.244Z"
      mask="url(#path-74-outside-68_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask
      fill="black"
      height="63.2924"
      id="path-75-outside-69_1864_21250"
      maskUnits="userSpaceOnUse"
      width="62.3782"
      x="2157.37"
      y="600.853"
    >
      <rect
        fill="white"
        height="63.2924"
        width="62.3782"
        x="2157.37"
        y="600.853"
      />
      <path
        d="M2175.43 646.452C2175.05 634.843 2176.75 623.259 2180.45 612.249L2204.25 620.251C2201.5 628.421 2200.24 637.016 2200.52 645.63L2175.43 646.452Z"
      />
    </mask>
    <path
      d="M2175.43 646.452C2175.05 634.843 2176.75 623.259 2180.45 612.249L2204.25 620.251C2201.5 628.421 2200.24 637.016 2200.52 645.63L2175.43 646.452Z"
      fill="rgb(var(--zero-color-background-svg))"
    />
    <path
      d="M2175.43 646.452C2175.05 634.843 2176.75 623.259 2180.45 612.249L2204.25 620.251C2201.5 628.421 2200.24 637.016 2200.52 645.63L2175.43 646.452Z"
      mask="url(#path-75-outside-69_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-linejoin="round"
      stroke-width="8"
    />
    <mask fill="white" id="path-76-inside-70_1864_21250">
      <path
        d="M2327.23 677.519C2318.84 690.663 2305.95 700.304 2290.94 704.654C2275.94 709.004 2259.85 707.767 2245.66 701.172C2231.47 694.578 2220.13 683.074 2213.77 668.796C2207.4 654.517 2206.43 638.434 2211.03 623.532L2228.92 629.097C2225.65 639.68 2226.34 651.101 2230.86 661.241C2235.38 671.381 2243.43 679.55 2253.51 684.233C2263.59 688.916 2275.02 689.795 2285.67 686.706C2296.32 683.616 2305.48 676.77 2311.44 667.436L2327.23 677.519Z"
      />
    </mask>
    <path
      d="M2327.23 677.519C2318.84 690.663 2305.95 700.304 2290.94 704.654C2275.94 709.004 2259.85 707.767 2245.66 701.172C2231.47 694.578 2220.13 683.074 2213.77 668.796C2207.4 654.517 2206.43 638.434 2211.03 623.532L2228.92 629.097C2225.65 639.68 2226.34 651.101 2230.86 661.241C2235.38 671.381 2243.43 679.55 2253.51 684.233C2263.59 688.916 2275.02 689.795 2285.67 686.706C2296.32 683.616 2305.48 676.77 2311.44 667.436L2327.23 677.519Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-76-inside-70_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-77-inside-71_1864_21250">
      <path
        d="M2134.86 588.749C2143.85 558.895 2162.89 533.074 2188.76 515.672C2214.63 498.269 2245.73 490.359 2276.77 493.284L2270.43 560.473C2254.91 559.01 2239.37 562.965 2226.43 571.667C2213.5 580.368 2203.98 593.279 2199.48 608.205L2134.86 588.749Z"
      />
    </mask>
    <path
      d="M2134.86 588.749C2143.85 558.895 2162.89 533.074 2188.76 515.672C2214.63 498.269 2245.73 490.359 2276.77 493.284L2270.43 560.473C2254.91 559.01 2239.37 562.965 2226.43 571.667C2213.5 580.368 2203.98 593.279 2199.48 608.205L2134.86 588.749Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-77-inside-71_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <mask fill="white" id="path-78-inside-72_1864_21250">
      <path
        d="M2203.48 620.733C2206.6 610.859 2211.8 601.767 2218.72 594.064C2225.64 586.361 2234.13 580.226 2243.61 576.069C2253.1 571.913 2263.36 569.83 2273.71 569.962C2284.07 570.093 2294.28 572.435 2303.65 576.831C2313.03 581.227 2321.36 587.576 2328.08 595.452C2334.8 603.327 2339.77 612.549 2342.64 622.498C2345.51 632.448 2346.22 642.896 2344.72 653.143C2343.23 663.39 2339.56 673.199 2333.97 681.914L2316.37 670.619C2320.35 664.411 2322.97 657.423 2324.03 650.124C2325.1 642.825 2324.59 635.382 2322.54 628.295C2320.5 621.207 2316.96 614.638 2312.17 609.028C2307.39 603.418 2301.45 598.896 2294.77 595.764C2288.1 592.633 2280.82 590.964 2273.45 590.871C2266.07 590.777 2258.76 592.26 2252.01 595.221C2245.25 598.182 2239.2 602.553 2234.27 608.04C2229.34 613.527 2225.64 620.004 2223.42 627.037L2203.48 620.733Z"
      />
    </mask>
    <path
      d="M2203.48 620.733C2206.6 610.859 2211.8 601.767 2218.72 594.064C2225.64 586.361 2234.13 580.226 2243.61 576.069C2253.1 571.913 2263.36 569.83 2273.71 569.962C2284.07 570.093 2294.28 572.435 2303.65 576.831C2313.03 581.227 2321.36 587.576 2328.08 595.452C2334.8 603.327 2339.77 612.549 2342.64 622.498C2345.51 632.448 2346.22 642.896 2344.72 653.143C2343.23 663.39 2339.56 673.199 2333.97 681.914L2316.37 670.619C2320.35 664.411 2322.97 657.423 2324.03 650.124C2325.1 642.825 2324.59 635.382 2322.54 628.295C2320.5 621.207 2316.96 614.638 2312.17 609.028C2307.39 603.418 2301.45 598.896 2294.77 595.764C2288.1 592.633 2280.82 590.964 2273.45 590.871C2266.07 590.777 2258.76 592.26 2252.01 595.221C2245.25 598.182 2239.2 602.553 2234.27 608.04C2229.34 613.527 2225.64 620.004 2223.42 627.037L2203.48 620.733Z"
      fill="rgb(var(--zero-color-background-svg))"
      mask="url(#path-78-inside-72_1864_21250)"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="8"
    />
    <path
      d="M1213 1320C1272.01 1449.12 1373.94 1820.37 1309.6 2272.4C1229.19 2837.44 1535.01 2887.5 1548.82 3158.74C1559.86 3375.73 1539.56 3419.03 1528.03 3413.56"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="10"
    />
    <path
      d="M2401 681.566C2474.06 686.876 2626.81 738.217 2653.31 901.105C2686.43 1104.71 2954.23 956.062 3014.48 1025.89C3062.68 1081.75 3053.26 1104.88 3042.52 1109.47"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="10"
    />
    <path
      d="M478 1312C395.668 1387.91 253.445 1606.15 343.21 1871.89C455.416 2204.06 28.6996 2233.49 9.44391 2392.94C-5.96068 2520.5 22.3603 2545.96 38.4464 2542.74"
      stroke="rgb(var(--zero-color-background-svg))"
      stroke-width="10"
    />
  </svg>
</div>
