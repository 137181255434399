export const DETAILED_PARAMETER_NAME = {
  A1_A3: 'Embodied carbon for stages A1-A3 product',
  A4: 'Embodied carbon for stage A4 transport',
  A5: 'Embodied carbon for stage A5 construction and installation',
  A1_A5: 'Total embodied carbon for stage A',
  B1: 'Embodied carbon for stage B1 use',
  B2: 'Embodied carbon for stage B2 maintenance',
  B3: 'Embodied carbon for stage B3 repair',
  B4: 'Embodied carbon for stage B4 replacement',
  B5: 'Embodied carbon for stage B5 refurbishment',
  B1_B5: 'Total embodied carbon for stage B',
  B6: 'Notional operational energy carbon for stage B6',
  B7: 'Notional operational water carbon for stage B7',
  B6_B7: 'Total operational carbon for stage B',
  C1: 'Embodied carbon for stage C1 deconstruction and demolition',
  C2: 'Embodied carbon for stage C2 material transport',
  C3: 'Embodied carbon for stage C3 water processing',
  C4: 'Embodied carbon for stage C4 disposal',
  C1_C4: 'Total embodied carbon for stage C',
  B: 'Biogenic',
  D: 'Embodied carbon for stage D beyond building',
} as const;

/**
 * Detailed Parameter Names.
 * Array of all detailed parameter names.
 */
export const DETAILED_PARAMETER_NAMES: DetailedParameterName[] = Object.values(
  DETAILED_PARAMETER_NAME,
);

export type DetailedParameterName =
  typeof DETAILED_PARAMETER_NAME[keyof typeof DETAILED_PARAMETER_NAME];
