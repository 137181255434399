import { HumanReadableOption } from '@core/types/human-readable-option';

/**
 * Asset Status.
 * Defines the status of an asset.
 */
export const ASSET_STATUS: Record<string, HumanReadableOption<string>> = {
  /* The asset is in the brief phase. */
  Brief: {
    display_name: 'Brief',
    value: 'Brief',
  },
  /* The asset is in the concept phase. */
  Concept: {
    display_name: 'Concept',
    value: 'Concept',
  },
  /* The asset is in the design phase. */
  Detail: {
    display_name: 'Detail',
    value: 'Detail',
  },
  /* The asset is in the construction phase. */
  Construction: {
    display_name: 'Construction',
    value: 'Construction',
  },
  /* The asset is in the operation phase. */
  InUse: {
    display_name: 'In Use',
    value: 'InUse',
  },
} as const;

/**
 * Asset Statuses.
 * All the possible asset statuses.
 */
export const ASSET_STATUSES = Object.values(ASSET_STATUS);

/**
 * Asset Status.
 * The status of an asset.
 */
export type AssetStatus =
  typeof ASSET_STATUS[keyof typeof ASSET_STATUS]['value'];
