import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@core/tokens/window.token';

/**
 * Di token for the local storage object.
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('Local Storage', {
  factory: () => {
    const { localStorage } = inject(WINDOW);
    return localStorage;
  },
});
