import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

/**
 * Veracity Endpoint.
 * Injection token for veracity endpoint.
 */
export const VERACITY_ENDPOINT = new InjectionToken<string>(
  'VERACITY_ENDPOINT',
  {
    factory: () => `${inject(ConfigService).config.veracityURL}`,
  },
);
