import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Warning Component.
 * Responsible for rendering a warning message alongside
 * a report problem material icon. Provide message with the
 * use of content projection.
 */
@Component({
  selector: 'zero-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningComponent {}
