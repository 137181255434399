import { Pipe, PipeTransform } from '@angular/core';
import { ASSET_STATUS, AssetStatus } from '@asset/enums/asset-status';

/**
 * Status Pipe.
 * Responsible for transforming the status enum to a display name.
 */
@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  /**
   * Transform.
   * Transforms the status enum to a display name.
   */
  transform(value: keyof typeof ASSET_STATUS) {
    return ASSET_STATUS[value].display_name;
  }
}
