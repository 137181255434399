import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  LOADING_MODAL_CONFIG,
  LOADING_MODAL_ID,
  LoadingModalComponent,
  LoadingModalData,
  LoadingModalOperation,
} from '@core/components/loading-modal/loading-modal.component';
import {
  catchError,
  iif,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

/**
 * Loading Modal Service.
 * Handles the opening and closing of the loading modal. This is not an ideal
 * solution, but it works for now.
 */
@Injectable({ providedIn: 'root' })
export class LoadingModalService {
  /**
   * Operations.
   * The operations map is used to keep track of the loading modal operations.
   */
  private readonly operations = new Map<
    LoadingModalOperation,
    LoadingModalData
  >();

  constructor(private readonly dialog: MatDialog) {}

  /**
   * Close.
   * Returns an operator function that handles closing the loading modal.
   * If there are more operations in the queue, the next operation will
   * be displayed. If there are no more operations in the queue, the loading
   * modal will be closed.
   */
  private _close<T>(ref: LoadingModalOperation): OperatorFunction<T, T> {
    return (source$: Observable<any>) =>
      source$.pipe(
        switchMap((source) => {
          const loadingModal = this.dialog.getDialogById(LOADING_MODAL_ID);
          this.operations.delete(ref);
          return iif(
            () => loadingModal !== undefined,
            of(source).pipe(
              switchMap(() =>
                iif(
                  () => this.operations.size > 0,
                  of(source).pipe(
                    tap(() => {
                      const next = this.operations.values().next().value;
                      loadingModal!.componentInstance.data = next;
                      loadingModal!.componentInstance.ref.markForCheck();
                    }),
                  ),
                  of(source).pipe(
                    switchMap(() => {
                      loadingModal!.close();
                      return loadingModal!
                        .afterClosed()
                        .pipe(map(() => source));
                    }),
                  ),
                ),
              ),
            ),
            of(source),
          );
        }),
      );
  }

  /**
   * Open.
   * Handles opening the loading modal. If the modal is already open, and the
   * override flag is set to true, the modal will be updated with the new data.
   */
  open(data: LoadingModalData, override = false) {
    const loadingModal = this.dialog.getDialogById(LOADING_MODAL_ID) as
      | MatDialogRef<LoadingModalComponent>
      | undefined;
    this.operations.set(data.operation, data);
    if (loadingModal) {
      if (override) {
        loadingModal.componentInstance.data = data;
        loadingModal.componentInstance.ref.markForCheck();
      }
      return loadingModal;
    } else {
      return this.dialog.open(LoadingModalComponent, {
        ...LOADING_MODAL_CONFIG,
        data: data,
      });
    }
  }

  /**
   * Close.
   * Returns an operator function that handles closing the loading modal.
   * If there are more operations in the queue, the next operation will
   * be displayed. If there are no more operations in the queue, the loading
   * modal will be closed.
   */
  close<T>(ref: LoadingModalOperation): OperatorFunction<T, T> {
    return (source$: Observable<any>) => source$.pipe(this._close(ref));
  }

  /**
   * Close on Error.
   * Returns an operator function that handles closing the loading modal
   * on error. If there are more operations in the queue, the next operation
   * will be displayed. If there are no more operations in the queue, the
   * loading modal will be closed.
   */
  closeOnError<T>(ref: LoadingModalOperation): OperatorFunction<T, T> {
    return (source$: Observable<any>) =>
      source$.pipe(
        catchError((error) =>
          of(error).pipe(
            this._close(ref),
            switchMap(() => throwError(() => error)),
          ),
        ),
      );
  }
}
