import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Form Help Component.
 * Responsible for displaying help text for a form section.
 * Content is provided with the use of content projection.
 */
@Component({
  selector: 'zero-form-help',
  templateUrl: './form-help.component.html',
  styleUrls: ['./form-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHelpComponent {}
