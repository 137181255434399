import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AssetImportableModel, AssetModel } from '@asset/models/asset.model';

/**
 * Page Header Component.
 * Responsible for rending header for a page. The headers title content is
 * provided with the user of content projection.
 *
 * @example ```html
 * <zero-page-header>Foo</zero-page-header>
 * ```
 */
@Component({
  selector: 'zero-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  /**
   * Asset.
   * The asset to render the asset and project number for above
   * the page title.
   */
  @Input() asset?: AssetModel | AssetImportableModel;

  /**
   * Navigate Back.
   * Emits an event when the navigate back button is clicked.
   */
  @Output() navigateBack: EventEmitter<void> = new EventEmitter<void>();
}
