<div class="mat-form-field">
  <mgt-people-picker
    #peoplePicker
    [showMax]="10"
    [placeholder]="placeholder"
    [selectionMode]="selectionMode"
    (selectionChanged)="handleSelectionChanged($event)"
    type="'any'"
  >
  </mgt-people-picker>
</div>
