import { filter, map, OperatorFunction } from 'rxjs';
import { HttpDataState } from '@core/types/http-data-state';

/**
 * Filter Success.
 * Filters out all data states that are not in a success state.
 */
export function filterSuccess<T>(): OperatorFunction<
  HttpDataState<T>,
  HttpDataState<T> & Record<'data', T>
> {
  return (source$) =>
    source$.pipe(
      filter((dataState) => dataState.status === 'success'),
      map((dataState) => dataState as HttpDataState<T> & Record<'data', T>),
    );
}
