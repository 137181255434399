import { Component, Input } from '@angular/core';

/**
 * Read More Component.
 *
 * Render content (of type `string`) to the DOM and limit its character length.
 * A 'Read More' 'Read Less' clickable toggle is provided to control toggling the
 * content charter length limit.
 */
@Component({
  selector: 'zero-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent {
  /**
   * Content to render.
   */
  @Input() content!: string;

  /**
   * Content char limit.
   */
  @Input() limit: number = 100;

  /**
   * Read More Toggle. Should `false` if content should be limited
   * and `true` if content should not be limited.
   */
  readMore: boolean = false;
}
