import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DetailedModel } from '@detailed-assessment/models/detailed.model';
import { HttpService } from '@core/services/http.service';
import { UtilsService } from '@core/services/utils.service';
import { DetailedUploadModel } from '@detailed-assessment/models/detailed-upload.model';
import { DETAILED_ENDPOINT } from '@detailed-assessment/tokens/detailed-endpoint.token';
import { ASSESSMENT_TYPE } from '@core/types/assessment-type';

/**
 * Detailed Service.
 * Responsible for detailed assessment related operations.
 */
@Injectable({ providedIn: 'root' })
export class DetailedService {
  constructor(
    private readonly httpService: HttpService,
    private readonly utilsService: UtilsService,
    @Inject(DETAILED_ENDPOINT) private readonly detailedEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Detailed.
   * Makes HTTP call to API to get detailed assessment for given asset id.
   */
  getDetailed(assetId: number) {
    return this.http
      .get<DetailedModel>(`${this.detailedEndpoint}/${assetId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssessmentError(ASSESSMENT_TYPE.Detailed),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Upload Detailed.
   * Makes HTTP call to API to upload detailed assessment.
   */
  uploadDetailed(detailed: DetailedUploadModel) {
    return this.http
      .put<DetailedModel>(
        `${this.detailedEndpoint}`,
        this.utilsService.convertObjectPropsToApiDateFormat(detailed, [
          'assessment_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }
}
