import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

/**
 * Acknowledgement Modal Config.
 * Defines the common configuration for the acknowledgement modal.
 */
export const ACKNOWLEDGEMENT_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
};

/**
 * Acknowledgement Modal Data.
 * Defines the data that is required to open the acknowledgement modal.
 */
export interface AcknowledgementModalData {
  /* Title of the acknowledgement modal. */
  title: string;
  /* Message to display to the user. */
  message: string;
  /* Action text for the confirmation button. */
  action:
    | string
    | {
        /* Action text for the confirmation button. */
        confirm: string;
        /* Action text for the rejection button. */
        reject: string;
      };
  /* Whether the modal is skippable. */
  skippable: boolean;
}

/**
 * Acknowledgement Modal Result.
 * Defines the result that is returned by the acknowledgement modal.
 */
export type AcknowledgementModalResult =
  | boolean
  | { confirm: boolean; skip?: boolean };

/**
 * Acknowledgement Modal Result Is Boolean.
 * Returns a boolean representing whether the provided
 * acknowledgement modal result is a boolean.
 */
export function acknowledgementModalResultIsBoolean(
  result: AcknowledgementModalResult,
): result is boolean {
  return typeof result === 'boolean';
}

/**
 * Acknowledgement Modal Result Is Object.
 * Returns a boolean representing whether the provided
 * acknowledgement modal result is an object.
 */
export function acknowledgementModalResultIsObject(
  result: AcknowledgementModalResult,
): result is { confirm: boolean; skip?: boolean } {
  return typeof result === 'object';
}

/**
 * Acknowledgement Modal Component.
 * Responsible for displaying an acknowledgement modal to the user. The component
 * is opened by the material dialog service. The component data is provided by
 * injecting the MAT_DIALOG_DATA token. The component result is returned by
 * closing the dialog with the result. The result can be a boolean or an object
 * with a confirmation property and optionally a skip property.
 *
 * @example ```
 * this.dialog.open(AcknowledgementModalComponent, {
 *   ...ACKNOWLEDGEMENT_MODAL_CONFIG,
 *   data: <AcknowledgementModalData>{
 *     ...,
 *     skippable: false,
 *   },
 * }).afterClosed().subscribe((result: AcknowledgementModalResult) => {
 *   if (acknowledgementModalResultIsBoolean(result)) {
 *     ...
 *   }
 * });
 *
 * this.dialog.open(AcknowledgementModalComponent, {
 *   ...ACKNOWLEDGEMENT_MODAL_CONFIG,
 *   data: <AcknowledgementModalData>{
 *     ...,
 *     skippable: true,
 *   }
 * }).afterClosed().subscribe((result: AcknowledgementModalResult) => {
 *   if (acknowledgementModalResultIsObject(result)) {
 *     ...
 *   }
 * });
 *  ```
 */
@Component({
  selector: 'zero-acknowledgement-modal',
  templateUrl: './acknowledgement-modal.component.html',
  styleUrls: ['./acknowledgement-modal.component.scss'],
})
export class AcknowledgementModalComponent {
  /**
   * Checkbox form control.
   */
  checkboxControl = new FormControl<boolean>(false);

  constructor(
    private readonly dialogRef: MatDialogRef<AcknowledgementModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: AcknowledgementModalData,
  ) {}

  /**
   * Action Type.
   * Returns the type of action that is being provided
   * to the modal. If the action is a string then the
   * modal will render a single acknowledgement button
   * with the provided string as the text. If the
   * action is an object then the modal will render
   * two buttons, one for confirming and one for
   * cancelling.
   */
  actionType(): 'string' | 'object' {
    return typeof this.data.action === 'string' ? 'string' : 'object';
  }

  /**
   * Handle Acknowledgement.
   * Handles the acknowledgement of the modal. If the
   * modal is skippable then the result will be an
   * object with a confirmation property and a skip
   * property. If the modal is not skippable then the
   * result will be a boolean.
   */
  handleAcknowledgement(action: 'confirm' | 'reject') {
    switch (this.data.skippable) {
      case true:
        this.dialogRef.close({
          confirm: action === 'confirm',
          skip: this.checkboxControl.value,
        });
        break;
      case false:
        this.dialogRef.close(action === 'confirm');
        break;
    }
  }
}
