import { Injectable } from '@angular/core';
import { from, map, OperatorFunction, switchMap } from 'rxjs';
import { EnergyModelExcelModel } from '@energy-model-assessment/models/energy-model/energy-model.model';
import { DetailedExcelModel } from '@detailed-assessment/models/detailed.model';
import { SystemName } from '@asset/enums/system-name';
import { SubsystemName } from '@core/models/subsystem';
import {
  DETAILED_PARAMETER_NAMES,
  DetailedParameterName,
} from '@detailed-assessment/types/detailed-paramter-name';
import { CarbonCalculationService } from '@core/services/carbon-calculation.service';
import * as XLSX from 'xlsx';
import { CampaignModel } from '@campaign/models/campaign.model';
import { ProgressDashboardPageState } from '@project/containers/progress-dashboard-page/progress-dashboard-page.component';
import { UtilsService } from '@core/services/utils.service';

/**
 * Excel Service.
 * Responsible for parsing excel files.
 */
@Injectable()
export class ExcelService {
  constructor(
    private readonly carbonCalculationService: CarbonCalculationService,
    private readonly utilsService: UtilsService,
  ) {}

  /**
   * Cell Exists.
   * Checks if a cell exists in a worksheet.
   */
  private _cellExists(worksheet: XLSX.WorkSheet, cell: string) {
    return (
      !!worksheet[cell] &&
      worksheet[cell].v !== undefined &&
      worksheet[cell].v !== null &&
      worksheet[cell].v !== ''
    );
  }

  /**
   * Cell Is Number.
   * Checks if a cell is a number.
   */
  private _cellIsNumber(worksheet: XLSX.WorkSheet, cell: string) {
    return worksheet[cell].t === 'n';
  }

  /**
   * Cell Is String.
   * Checks if a cell is a string.
   */
  private _cellIsString(worksheet: XLSX.WorkSheet, cell: string) {
    return worksheet[cell].t === 's';
  }

  private _readCellValue(
    worksheet: XLSX.WorkSheet,
    cell: string,
    type: 'number',
  ): number;
  private _readCellValue<T extends string>(
    worksheet: XLSX.WorkSheet,
    cell: string,
    type: 'string',
    acceptableValues?: string[],
  ): T;
  private _readCellValue(
    worksheet: XLSX.WorkSheet,
    cell: string,
    type: 'string' | 'number',
    acceptableValues?: string[],
  ): string | number {
    if (!this._cellExists(worksheet, cell)) {
      throw new Error(
        `Cell ${cell} does not exist in the assessment worksheet.`,
      );
    }
    switch (type) {
      case 'number':
        if (!this._cellIsNumber(worksheet, cell))
          throw new Error(`Cell ${cell} should be a number.`);
        try {
          const num = Number(worksheet[cell].v);
          if (isNaN(num)) {
            throw new Error(`Cell ${cell} should be a number.`);
          }
          return num;
        } catch (error) {
          throw new Error(`Cell ${cell} should be a number.`);
        }
      case 'string':
        if (!this._cellIsString(worksheet, cell))
          throw new Error(`Cell ${cell} should be a string.`);
        if (acceptableValues && !acceptableValues.includes(worksheet[cell].v)) {
          throw new Error(
            `Cell ${cell} value is not one of the acceptable values ${[
              acceptableValues.join(', '),
            ]}.`,
          );
        }
        try {
          return new String(worksheet[cell].v).valueOf();
        } catch (error) {
          throw new Error(`Cell ${cell} should be a string.`);
        }
    }
  }

  readFile(): OperatorFunction<File, XLSX.WorkBook> {
    return (file$) =>
      file$.pipe(
        switchMap((file) => {
          return from(
            new Promise<XLSX.WorkBook>((resolve, reject) => {
              try {
                const reader = new FileReader();
                reader.onload = (e) => {
                  try {
                    const data = e.target?.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    resolve(workbook);
                  } catch (error) {
                    reject(error);
                  }
                };
                reader.readAsBinaryString(file);
              } catch (error) {
                reject(error);
              }
            }),
          );
        }),
      );
  }

  /**
   * Get Worksheet.
   * Gets a worksheet from a workbook. Throws an error if the worksheet does not exist.
   */
  getWorksheet(
    worksheetName: string,
  ): OperatorFunction<XLSX.WorkBook, XLSX.WorkSheet> {
    return (workbook$) =>
      workbook$.pipe(
        map((workbook) => {
          if (!workbook.Sheets[worksheetName]) {
            throw new Error(
              `Worksheet ${worksheetName} not found in the workbook.`,
            );
          }
          return workbook.Sheets[worksheetName];
        }),
      );
  }

  /**
   * Parse Energy Model.
   * Parses an energy model from a worksheet.
   */
  parseEnergyModel(): OperatorFunction<XLSX.WorkSheet, EnergyModelExcelModel> {
    return (workbook$) =>
      workbook$.pipe(
        map((worksheet) => ({
          distribution_electrical: this._readCellValue(
            worksheet,
            'E40',
            'number',
          ),
          generator_fuel_for_normal_operation_electrical: this._readCellValue(
            worksheet,
            'E30',
            'number',
          ),
          heat_rejection_water: this._readCellValue(worksheet, 'E18', 'number'),
          heat_rejection_electrical: this._readCellValue(
            worksheet,
            'E17',
            'number',
          ),
          cooling_process_load: this._readCellValue(worksheet, 'E16', 'number'),
          cooling_other: this._readCellValue(worksheet, 'E15', 'number'),
          cooling_electrical: this._readCellValue(worksheet, 'E14', 'number'),
          pump_energy_to_heat_rejection_electrical:
            this._readCellValue(worksheet, 'E10', 'number') *
            (this._readCellValue(worksheet, 'E13', 'number') / 100),
          pump_energy_to_cooling_electrical:
            this._readCellValue(worksheet, 'E10', 'number') *
            (this._readCellValue(worksheet, 'E12', 'number') / 100),
          pump_energy_to_heating_electrical:
            this._readCellValue(worksheet, 'E10', 'number') *
            (this._readCellValue(worksheet, 'E11', 'number') / 100),
          heat_recovery_electrical: this._readCellValue(
            worksheet,
            'E8',
            'number',
          ),
          heat_recovery_gas: this._readCellValue(worksheet, 'E9', 'number'),
          humidification_gas: this._readCellValue(worksheet, 'E7', 'number'),
          humidification_electrical: this._readCellValue(
            worksheet,
            'E6',
            'number',
          ),
          heating_other: this._readCellValue(worksheet, 'E5', 'number'),
          heating_gas: this._readCellValue(worksheet, 'E4', 'number'),
          heating_electrical: this._readCellValue(worksheet, 'E3', 'number'),
          hot_water_other: this._readCellValue(worksheet, 'E36', 'number'),
          hot_water_gas: this._readCellValue(worksheet, 'E35', 'number'),
          hot_water_electrical: this._readCellValue(worksheet, 'E34', 'number'),
          process_load_electrical: this._readCellValue(
            worksheet,
            'E29',
            'number',
          ),
          special_equipment_refrigeration_electrical: this._readCellValue(
            worksheet,
            'E28',
            'number',
          ),
          equipment_external_electrical: this._readCellValue(
            worksheet,
            'E27',
            'number',
          ),
          equipment_internal_electrical: this._readCellValue(
            worksheet,
            'E26',
            'number',
          ),

          distribution_pluming_water: this._readCellValue(
            worksheet,
            'E39',
            'number',
          ),
          cooking_electrical: this._readCellValue(worksheet, 'E37', 'number'),
          cooking_gas: this._readCellValue(worksheet, 'E38', 'number'),
          fuel_source_mechanical: this._readCellValue(
            worksheet,
            'E2',
            'string',
            ['Electric', 'Gas'],
          ),
          fuel_source_hydraulics: this._readCellValue(
            worksheet,
            'E33',
            'string',
            ['Electric', 'Gas'],
          ),
          lighting_exterior_electrical: this._readCellValue(
            worksheet,
            'E25',
            'number',
          ),
          lighting_interior_electrical: this._readCellValue(
            worksheet,
            'E24',
            'number',
          ),
          renewables_renewable_energy_electrical: this._cellExists(
            worksheet,
            'E22',
          )
            ? this._readCellValue(worksheet, 'E21', 'number')
            : 0,
          renewables_green_power: this._cellExists(worksheet, 'E21')
            ? 0
            : this._readCellValue(worksheet, 'E23', 'number'),
          special_equipment_vertical_transport_electrical: this._readCellValue(
            worksheet,
            'E31',
            'number',
          ),
          ventilation_external_fan_electrical: this._readCellValue(
            worksheet,
            'E20',
            'number',
          ),
          ventilation_internal_fan_electrical: this._readCellValue(
            worksheet,
            'E18',
            'number',
          ),
          ventilation_process_load: this._readCellValue(
            worksheet,
            'E21',
            'number',
          ),
          telecoms_data_centre_equipment_electrical: this._readCellValue(
            worksheet,
            'E32',
            'number',
          ),
        })),
      );
  }

  /**
   * Parse Detailed.
   * Parse a detailed assessment from worksheet.
   */
  parseDetailed(
    assetGrossArea: number,
  ): OperatorFunction<XLSX.WorkSheet, DetailedExcelModel> {
    return (workbook$) =>
      workbook$.pipe(
        map((worksheet) => {
          const detailedExcelModel: DetailedExcelModel = {
            systems: [],
          };
          let i = 2;
          while (true) {
            if (!this._cellExists(worksheet, `A${i}`)) {
              break;
            }
            const systemName = this._readCellValue<SystemName>(
              worksheet,
              `A${i}`,
              'string',
              Object.values(SystemName),
            );
            const subsystemName = this._readCellValue<SubsystemName>(
              worksheet,
              `B${i}`,
              'string',
              Object.values(SubsystemName),
            );
            const validSubsystemNames =
              this.utilsService.getSubsystemsNames(systemName);
            if (!validSubsystemNames.includes(subsystemName)) {
              throw new Error(
                `Invalid subsystem name ${subsystemName} for system ${systemName} at row ${i}`,
              );
            }
            const parameterName = this._readCellValue<DetailedParameterName>(
              worksheet,
              `C${i}`,
              'string',
              DETAILED_PARAMETER_NAMES,
            );
            const value =
              this.carbonCalculationService.absoluteCarbonForGrossArea(
                this._readCellValue(worksheet, `D${i}`, 'number'),
                assetGrossArea,
              );
            const system = detailedExcelModel.systems.find(
              (system) => system.system_name === systemName,
            );
            if (system === undefined) {
              detailedExcelModel.systems.push({
                system_name: systemName,
                subsystems: [
                  {
                    subsystem_name: subsystemName,
                    parameters: [
                      {
                        param_name: parameterName,
                        value: value!,
                      },
                    ],
                  },
                ],
              });
            } else {
              const subsystem = system.subsystems.find(
                (subsystem) => subsystem.subsystem_name === subsystemName,
              );
              if (subsystem === undefined) {
                system.subsystems.push({
                  subsystem_name: subsystemName,
                  parameters: [
                    {
                      param_name: parameterName,
                      value: value!,
                    },
                  ],
                });
              } else {
                const parameter = subsystem.parameters.find(
                  (parameter) => parameter.param_name === parameterName,
                );
                if (parameter === undefined) {
                  subsystem.parameters.push({
                    param_name: parameterName,
                    value: value!,
                  });
                } else {
                  parameter.value = value!;
                }
              }
            }
            i++;
          }
          return detailedExcelModel;
        }),
      );
  }

  /**
   * Export Project Progress List.
   *
   * Export the project progress list to a .ods file.
   */
  exportProjectProgressList(
    projects: ProgressDashboardPageState['projects'],
    filters: ProgressDashboardPageState['filters'],
    progressFilter: ProgressDashboardPageState['progressFilter'],
    campaign: CampaignModel,
    searchTerm: string,
  ) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(
      [
        ['Campaign Name:', campaign.name],
        filters.region
          ? [
              'Region:',
              `${filters.region.RegionName} - ${filters.region.RegionCode}`,
            ]
          : [],
        filters.groupOfGroups
          ? [
              'Geography:',
              `${filters.groupOfGroups.GroupOfGroupsName} - ${filters.groupOfGroups.GroupOfGroupsCode}`,
            ]
          : [],
        filters.accountingCentre
          ? [
              'Accounting Centre:',
              `${filters.accountingCentre.AccountingCentreName} - ${filters.accountingCentre.AccountingCentreCode}`,
            ]
          : [],
        filters.business
          ? [
              'Business:',
              `${filters.business.BusinessName} - ${filters.business.BusinessCode}`,
            ]
          : [],
        filters.assetFunction ? ['Asset Function:', filters.assetFunction] : [],
        filters.systems && filters.systems.length > 0
          ? ['Systems in Scope:', filters.systems.join(', ')]
          : [],
        searchTerm !== null || searchTerm !== ''
          ? ['Search Term:', searchTerm]
          : [],
        progressFilter !== null && progressFilter.length > 0
          ? ['Progress Filters:', progressFilter.join(', ')]
          : [],
        [
          'Project Number',
          'Project Name',
          'Project Manager Email',
          'Project Director Email',
          'Replacement Project Manager Email',
          'Replacement Project Director Email',
          'Assets Created',
          'High Level Operational Assessment Data Exists',
          'High Level Embodied Assessment Data Exists',
          'Detailed Energy Model Assessment Data Exists',
          'Detailed Assessment Data Exists',
          'All Assets Submitted to Campaign',
        ],
        ...(projects.data?.map(([project, progress]) => [
          project.project_number,
          project.ShortTitle,
          project.ProjectManagerEmail,
          project.ProjectDirectorEmail,
          project.replacement_project_manager,
          project.replacement_project_director,
          progress[0],
          progress[1][0],
          progress[1][1],
          progress[1][2],
          progress[1][3],
          progress[2],
        ]) ?? []),
      ].filter((row) => row.length > 0),
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Project Progress');
    XLSX.writeFile(workbook, 'zero-project-progress-export.ods');
  }
}
