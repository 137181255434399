import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDynamicPerson, MgtPeoplePicker } from '@microsoft/mgt';
import { Contact, Person, User } from '@microsoft/microsoft-graph-types';
import { GraphService } from '@mgt/services/graph.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SYSTEM_NOTIFICATION_CONFIG,
  SystemNotificationComponent,
  SystemNotificationData,
} from '@core/components/system-notification/system-notification.component';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';

/**
 * People Picker Control Value Accessor.
 */
type PeoplePickerValue = string | string[] | null;

/**
 * Is User.
 * Checks if the dynamic person is a user.
 */
function isUser(dynamicPerson: IDynamicPerson): dynamicPerson is User {
  return (dynamicPerson as User).mail !== undefined;
}

/**
 * Is Person.
 * Checks if the dynamic person is a person.
 */
function isPerson(dynamicPerson: IDynamicPerson): dynamicPerson is Person {
  return (dynamicPerson as Person).scoredEmailAddresses !== undefined;
}

/**
 * Is Contact.
 * Checks if the dynamic person is a contact.
 */
function isContact(dynamicPerson: IDynamicPerson): dynamicPerson is Contact {
  return (dynamicPerson as Contact).emailAddresses !== undefined;
}

/**
 * People Picker Component.
 * Wraps the MgtPeoplePicker component and provides a ControlValueAccessor interface
 * allowing it to be used in reactive forms. It also provides an implementation of
 * writeValue that allows the default people to be set when the component is initialized.
 * The PeoplePickerComponent value can be set to a string, an array of strings or null.
 * If the selectionMode is set to `single` then the value will be a string, otherwise
 * it will be an array of strings.
 */
@Component({
  selector: 'zero-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeoplePickerComponent),
      multi: true,
    },
  ],
})
export class PeoplePickerComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  /**
   * Destroyed Subject.
   * Emits when the component is destroyed.
   */
  private readonly _destroyed$ = new Subject<void>();

  /**
   * Allow multiple person selection or only single.
   * Default value is `single`.
   */
  @Input() selectionMode: MgtPeoplePicker['selectionMode'] = 'single';

  /**
   * Placeholder text for the people picker input.
   * Default value is 'Start typing a name'.
   */
  @Input() placeholder: MgtPeoplePicker['placeholder'] = 'Start typing a name';

  /**
   * MgtPeoplePicker Component.
   * Reference to the MgtPeoplePicker component.
   */
  @ViewChild('peoplePicker')
  private readonly _mgtPeoplePicker?: ElementRef<MgtPeoplePicker>;

  /**
   * Default People.
   * The default people to be selected when the component is initialized.
   */
  private _defaultPeople: IDynamicPerson[] = [];

  constructor(
    private graphService: GraphService,
    private readonly snackBar: MatSnackBar,
  ) {}

  /**
   * After View Init.
   * Sets the default people on the MgtPeoplePicker component.
   */
  ngAfterViewInit() {
    if (this._mgtPeoplePicker) {
      this._mgtPeoplePicker.nativeElement.selectedPeople = this._defaultPeople;
    }
  }

  /**
   * On Destroy.
   * Calls the next method on the destroyed subject to complete all subscriptions.
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Handle selection changed event.
   * Handle the selection changed event emitted by the MgtPeoplePicker component.
   * The event detail contains an array of dynamic people. The dynamic people
   * can be of type User, Person or Contact. The email address is extracted from
   * the dynamic person and the control value is updated. The control value is
   * either a string or an array of strings depending on the selection mode.
   */
  handleSelectionChanged(event: Event) {
    const emails = (event as CustomEvent<IDynamicPerson[]>).detail
      .map((dynamicPerson) => {
        return isUser(dynamicPerson)
          ? dynamicPerson.mail?.toLowerCase()
          : isPerson(dynamicPerson)
          ? dynamicPerson.scoredEmailAddresses?.[0].address?.toLowerCase()
          : isContact(dynamicPerson)
          ? dynamicPerson.emailAddresses?.[0].address?.toLowerCase()
          : null;
      })
      .filter((email) => {
        return email !== undefined && email !== null;
      }) as string[];
    this._onTouched();
    this._onChange(this.selectionMode === 'single' ? emails[0] : emails);
  }

  /**
   * On Change Callback.
   * Function to call when the control value changes.
   */
  private _onChange(emails: PeoplePickerValue) {}

  /**
   * On Touched Callback.
   * Function to call when the control has been touched.
   */
  private _onTouched() {}

  /**
   * Register On Change Callback.
   * Register the on change function that is called when the control value changes.
   */
  registerOnChange(onChange: (emails: PeoplePickerValue) => void) {
    this._onChange = onChange;
  }

  /**
   * Register On Touched Callback.
   * Register the on touched function that is called when the control has been touched.
   */
  registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  /**
   * Write the value of the control.
   * The value of the control is an email address or an array of email addresses.
   * The email addresses are used to retrieve the user details from the Microsoft
   * Graph API, then the user details are set on the MgtPeoplePicker component if
   * it is initialized or stored in the default people property ready to be set
   * when the component is initialized.
   */
  writeValue(emails: PeoplePickerValue) {
    if (emails === null) emails = [];
    if (typeof emails === 'string') emails = [emails];
    forkJoin(
      emails.map((email) =>
        this.graphService.getUser(email).pipe(
          catchError(() => {
            return of(email);
          }),
        ),
      ),
    )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((users) => {
        const failedEmails = users.filter((user) => typeof user === 'string');
        if (failedEmails.length > 0) {
          this.snackBar.openFromComponent(SystemNotificationComponent, {
            ...SYSTEM_NOTIFICATION_CONFIG,
            data: <SystemNotificationData>{
              type: 'error',
              title: 'Failed to fetch user details',
              message: `Failed to fetch user details for ${failedEmails.join(
                ', ',
              )}.`,
            },
          });
        }
        const successfulUsers = users.filter(
          (user) => typeof user !== 'string',
        ) as User[];
        if (this._mgtPeoplePicker) {
          this._mgtPeoplePicker.nativeElement.selectedPeople = successfulUsers;
        } else {
          this._defaultPeople = successfulUsers;
        }
      });
  }
}
