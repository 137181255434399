import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { AccountInfo } from '@azure/msal-browser';
import posthog from 'posthog-js';

/**
 * PosthogService Service.
 * Responsible for handling Posthog operations.
 */
@Injectable()
export class PosthogService {
  constructor(private configService: ConfigService) {}

  /* istanbul ignore next */
  /**
   * Initialize posthog.
   * Only called in production.
   */
  initialize() {
    if (this.configService.config.production) {
      posthog.init(`${this.configService.config.posthogId}`, {
        api_host: 'https://posthog.insights.arup.com',
      });
    }
  }

  /* istanbul ignore next */
  /**
   * Identify the user in posthog.
   * Only called in production.
   */
  identify(account: AccountInfo) {
    if (this.configService.config.production) {
      posthog.identify(account.localAccountId, {
        email: account.username.toLowerCase(),
      });
    }
  }

  /* istanbul ignore next */
  /**
   * Clear posthog user data.
   * Only called in production.
   */
  reset() {
    if (this.configService.config.production) {
      posthog.reset();
    }
  }
}
