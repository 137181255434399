import { Component, Input } from '@angular/core';
import { UtilsService } from '@core/services/utils.service';
import { SystemName } from '@asset/enums/system-name';

/**
 * System Chip Component.
 *
 * Responsible for rendering a system name within
 * a chip. Out of scope systems have a stoked
 * style while in scope systems have solid fill
 * chip style.
 */
@Component({
  selector: 'zero-system-chip',
  templateUrl: './system-chip.component.html',
  styleUrls: ['./system-chip.component.scss'],
})
export class SystemChipComponent {
  /**
   * Name of the system.
   */
  @Input() name!: SystemName;

  /**
   * Scope of the system.
   */
  @Input() scope = false;

  /**
   * Chip is selectable and should have selectable styles.
   */
  @Input() selectable = false;

  constructor(public utils: UtilsService) {}
}
