export enum SubsystemName {
  /* Substructure */
  BasementFrame = 'Ground Slab - Basement Frame',
  Foundation = 'Foundation',
  BasementPerimeter = 'Basement Perimeter',
  /* Superstructure */
  Floorplate = 'Floorplate',
  VerticalStructure = 'Vertical Structure',
  Stability = 'Stability',
  Roof = 'Roof',
  Stairs = 'Stairs',
  /* Mechanical Services */
  Heating = 'Heating',
  Cooling = 'Cooling',
  Ventilation = 'Ventilation',
  Process = 'Process',
  /* Electrical Services */
  Electrical = 'Electrical',
  Telecomms = 'Telecomms - ICT',
  Lighting = 'Lighting',
  Renewables = 'Renewables',
  VerticalTransport = 'Vertical Transport',
  Security = 'Security',
  /* Public Health & Hydraulics */
  HotWater = 'Hot Water',
  ColdWater = 'Cold Water',
  WasteWater = 'Waste Water',
  Fire = 'Piped Fire Systems',
  Fuel = 'Piped Fuel',
  Specialist = 'Specialist',
  /* Building Envelope */
  Facade = 'Facade',
  RoofFinishes = 'Roof Finishes',
  /* Space Plan */
  Partitions = 'Partitions',
  FloorFinishes = 'Floor Finishes',
  CeilingFinishes = 'Ceiling Finishes',
  WallFinishes = 'Wall Finishes',
  ArchitecturalMetalwork = 'Architectural Metalwork',
}
