import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Tag Component.
 * Responsible for rendering a tag. A tag is a small piece of information
 * that can be used to categorize or label content. Tags have both an optional
 * icon and a required text label. Content is provided with the use of content
 * projection.
 *
 * @example ```html
 * <zero-tag>foo</zero-tag>
 * ```
 *
 * @example ```html
 * <zero-tag><mat-icon>home</mat-icon>foo</zero-tag>
 * ```
 *
 * @example ```html
 * <zero-tag style="background-color: #f00; color: #fff;">foo</zero-tag>
 * ```
 */
@Component({
  selector: 'zero-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {}
