import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

export const DETAILED_ENDPOINT = new InjectionToken<string>(
  'DETAILED_ENDPOINT',
  {
    factory: () =>
      `${inject(ConfigService).config.apiURL}/assessments/detailed`,
  },
);
