import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AssessmentType,
  assessmentTypeDisplayString,
} from '@core/types/assessment-type';

/**
 * Create Assessment Component.
 * Responsible for rendering a create assessment button for the given assessment
 * type. The buttons icon and text will change depending on whether the user
 * has already created an assessment for the given assessment type.
 */
@Component({
  selector: 'zero-create-assessment[assessmentType][assessmentExists]',
  templateUrl: './create-assessment.component.html',
  styleUrls: ['./create-assessment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAssessmentComponent {
  /**
   * Assessment Type.
   * The type of assessment to render the create assessment button for.
   */
  @Input() assessmentType!: AssessmentType;

  /**
   * Assessment Exists.
   * Whether the user has already created an assessment for the given assessment.
   */
  @Input() assessmentExists!: boolean;

  /**
   * Create Assessment Event.
   * Emits an event when the user clicks the create assessment button.
   */
  @Output() createAssessment = new EventEmitter<AssessmentType>();

  /**
   * Assessment Type Display String.
   * The display string for the given assessment type.
   */
  readonly assessmentTypeDisplayString = assessmentTypeDisplayString;
}
