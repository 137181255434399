import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from '@core/components/unauthorized/unauthorized.component';
import { UnderConstructionComponent } from '@core/components/under-construction/under-construction.component';

/**
 * App Routes.
 * Defines the routes for the application.
 */
export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./landing-page/landing-page.module').then(
        (module) => module.LandingPageModule,
      ),
  },
  {
    path: 'asset',
    loadChildren: () =>
      import('./asset/asset.module').then((m) => m.AssetModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'collection',
    loadChildren: () =>
      import('./collection/collection.module').then((m) => m.CollectionModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'operational-assessment',
    loadChildren: () =>
      import('./operational-assessment/operational-assessment.module').then(
        (m) => m.OperationalAssessmentModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'detailed-assessment',
    loadChildren: () =>
      import('./detailed-assessment/detailed-assessment.module').then(
        (m) => m.DetailedAssessmentModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'energy-model-assessment',
    loadChildren: () =>
      import('./energy-model-assessment/energy-model-assessment.module').then(
        (m) => m.EnergyModelAssessmentModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'embodied-assessment',
    loadChildren: () =>
      import('./embodied-assessment/embodied-assessment.module').then(
        (m) => m.EmbodiedAssessmentModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'project',
    loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'oauth2-redirect',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'global-insights',
    component: UnderConstructionComponent,
  },
  {
    path: 'global-library',
    component: UnderConstructionComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];
