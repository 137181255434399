import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

/**
 * Operational Endpoint.
 * Injection token for the operational endpoint.
 */
export const OPERATIONAL_ENDPOINT = new InjectionToken<string>(
  'OPERATIONAL_ENDPOINT',
  {
    factory: () =>
      `${
        inject(ConfigService).config.apiURL
      }/assessments/high_level/operational`,
  },
);
