<div *ngIf="vm$ | async as vm" class="asset-card zero-card">
  <div class="front">
    <span *ngIf="viewed" class="zero-tag notification">New</span>
    <div class="chart">
      <img src="/assets/card_vector.svg" />
    </div>
    <div class="overview">
      <h1>{{ asset.name }}</h1>
      <p>
        <ng-container
          *ngIf="vm.project.status === 'success'; else loadingSpinner"
        >
          {{ vm.project.data.ShortTitle }} |
          {{ vm.project.data.project_number | projectNumber }}
        </ng-container>
      </p>
      <p>Last Updated {{ asset.updated_at | date: 'mediumDate' }}</p>
      <div class="status zero-tag-list">
        <span *ngIf="asset.active" class="zero-tag" tippy="Active"
          ><mat-icon class="active">done</mat-icon> Active</span
        >
        <span *ngIf="!asset.active" class="zero-tag" tippy="Inactive"
          ><mat-icon class="inactive">close</mat-icon> Inactive</span
        >
        <span class="zero-tag status">{{
          asset.status | status | titlecase
        }}</span>
        <span *ngIf="asset?.confidential" class="zero-tag confidential"
          ><mat-icon>lock</mat-icon>Confidential</span
        >
      </div>
      <div class="status zero-tag-list">
        <span class="zero-tag">{{ asset.type }}</span>
        <span class="zero-tag location">{{ asset.address }}</span>
      </div>
      <span class="actions">
        <button mat-stroked-button (click)="viewAsset.emit()">View</button>
        <button
          mat-flat-button
          class="details"
          color="transparent"
          (click)="handleDetailsClick()"
        >
          Details
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </span>
    </div>
  </div>

  <div
    class="back asset-details-typography"
    [ngClass]="flipped ? 'visible' : ''"
  >
    <span>
      <strong>Description:</strong>
      <p class="description">{{ asset.description }}</p>
    </span>
    <span>
      <p>
        <strong>Associated Project: </strong>
        <ng-container
          *ngIf="vm.project.status === 'success'; else loadingSpinner"
        >
          {{ vm.project.data.ShortTitle }}
        </ng-container>
      </p>
      <p><strong>Phase</strong>: {{ asset.status | status | titlecase }}</p>
      <p><strong>Asset type:</strong> {{ asset.type | titlecase }}</p>
      <p>
        <strong>Primary Function:</strong>
        {{ asset.functions[0].type }} ({{ asset.functions[0].percentage }}%)
      </p>
      <p *ngIf="asset.functions.length > 1">
        <strong>Secondary Function(s):</strong>
        <ng-container *ngFor="let function of asset.functions | slice: 1">
          {{ function.type }} ({{ function.percentage }}%)
        </ng-container>
      </p>
      <p><strong>Location:</strong> {{ asset.address }}</p>
    </span>
    <span *ngIf="(asset.systems | systemScope: true).length > 0">
      <p>
        <strong>In Scope Systems: </strong>
        <span
          *ngFor="
            let system of asset.systems | systemScope: true;
            let last = last
          "
          >{{ system.name }}{{ last ? '' : ', ' }}
        </span>
      </p>
    </span>
    <span *ngIf="(asset.systems | systemScope: false).length > 0">
      <p>
        <strong>Out of Scope Systems: </strong>
        <span
          *ngFor="
            let system of asset.systems | systemScope: false;
            let last = last
          "
          >{{ system.name }}{{ last ? '' : ', ' }}
        </span>
      </p>
    </span>
    <span class="assigned-users">
      <strong>Assigned to: </strong>
      <ng-container
        *ngIf="vm.assignedUsers.status === 'success'; else loadingSpinner"
      >
        <mgt-person
          *ngFor="
            let assignee of vm.assignedUsers.data
              | assignedUsersLengthFilter: asset.created_by:5
          "
          [personQuery]="assignee"
          person-card="hover"
          show-presence
        ></mgt-person>
        <ng-container *ngIf="vm.assignedUsers.data.length > 5">
          <span>+ {{ vm.assignedUsers.data!.length - 5 }}</span>
        </ng-container>
      </ng-container>
    </span>
    <span>
      <p>
        <strong>Last updated: </strong>
        {{ asset.updated_at | date: 'mediumDate' }}
      </p>
      <div class="created-by">
        <strong>Updated By:</strong>
        <mgt-person
          [personQuery]="asset.updated_by"
          show-presence
          view="oneline"
          person-card="hover"
        ></mgt-person>
      </div>
      <p>
        <strong>Date created:</strong>
        {{ asset.created_at | date: 'mediumDate' }}
      </p>
      <div class="created-by">
        <strong>Created By:</strong>
        <mgt-person
          [personQuery]="asset.created_by"
          show-presence
          view="oneline"
          person-card="hover"
        ></mgt-person>
      </div>
    </span>
    <span class="actions">
      <button mat-icon-button color="secondary" (click)="handleDetailsClick()">
        <mat-icon>arrow_backward</mat-icon>
      </button>
    </span>
  </div>
</div>

<ng-template #loadingSpinner>
  <arc-spinner></arc-spinner>
</ng-template>
