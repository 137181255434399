import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

export const BENCHMARK_ENDPOINT = new InjectionToken<string>(
  'BENCHMARK_ENDPOINT',
  {
    factory: () =>
      `${inject(ConfigService).config.apiURL}/assessments/benchmark`,
  },
);
