{
  "name": "zero-frontend",
  "version": "2.18.4",
  "private": true,
  "builders": "builders.json",
  "scripts": {
    "start": "ng serve",
    "build": "ng build",
    "lint": "ng lint",
    "test": "ng test"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.11",
    "@angular-devkit/core": "17.3.7",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.4.1",
    "@angular-eslint/template-parser": "17.4.0",
    "@angular/cli": "17.3.6",
    "@angular/compiler-cli": "17.3.5",
    "@angular/language-service": "17.3.9",
    "@commitlint/cli": "19.3.0",
    "@commitlint/config-conventional": "19.2.2",
    "@microsoft/microsoft-graph-types": "2.40.0",
    "@types/d3": "7.4.3",
    "@types/jest": "29.5.11",
    "@types/node": "18.19.33",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.17.0",
    "dotenv": "16.3.1",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-jest": "27.6.0",
    "eslint-plugin-prettier": "4.2.1",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-preset-angular": "14.0.4",
    "ng-mocks": "14.12.2",
    "prettier": "2.8.8",
    "ts-jest": "29.1.1",
    "ts-node": "10.9.2",
    "typescript": "5.4.5"
  },
  "dependencies": {
    "@ag-grid-community/angular": "30.2.1",
    "@ag-grid-community/client-side-row-model": "30.2.1",
    "@ag-grid-community/core": "30.2.1",
    "@ag-grid-community/styles": "31.2.1",
    "@angular/animations": "17.3.5",
    "@angular/cdk": "17.3.5",
    "@angular/cdk-experimental": "17.3.5",
    "@angular/common": "17.3.5",
    "@angular/compiler": "17.3.5",
    "@angular/core": "17.3.5",
    "@angular/forms": "17.3.5",
    "@angular/material": "17.3.5",
    "@angular/material-moment-adapter": "17.3.5",
    "@angular/platform-browser": "17.3.5",
    "@angular/platform-browser-dynamic": "17.3.5",
    "@angular/router": "17.3.5",
    "@arc-web/components": "2.3.3",
    "@azure/msal-angular": "3.0.16",
    "@azure/msal-browser": "3.14.0",
    "@microsoft/applicationinsights-angularplugin-js": "3.0.3",
    "@microsoft/applicationinsights-web": "3.2.1",
    "@microsoft/mgt": "3.1.3",
    "@ngneat/helipopper": "5.1.4",
    "@ngneat/overview": "3.0.4",
    "@ngrx/component-store": "17.2.0",
    "@ngrx/operators": "17.2.0",
    "@sentry/angular-ivy": "7.119.2",
    "chart.js": "4.4.1",
    "d3": "7.8.5",
    "lit-element": "2.5.1",
    "lit-html": "1.4.1",
    "mat-select-search": "1.2.21",
    "moment": "2.29.4",
    "ng2-charts": "5.0.4",
    "posthog-js": "1.130.2",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "zone.js": "0.14.5"
  }
}
