import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AssemblyModel } from '@veracity/models/assembly.model';
import { AssemblyAssumptionModel } from '@veracity/models/assembly-assumption.model';

/**
 * Assembly Modal Config.
 * Defines the configuration of the assembly modal component.
 */
export const ASSEMBLY_MODAL_CONFIG: MatDialogConfig = {
  disableClose: false,
};

/**
 * Assembly Modal Data.
 * Defined the data of the assembly modal component.
 */
export interface AssemblyModalData {
  /* The assembly to render details for */
  assembly: AssemblyModel;
  /* The assebmly assumptions to render */
  assumptions: AssemblyAssumptionModel[];
}

/**
 * Assembly Modal Component.
 * Responsible for rendering an assembly details and
 * its associated assumptions. This component is opened by the
 * material dialog service and data is provided by the injected
 * MAT_DIALOG_DATA token.
 *
 * @example ```
 * this.dialog.open(AssemblyModalComponent, {
 *   ...ASSEMBLY_MODAL_CONFIG,
 *   data: <AssemblyModalData>{ ... },
 * });
 *  ```
 */
@Component({
  selector: 'zero-assembly-modal',
  templateUrl: './assembly-modal.component.html',
  styleUrls: ['./assembly-modal.component.scss'],
})
export class AssemblyModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: AssemblyModalData,
  ) {}
}
