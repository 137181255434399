import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CollectionModel } from '@collection/models/collection.model';
import { HttpService } from '@core/services/http.service';
import { AssetModel } from '@asset/models/asset.model';
import { COLLECTION_ENDPOINT } from '@collection/tokens/collection-endpoint.token';

/**
 * Collection Service.
 * Responsible for all collection related operations.
 */
@Injectable({ providedIn: 'root' })
export class CollectionService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(COLLECTION_ENDPOINT) private readonly collectionEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Asset Collections.
   * Makes an HTTP call to the API to get collection for give asset id.
   */
  getAssetCollections(assetId: AssetModel['id']) {
    return this.http
      .get<CollectionModel[]>(
        `${this.collectionEndpoint}?asset_id=${assetId}`,
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Create Collections.
   * Makes an HTTP call to the API to create a collection for each assigned user.
   */
  createCollections(users: string[], assetId: number) {
    return this.http
      .post<CollectionModel[]>(
        this.collectionEndpoint,
        { asset_id: assetId, assigned_to: users },
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Remove Collection.
   * Makes an HTTP call to the API to remove a collection.
   */
  removeCollection(collectionId: number) {
    return this.http
      .delete<string>(`${this.collectionEndpoint}/${collectionId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Get Collections.
   * Makes an HTTP call to the API to get collection for current user.
   */
  getCollections() {
    return this.http
      .get<CollectionModel[]>(this.collectionEndpoint, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Remove New Notification.
   * Makes an HTTP call to the API to set the viewed property of the collection
   * to true.
   */
  removeNewNotification(collection: CollectionModel) {
    return this.http
      .patch<CollectionModel>(
        `${this.collectionEndpoint}/${collection.id}`,
        { viewed: true },
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }
}
