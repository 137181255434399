import {
  HttpErrorResponse,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AssetModel } from '@asset/models/asset.model';
import {
  SystemNotificationComponent,
  SystemNotificationData,
  SYSTEM_NOTIFICATION_CONFIG,
} from '@core/components/system-notification/system-notification.component';
import { Assessment } from '@core/types/assessment';
import {
  AssessmentType,
  assessmentTypeDisplayString,
} from '@core/types/assessment-type';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import {
  OperatorFunction,
  of,
  tap,
  switchMap,
  iif,
  throwError,
  catchError,
  map,
} from 'rxjs';

/**
 * HTTP Action.
 * Enumerates the possible HTTP actions.
 * The action is set in the x-action header of the HttpResponse.
 * The action is performed by the HttpService.
 */
export const HTTP_ACTION = {
  /**
   * Invalid Location.
   * Triggured when lat long cannot be auto fixed.
   * The action is to navigate to the edit asset page.
   */
  InvalidLocation: 'invalid-location',
} as const;

/**
 * HTTP Action.
 * The type of the HTTP action.
 * The type is set in the x-action header of the HttpResponse.
 * The type is used to determine the action to perform.
 */
export type HttpAction = typeof HTTP_ACTION[keyof typeof HTTP_ACTION];

/**
 * HTTP Request Configuration.
 * The configuration for the HTTP request sent to the Zero API.
 * Zero API are piped through operator returned from `HttpServic`
 * methods. This configuration can be used to skip piping through
 * certain operators in the stream.
 */
export interface HttpRequestConfiguration {
  /* Skip opening a system notification. */
  skipSystemNotification?: boolean;
  /* Skip performing an action. */
  skipPerformAction?: boolean;
}

/**
 * HTTP Service.
 * Responsible for handling common HTTP operations.
 */
@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(
    private readonly insightsService: ApplicationInsightsService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
  ) {}

  /**
   * Open System Notification.
   * Returns an operator function that opens a system notification if the
   * response headers contain the x-system-notification header and the value
   * is true. The notification type, title and message are set from the x-type,
   * x-title and x-message headers respectively.
   */
  openSystemNotification<T>(
    config?: HttpRequestConfiguration,
  ): OperatorFunction<HttpResponse<T>, HttpResponse<T>> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => !config?.skipSystemNotification,
            of(response).pipe(
              tap((response) => {
                if (
                  response.headers.has('x-system-notification') &&
                  response.headers.get('x-system-notification') === 'true' &&
                  response.headers.has('x-type') &&
                  response.headers.has('x-title') &&
                  response.headers.has('x-message')
                ) {
                  this.snackBar.openFromComponent(SystemNotificationComponent, {
                    ...SYSTEM_NOTIFICATION_CONFIG,
                    data: <SystemNotificationData>{
                      type: response.headers.get('x-type'),
                      title: response.headers.get('x-title'),
                      message: response.headers.get('x-message'),
                    },
                  });
                }
              }),
            ),
            of(response),
          ),
        ),
      );
  }

  /**
   * Perform Action.
   * Returns an operator function that performs an action if the HttpResponse
   * headers contain the x-action header.
   */
  performAction<T>(
    config?: HttpRequestConfiguration,
  ): OperatorFunction<HttpResponse<T>, HttpResponse<T>> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => !config?.skipPerformAction,
            of(response).pipe(
              tap((response) => {
                const action = response.headers.get(
                  'x-action',
                ) as HttpAction | null;
                switch (action) {
                  case HTTP_ACTION.InvalidLocation:
                    const asset = response.body! as unknown as AssetModel;
                    this.router.navigate(['asset', asset.id, 'edit'], {
                      state: { noLoad: true },
                    });
                    break;
                }
              }),
            ),
            of(response),
          ),
        ),
      );
  }

  /**
   * Catch Asset Errors.
   * Returns an operator function that catches asset request errors. A system
   * notification is opened if the asset request is 410 Gone. And the user is
   * redirected to the collection page.
   */
  catchAssetError<T>(): OperatorFunction<HttpResponse<T>, HttpResponse<T>> {
    return (response$) =>
      response$.pipe(
        // TODO: Remove this in favour of the backend trigguring the system notification
        // using the HTTP headers in the asset response
        tap((response) => {
          if (
            response.status === HttpStatusCode.PartialContent &&
            response.headers.get('x-system-notification') !== 'true'
          ) {
            const asset: AssetModel = response.body as any as AssetModel;
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'warning',
                title: 'Incomplete asset',
                message: `Asset "${asset.name}" contains missing parameters.  Please update the asset in DDB`,
              },
            });
          }
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === HttpStatusCode.Gone) {
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'warning',
                title: 'Asset archived',
                message: 'Asset has been archived.',
              },
            });
            this.router.navigate(['collection']);
          }
          return throwError(() => response);
        }),
      );
  }

  /**
   * Catch Assessment Errors.
   * Returns an operator function that catches assessment request errors and
   * returns an empty assessment response instead to prevent the request from
   * failing and blocking the rest of asset from loading.
   *
   * 204 - No Content repsonses represent an unstarted empty assessment.
   */
  catchAssessmentError<T>(
    assessmentType: AssessmentType,
  ): OperatorFunction<
    HttpResponse<Assessment<T>>,
    HttpResponse<Assessment<T>>
  > {
    return (response$) =>
      response$.pipe(
        // TODO: Remove this in favour of the backend opening a system notification
        // using the x-system-notification header. This is a temporary solution to
        // display a system notification when an assessment contains missing.
        // Open a system notification if the response status is 206 Partial Content.
        // This indicates that the assessment contains missing or invalid parameters.
        tap((response) => {
          if (response.status === HttpStatusCode.PartialContent) {
            const assessment = assessmentTypeDisplayString(assessmentType);
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'warning',
                title: `Incomplete ${assessment} assessment`,
                message: `Assessment ${assessment} assessment contains missing or invalid parameters.`,
              },
            });
          }
        }),
        map((response) =>
          response.status === HttpStatusCode.Ok
            ? response
            : new HttpResponse<Assessment<T>>({
                ...response,
                url: '',
                body: null,
              }),
        ),
        catchError(() => of(new HttpResponse<Assessment<T>>({ body: null }))),
      );
  }

  /**
   * Redirect Forbidden.
   * Returns an operator function that redirects the user to the unauthorized
   * page if the response status is 403 Forbidden.
   */
  redirectForbidden<T>(): OperatorFunction<HttpResponse<T>, HttpResponse<T>> {
    return (response$) =>
      response$.pipe(
        catchError((response) => {
          if (response.status === HttpStatusCode.Forbidden) {
            // TODO: Remove this in favour of the backend opening a system notification
            // using the x-system-notification header. This is a temporary solution to
            // display a system notification when the user is forbidden to access a resource.
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'error',
                title: 'Forbidden to access resource',
                message: 'You are not authorized to access this resource.',
              },
            });

            this.router.navigate(['unauthorized']);
          }
          return throwError(() => response);
        }),
      );
  }

  /**
   * Body.
   * Returns an operator function that maps the response body to the source
   * observable. If the response is not ok the error is thrown.
   */
  body<T>(): OperatorFunction<HttpResponse<T>, T> {
    return (response$) =>
      response$.pipe(
        switchMap((response) =>
          iif(
            () => response.ok,
            of(response.body as T),
            throwError(() => response),
          ),
        ),
      );
  }

  /**
   * Tack Error.
   * Returns an operator function that used the `ApplicationInsightsService`
   * to track the error.
   */
  trackError<T>(): OperatorFunction<T, T> {
    return (response$) =>
      response$.pipe(
        catchError((error) => {
          this.insightsService.trackException(error);
          return throwError(() => error);
        }),
      );
  }
}
