import { Component } from '@angular/core';

@Component({
  selector: 'zero-unauthorized',
  template: `
    <div class="zero-page-xx-large">
      You are not authorized to access this..
    </div>
  `,
})
export class UnauthorizedComponent {}
