import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@core/tokens/window.token';
import { DeviceType } from '@core/types/device-type';

/**
 * Mobile Regex.
 * Defines the regex to detect if the user is on a mobile device.
 */
const MOBILE_REGEX =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

/**
 * Tablet Regex.
 * Defines the regex to detect if the user is on a tablet device.
 */
const TABLET_REGEX = /iPad/i;

/**
 * Device Type.
 * Di token to detect the device type.
 */
export const DEVICE_TYPE = new InjectionToken<DeviceType>('DEVICE_TYPE', {
  factory: () => {
    const { userAgent } = inject(WINDOW).navigator;
    const isMobile = MOBILE_REGEX.test(userAgent);
    const isTablet = TABLET_REGEX.test(userAgent);
    return isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop';
  },
});
