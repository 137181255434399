import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilsService } from '@core/services/utils.service';

@Directive({
  selector: '[zeroPatchFormGroupValue]',
})
export class PatchFormGroupValueDirective {
  @Input() formGroup!: FormGroup;
  @Input() set zeroPatchFormGroupValue(value: any) {
    if (this.utilsService.isDefined(value)) {
      this.formGroup.patchValue(value, { emitEvent: false });
    }
  }
  constructor(private utilsService: UtilsService) {}
}
