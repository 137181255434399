import { Component } from '@angular/core';

/**
 * Carbon Table Component.
 *
 * Responsible for rendering a carbon parameter
 * table. For small screen sizes an overlay with
 * be rendered on top to inform the user that a
 * lager screen is required for viewing and editing
 * the carbon parameter table.
 */
@Component({
  selector: 'zero-carbon-table',
  templateUrl: './carbon-table.component.html',
  styleUrls: ['./carbon-table.component.scss'],
})
export class CarbonTableComponent {}
