import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

/**
 * Embodied Endpoint.
 * Injection token for the embodied endpoint.
 */
export const EMBODIED_ENDPOINT = new InjectionToken<string>(
  'ENERGY_MODEL_ENDPOINT',
  {
    factory: () =>
      `${inject(ConfigService).config.apiURL}/assessments/high_level/embodied`,
  },
);
