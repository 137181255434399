import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@microsoft/microsoft-graph-types';
import { HttpService } from '@core/services/http.service';
import { GRAPH_ENDPOINT } from '@mgt/tokens/graph-endpoint.token';

/**
 * Graph Service.
 * Responsible for making HTTP requests to the Microsoft Graph API.
 */
@Injectable()
export class GraphService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(GRAPH_ENDPOINT) private readonly graphEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Current User.
   * Makes HTTP request to Microsoft Graph API to get the current user information.
   */
  getCurrentUser() {
    return this.http
      .get<User>(`${this.graphEndpoint}/me`)
      .pipe(this.httpService.trackError());
  }

  /**
   * Get User.
   * Makes HTTP request to Microsoft Graph API to get the user information for the
   * specified email address.
   */
  getUser(email: string) {
    return this.http
      .get<User>(`${this.graphEndpoint}/users/${email}`)
      .pipe(this.httpService.trackError());
  }
}
