import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AssetImportableModel, AssetModel } from '@asset/models/asset.model';
import { BenchmarkModel } from '@asset/models/benchmark.model';
import { BenchmarkService } from '@asset/services/benchmark.service';
import { DonutChartDataModel } from '@asset/models/donut-chart-data/donut-chart-data.model';
import { CollectionModel } from '@collection/models/collection.model';
import { CollectionService } from '@collection/services/collection.service';
import { AssetService } from '@asset/services/asset.service';
import { Assessment } from '@core/types/assessment';
import { ChartService } from '@core/services/chart.service';
import { BarChartData } from '@core/models/chart-data';
import { HttpDataState } from '@core/types/http-data-state';
import { EmbodiedModel } from '@embodied-assessment/models/embodied.model';
import { EmbodiedService } from '@embodied-assessment/services/embodied.service';
import { EnergyModelModel } from '@energy-model-assessment/models/energy-model/energy-model.model';
import { EnergyModelService } from '@energy-model-assessment/services/energy-model.service';
import { DetailedModel } from '@detailed-assessment/models/detailed.model';
import { DetailedService } from '@detailed-assessment/services/detailed.service';
import { ProjectService } from '@project/services/project.service';
import { ProjectModel } from '@project/models/project.model';
import { LoadingModalService } from '@core/services/loading-modal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SYSTEM_NOTIFICATION_CONFIG,
  SystemNotificationComponent,
  SystemNotificationData,
} from '@core/components/system-notification/system-notification.component';
import { OperationalModel } from '@operational-assessment/models/operational/operational.model';
import { OperationalService } from '@operational-assessment/services/operational.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { SystemService } from '@system/services/system.service';
import { SiteService } from '@asset/services/site.service';
import { SiteModel } from '@asset/models/site/site.model';
import { SiteUploadModel } from '@asset/models/site-upload/site-upload.model';
import { ValuesOf } from '@core/types/value-of';
import { filterSuccess } from '@core/utils/filter-success';
import { CreateFormControlsModel } from '@asset/models/create-form-controls/create-form-controls.model';
import { AssetFunctionModel } from '@asset/models/asset-function.model';
import { PlaceResultModel } from '@asset/models/place-result/place-result.model';
import { EditFormControl } from '@asset/containers/edit-page/edit-page.component';
import { ImportFormControl } from '@asset/containers/import-page/import-page.component';
import { AssessmentType } from '@core/types/assessment-type';
import {
  ACKNOWLEDGEMENT_MODAL_CONFIG,
  AcknowledgementModalComponent,
  AcknowledgementModalData,
  AcknowledgementModalResult,
  acknowledgementModalResultIsBoolean,
} from '@core/components/acknowledgement-modal/acknowledgement-modal.component';
import {
  UPDATE_DESIGN_STAGE_MODAL_CONFIG,
  UpdateDesignStageModalComponent,
  UpdateDesignStageModalData,
  UpdateDesignStageModalResult,
} from '@asset/components/update-design-stage-modal/update-design-stage-modal.component';
import { AuthStore } from '@auth/state/auth.store';
import { filterNullish } from '@core/utils/filter-nullish';
import { SystemModel } from '@asset/models/system/system.model';
import { User } from '@microsoft/microsoft-graph-types';
import {
  forkJoin,
  map,
  of,
  switchMap,
  withLatestFrom,
  combineLatest,
  tap,
  EMPTY,
  OperatorFunction,
  iif,
} from 'rxjs';

/**
 * Asset Store State.
 * Defines the state of the asset store.
 */
interface AssetStoreState {
  /* The current asset data. */
  currentAsset: HttpDataState<{
    /* The current asset information. */
    asset: AssetModel;
    /* The current asset collections. */
    collections: CollectionModel[];
    /* The current assets project */
    project: ProjectModel;
    /* The current assets benchmark assessment. */
    benchmark: Assessment<BenchmarkModel>;
    /* The current assets high level operational assessment. */
    operational: Assessment<OperationalModel>;
    /* The current assets high level embodied assessment. */
    embodied: Assessment<EmbodiedModel>;
    /* The current assets detailed energy model assessment. */
    energyModel: Assessment<EnergyModelModel>;
    /* The current assets detailed energy model assessment. */
    detailed: Assessment<DetailedModel>;
    /* The current assets bar chart data. */
    barChartData: BarChartData;
    /* The current assets donut chart data */
    donutChartData: DonutChartDataModel;
  }>;
}

/**
 * Asset Store.
 * Responsible for managing asset state.
 */
@Injectable({ providedIn: 'root' })
export class AssetStore extends ComponentStore<AssetStoreState> {
  /**
   * Current Asset.
   * The current asset data.
   */
  readonly currentAsset$ = this.select(({ currentAsset }) => currentAsset);

  /**
   * Current Loaded Asset.
   * The current asset data. Filtered to only emit data when the status is success.
   */
  readonly currentLoadedAsset$ = this.currentAsset$.pipe(filterSuccess());

  /**
   * Asset.
   * The current asset. Filtered to only emit data when the status is success.
   */
  readonly asset$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.asset;
    }),
  );

  /**
   * Project.
   * The current assets project. Filtered to only emit data when the status is success.
   */
  readonly project$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.project;
    }),
  );

  /**
   * Collections.
   * The current assets collections. Filtered to only emit data when the status is success.
   */
  readonly collections$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.collections;
    }),
  );

  /**
   * Benchmark.
   * The current assets benchmark assessment. Filtered to only emit data when the status is success.
   */
  readonly benchmark$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.benchmark;
    }),
  );

  /**
   * Operational.
   * The current assets high level operational assessment. Filtered to only emit data when the status is success.
   */
  readonly operational$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.operational;
    }),
  );

  /**
   * Embodied.
   * The current assets high level embodied assessment. Filtered to only emit data when the status is success.
   */
  readonly embodied$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.embodied;
    }),
  );

  /**
   * Energy Model.
   * The current assets detailed energy model assessment. Filtered to only emit data when the status is success.
   */
  readonly energyModel$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.energyModel;
    }),
  );

  /**
   * Detailed.
   * The current assets detailed assessment. Filtered to only emit data when the status is success.
   */
  readonly detailed$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.detailed;
    }),
  );

  /**
   * Combined Detailed.
   * The current assets detailed assessment with the energy model data merged in. Filtered to only emit data when the status is success.
   */
  readonly combinedDetailed$ = combineLatest([
    this.energyModel$,
    this.detailed$,
  ]).pipe(
    map(([energyModel, detailed]) => {
      return this.energyModelService.overrideDetailedAssessmentWithEnergyModel(
        energyModel,
        detailed,
      );
    }),
  );

  /**
   * Bar Chart Data.
   * The current assets bar chart data. Filtered to only emit data when the status is success.
   */
  readonly barChartData$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.barChartData;
    }),
  );

  /**
   * Donut Chart Data.
   * The current assets donut chart data. Filtered to only emit data when the status is success.
   */
  readonly donutChartData$ = this.currentLoadedAsset$.pipe(
    map((currentAsset) => {
      return currentAsset.data.donutChartData;
    }),
  );

  /**
   * Systems Covered By High Level Assessments.
   * The systems covered by the high level assessments for the current asset.
   * Filtered to only emit data when the status is success.
   */
  readonly systemsCoveredByHighLevelAssessments$ = combineLatest([
    this.asset$,
    this.embodied$,
    this.operational$,
  ]).pipe(
    map(([asset, embodied, operational]) => {
      return new Set(
        asset.systems.filter((system) =>
          [
            ...(embodied?.systems?.map((s) => s.system_name) ?? []),
            ...this.operationalService.systemsCovered(operational),
          ].includes(system.name),
        ),
      );
    }),
  );

  /**
   * Systems Covered By Detailed Assessments.
   * The systems covered by the detailed assessments for the current asset.
   * Filtered to only emit data when the status is success.
   */
  readonly systemsCoveredByDetailedAssessments$ = combineLatest([
    this.asset$,
    this.combinedDetailed$,
  ]).pipe(
    map(([asset, combinedDetailed]) => {
      return new Set(
        asset.systems.filter((system) =>
          (
            combinedDetailed?.systems.map((system) => system.system_name) ?? []
          ).includes(system.name),
        ),
      );
    }),
  );

  /**
   * Systems Covered By Assessments.
   * The systems covered by the current assets assessments. Filtered to only emit data when the status is success.
   */
  readonly systemsCoveredByAssessments$ = combineLatest([
    this.asset$,
    this.embodied$,
    this.operational$,
    this.combinedDetailed$,
  ]).pipe(
    map(([asset, embodied, operational, combinedDetailed]) => {
      return new Set(
        asset.systems.filter((system) =>
          new Set([
            ...new Set(
              asset.systems.filter((system) =>
                [
                  ...(embodied?.systems?.map((s) => s.system_name) ?? []),
                  ...this.operationalService.systemsCovered(operational),
                ].includes(system.name),
              ),
            ),
            ...new Set(
              asset.systems.filter((system) =>
                (
                  combinedDetailed?.systems.map(
                    (system) => system.system_name,
                  ) ?? []
                ).includes(system.name),
              ),
            ),
          ]).has(system),
        ),
      );
    }),
  );

  constructor(
    private readonly authStore: AuthStore,
    private readonly assetService: AssetService,
    private readonly systemService: SystemService,
    private readonly siteService: SiteService,
    private readonly collectionService: CollectionService,
    private readonly projectService: ProjectService,
    private readonly benchmarkService: BenchmarkService,
    private readonly operationalService: OperationalService,
    private readonly embodiedService: EmbodiedService,
    private readonly energyModelService: EnergyModelService,
    private readonly detailedService: DetailedService,
    private readonly chartService: ChartService,
    private readonly dialog: MatDialog,
    private readonly loadingModalService: LoadingModalService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
  ) {
    super({ currentAsset: { status: 'idle' } });
  }

  /**
   * Import Asset.
   * Opens a loading modal and then imports an asset from DDB with the given
   * asset information. Then assigns the imported asset to the assigned users
   * and updates the scope of the systems. If any requests fail then the loading
   * modal is closed and an error modal is opened. If all requests succeed then
   * the loading modal is closed and the user is redirected to the asset page.
   */
  readonly importAsset = this.effect<
    [
      ProjectModel,
      PlaceResultModel,
      AssetImportableModel,
      ValuesOf<ImportFormControl>,
    ]
  >((event$) =>
    event$.pipe(
      withLatestFrom(this.authStore.user$.pipe(filterNullish())),
      switchMap(([[project, place, importableAsset, formValue], user]) => {
        const loadingModalOperation = Symbol();
        this.loadingModalService.open({ operation: loadingModalOperation });
        return this.assetService
          .createAsset({
            active: formValue.details!.active!,
            name: importableAsset.name,
            type: formValue.details!.asset_type!,
            above_stories: formValue.details!.above_stories!,
            below_stories: formValue.details!.below_stories!,
            confidential: formValue.access!.confidential!,
            construction_type: formValue.details!.construction_type!,
            description: formValue.details!.description!,
            design_lifespan: formValue.details!.design_lifespan!,
            expected_construction_date: formValue
              .details!.expected_construction_date!.toDate()
              .toString(),
            facade_area: formValue.details!.facade_area!,
            functions: formValue.details!.functions!
              .functions as AssetFunctionModel[],
            above_grade_gross_area: formValue.details!.above_ground_area!,
            below_grade_gross_area: formValue.details!.below_ground_area!,
            address: formValue.details!.address!.Text,
            iso_code: place.Place.Country,
            project_number: project.project_number,
            status: formValue.status!.status!,
            longitude: place.Place.Geometry.Point[0],
            latitude: place.Place.Geometry.Point[1],
            contributor_notes: formValue.access!.contributor_notes!,
            detailed_notes: null,
            embodied_notes: null,
            operational_notes: null,
            energy_model_notes: null,
            detailed_compliance: null,
            energy_model_compliance: null,
            building_ref_id: importableAsset.ddb_ref_id,
          })
          .pipe(
            this._handleCreatedAsset(user, formValue, loadingModalOperation),
          );
      }),
    ),
  );

  /**
   * Create Asset.
   * Opens a loading modal and then creates an asset with the given asset
   * information. Then assigns the created asset to the assigned users and
   * updates the scope of the systems. If any requests fail then the loading
   * modal is closed and an error modal is opened. If all requests succeed then
   * the loading modal is closed and the user is redirected to the asset page.
   */
  readonly createAsset = this.effect<
    [
      ProjectModel,
      SiteModel | SiteUploadModel,
      PlaceResultModel,
      ValuesOf<CreateFormControlsModel>,
    ]
  >((event$) =>
    event$.pipe(
      withLatestFrom(this.authStore.user$.pipe(filterNullish())),
      switchMap(([[project, site, place, formValue], user]) => {
        const loadingModalOperation = Symbol();
        this.loadingModalService.open({ operation: loadingModalOperation });
        return this.siteService.ensureSiteExists(site).pipe(
          switchMap((site) => {
            return this.assetService.createAsset({
              active: formValue.details!.active!,
              name: formValue.details!.asset_name!,
              type: formValue.details!.asset_type!,
              above_stories: formValue.details!.above_stories!,
              below_stories: formValue.details!.below_stories!,
              confidential: formValue.access!.confidential!,
              construction_type: formValue.details!.construction_type!,
              description: formValue.details!.description!,
              design_lifespan: formValue.details!.design_lifespan!,
              expected_construction_date: formValue
                .details!.expected_construction_date!.toDate()
                .toString(),
              facade_area: formValue.details!.facade_area!,
              functions: formValue.details!.functions!
                .functions as AssetFunctionModel[],
              above_grade_gross_area: formValue.details!.above_ground_area!,
              below_grade_gross_area: formValue.details!.below_ground_area!,
              address: formValue.details!.address!.Text,
              iso_code: place.Place.Country,
              project_number: project.project_number,
              status: formValue.status!.status!,
              longitude: place.Place.Geometry.Point[0],
              latitude: place.Place.Geometry.Point[1],
              contributor_notes: formValue.access!.contributor_notes!,
              detailed_notes: null,
              embodied_notes: null,
              operational_notes: null,
              energy_model_notes: null,
              detailed_compliance: null,
              energy_model_compliance: null,
              site_ref_id: site.ddb_ref_id,
            });
          }),
          this._handleCreatedAsset(user, formValue, loadingModalOperation),
        );
      }),
    ),
  );

  /**
   * Handle Created Asset.
   * Creates a collection for each assigned user and updates the scope of the
   * systems. If any request fails then an error modal is opened. If all
   * requests succeed then the user is redirected to the assets profile page.
   */
  private _handleCreatedAsset(
    user: User,
    formValue: ValuesOf<CreateFormControlsModel> | ValuesOf<EditFormControl>,
    loadingModalOperation: Symbol,
  ): OperatorFunction<
    AssetModel,
    [AssetModel, CollectionModel[], SystemModel[]]
  > {
    return (source$) =>
      source$.pipe(
        switchMap((asset) => {
          return forkJoin([
            of(asset),
            this.collectionService.createCollections(
              [
                ...new Set([
                  ...formValue.access!.assigned_users.map((email) =>
                    email.toLowerCase(),
                  ),
                  user.userPrincipalName!.toLowerCase(),
                ]),
              ],
              asset.id,
            ),
            this.systemService.updateSystems(
              formValue.systems!,
              asset.ddb_ref_id,
            ),
          ]);
        }),
        this.loadingModalService.close(loadingModalOperation),
        this.loadingModalService.closeOnError(loadingModalOperation),
        tapResponse(
          ([asset]) => {
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'success',
                title: 'Asset created',
                message: 'Asset has been created successfully.',
              },
            });
            this.router.navigate(['asset', asset.id]);
          },
          (error: HttpErrorResponse) => {
            this.snackBar.openFromComponent(SystemNotificationComponent, {
              ...SYSTEM_NOTIFICATION_CONFIG,
              data: <SystemNotificationData>{
                type: 'error',
                title: 'Failed to create asset',
                message:
                  error.error?.detail ??
                  'An unexpected error occurred while creating asset. Please try again later.',
              },
            });
          },
        ),
      );
  }

  /**
   * Update Asset.
   * Opens a loading modal and then updates an asset with the given asset
   * information. Then assigns the created asset to the assigned users and
   * unassigned the asset from the unassigned users. Then updates the scope of
   * the systems. If any requests fail then the loading modal is closed and an
   * error modal is opened. If all requests succeed then the loading modal is
   * closed and the user is redirected to the asset page.
   */
  readonly updateAsset = this.effect<
    [PlaceResultModel, ValuesOf<EditFormControl>]
  >((event$) =>
    event$.pipe(
      withLatestFrom(this.project$, this.asset$, this.collections$),
      switchMap(([[place, formValue], project, currentAsset, collections]) => {
        const currentEmails = collections.map((collection) =>
          collection.assigned_to.toLowerCase(),
        );
        const assignedUsers = formValue.access!.assigned_users.map((email) =>
          email.toLowerCase(),
        );
        const removedCollections = currentEmails
          .filter((email) => !assignedUsers.includes(email))
          .map((email) =>
            this.collectionService.removeCollection(
              collections.find(
                (collection) => collection.assigned_to.toLowerCase() === email,
              )!.id,
            ),
          );
        const newEmails = assignedUsers.filter(
          (email) => !currentEmails.includes(email),
        );
        const loadingModalOperation = Symbol();
        return this.loadingModalService
          .open({ operation: loadingModalOperation })
          .afterOpened()
          .pipe(
            switchMap(() =>
              forkJoin([
                this.assetService.updateAsset(currentAsset.id, {
                  active: formValue.details!.active!,
                  name: formValue.details!.asset_name!,
                  type: formValue.details!.asset_type!,
                  above_stories: formValue.details!.above_stories!,
                  below_stories: formValue.details!.below_stories!,
                  confidential: formValue.access!.confidential!,
                  construction_type: formValue.details!.construction_type!,
                  description: formValue.details!.description!,
                  design_lifespan: formValue.details!.design_lifespan!,
                  expected_construction_date: formValue
                    .details!.expected_construction_date!.toDate()
                    .toString(),
                  facade_area: formValue.details!.facade_area!,
                  functions: formValue.details!.functions!
                    .functions as AssetFunctionModel[],
                  above_grade_gross_area: formValue.details!.above_ground_area!,
                  below_grade_gross_area: formValue.details!.below_ground_area!,
                  address: formValue.details!.address!.Text,
                  iso_code: place.Place.Country,
                  project_number: project.project_number,
                  status: currentAsset.status,
                  longitude: place.Place.Geometry.Point[0],
                  latitude: place.Place.Geometry.Point[1],
                  contributor_notes: formValue.access!.contributor_notes!,
                }),
                this.systemService.updateSystems(
                  formValue.systems!,
                  currentAsset.ddb_ref_id,
                ),
                ...removedCollections,
                newEmails.length > 0
                  ? this.collectionService.createCollections(
                      newEmails,
                      currentAsset.id,
                    )
                  : of([]),
              ]),
            ),
            this.loadingModalService.close(loadingModalOperation),
            this.loadingModalService.closeOnError(loadingModalOperation),
            tapResponse(
              ([asset]) => {
                this.snackBar.openFromComponent(SystemNotificationComponent, {
                  ...SYSTEM_NOTIFICATION_CONFIG,
                  data: <SystemNotificationData>{
                    type: 'success',
                    title: 'Asset updated',
                    message: 'Asset has been updated successfully.',
                  },
                });
                this.router.navigate(['asset', asset.id]);
              },
              (error: HttpErrorResponse) => {
                this.snackBar.openFromComponent(SystemNotificationComponent, {
                  ...SYSTEM_NOTIFICATION_CONFIG,
                  data: <SystemNotificationData>{
                    type: 'error',
                    title: 'Failed to update asset',
                    message:
                      error.status === HttpStatusCode.Unauthorized ||
                      error.status === HttpStatusCode.Forbidden
                        ? error.error.detail
                        : 'An unexpected error occurred while updating asset. Please try again later.',
                  },
                });
              },
            ),
          );
      }),
    ),
  );

  /**
   * Archive Asset.
   * Opens an archive modal and then updates an asset with the given asset
   * information. If the user confirms the archive then a loading modal is
   * opened and the asset is archived. If any requests fail then the loading
   * modal is closed and an error modal is opened. If all requests succeed then
   * the loading modal is closed and the user is redirected to the collection
   * page.
   */
  readonly archiveAsset = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        return this.dialog
          .open(AcknowledgementModalComponent, {
            ...ACKNOWLEDGEMENT_MODAL_CONFIG,
            data: <AcknowledgementModalData>{
              title: 'Are you sure you want to archive this asset?',
              message:
                'Archived assets will no longer be visible on the Zero platform and associated data will not be used for analysis and reporting.',
              action: {
                confirm: 'Yes, archive asset',
                reject: 'No, go back',
              },
              skippable: false,
            },
          })
          .afterClosed()
          .pipe(
            switchMap((result: AcknowledgementModalResult) => {
              const loadingModalOperation = Symbol();
              return acknowledgementModalResultIsBoolean(result) && result
                ? this.loadingModalService
                    .open({ operation: loadingModalOperation })
                    .afterOpened()
                    .pipe(
                      withLatestFrom(this.asset$),
                      switchMap(([_, asset]) => {
                        return this.assetService
                          .updateAsset(asset.id, {
                            archived: true,
                          })
                          .pipe(
                            this.loadingModalService.close(
                              loadingModalOperation,
                            ),
                            this.loadingModalService.closeOnError(
                              loadingModalOperation,
                            ),
                            tapResponse(
                              () => {
                                this.snackBar.openFromComponent(
                                  SystemNotificationComponent,
                                  {
                                    ...SYSTEM_NOTIFICATION_CONFIG,
                                    data: <SystemNotificationData>{
                                      type: 'success',
                                      title: 'Asset archived',
                                      message:
                                        'The asset has been successfully archived.',
                                    },
                                  },
                                );
                                this.router.navigate(['collection']);
                              },
                              (error: HttpErrorResponse) => {
                                this.snackBar.openFromComponent(
                                  SystemNotificationComponent,
                                  {
                                    ...SYSTEM_NOTIFICATION_CONFIG,
                                    data: <SystemNotificationData>{
                                      type: 'error',
                                      title: 'Failed to archive asset',
                                      message:
                                        error.status ===
                                          HttpStatusCode.Unauthorized ||
                                        error.status ===
                                          HttpStatusCode.Forbidden
                                          ? error.error.detail
                                          : 'An unexpected error occurred while archiving asset. Please try again later.',
                                    },
                                  },
                                );
                              },
                            ),
                          );
                      }),
                    )
                : EMPTY;
            }),
          );
      }),
    ),
  );

  /**
   * Load Asset.
   * Opens a loading modal if not already open and then loads the asset with the
   * given id. If the asset request succeeds, the assets collections, project,
   * benchmark, operational, embodied, energy model, and detailed assessments are
   * loaded. If any of these requests fail, an error modal is opened. If all
   * requests succeed, the user is navigated to the asset page. The assets data
   * is set in state.
   */
  readonly loadAsset = this.effect<number>((id$) =>
    id$.pipe(
      switchMap((id) => {
        const loadingModalOperation = Symbol();
        this.loadingModalService.open(
          { operation: loadingModalOperation },
          true,
        );
        this.patchState((state) => ({
          ...state,
          currentAsset: { ...state.currentAsset, status: 'loading' },
        }));
        return this.loadingModalService
          .open({ operation: loadingModalOperation })
          .afterOpened()
          .pipe(
            switchMap(() => this.assetService.getAsset(id)),
            switchMap((asset) =>
              forkJoin([
                of(asset),
                this.collectionService.getAssetCollections(asset.id),
                this.projectService.getProject(asset.project_number),
                this.benchmarkService.getBenchmark(asset.id),
                this.operationalService.getOperational(asset.id),
                this.embodiedService.getEmbodied(asset.id),
                this.energyModelService.getEnergyModel(asset.id),
                this.detailedService.getDetailed(asset.id),
              ]),
            ),
            this.loadingModalService.close(loadingModalOperation),
            this.loadingModalService.closeOnError(loadingModalOperation),
            tapResponse(
              ([
                asset,
                collections,
                project,
                benchmark,
                operational,
                embodied,
                energyModel,
                detailed,
              ]) => {
                detailed =
                  this.energyModelService.overrideDetailedAssessmentWithEnergyModel(
                    energyModel,
                    detailed,
                  );
                this.patchState({
                  currentAsset: {
                    status: 'success',
                    data: {
                      asset: asset,
                      collections: collections,
                      project: project,
                      benchmark: benchmark,
                      operational: operational,
                      embodied: embodied,
                      energyModel: energyModel,
                      detailed: detailed,
                      barChartData:
                        this.chartService.barChartDataFromAssessments(
                          asset,
                          benchmark,
                          detailed,
                          operational,
                          embodied,
                        ),
                      donutChartData:
                        this.chartService.donutChartDataFromAssessments(
                          asset,
                          benchmark,
                          detailed,
                          operational,
                          embodied,
                        ),
                    },
                  },
                });
              },
              (error: HttpErrorResponse) => {
                this.patchState((state) => ({
                  ...state,
                  currentAsset: {
                    status: 'error',
                    error: error,
                  },
                }));
                this.snackBar.openFromComponent(SystemNotificationComponent, {
                  ...SYSTEM_NOTIFICATION_CONFIG,
                  data: <SystemNotificationData>{
                    type: 'error',
                    title: 'Failed to load asset',
                    message:
                      error.error.detail ??
                      'An unexpected error occurred while loading asset data. Please try again later.',
                  },
                });
              },
            ),
          );
      }),
    ),
  );

  /**
   * Handle Edit Asset.
   * Handles the edit asset button click event. Navigates to the edit asset page
   * for the current asset.
   */
  readonly handleEditAsset = this.effect((event$) =>
    event$.pipe(
      withLatestFrom(this.asset$),
      tap(([_, asset]) => {
        this.router.navigate(['/asset', asset.id, 'edit']);
      }),
    ),
  );

  /**
   * Handle Add Assessment.
   * Handles the add assessment button click event. Navigates to the add assessment
   * page for the current asset.
   */
  readonly handleAddAssessment = this.effect<AssessmentType>(
    (assessmentType$) =>
      assessmentType$.pipe(
        withLatestFrom(this.asset$),
        tap(([assessment, asset]) => {
          this.router.navigate([`${assessment}-assessment`, asset.id]);
        }),
      ),
  );

  /**
   * Handle Open Assessments.
   * Handles the open assessments button click event. Navigates to the
   * current assessments page for the current asset.
   */
  readonly handleOpenAssessments = this.effect((event$) =>
    event$.pipe(
      withLatestFrom(this.asset$),
      tap(([_, asset]) => {
        this.router.navigate(['asset', asset.id, 'assessments']);
      }),
    ),
  );

  /**
   * Handle Open Save Record.
   * Handles the open save record button click event. Navigates to the
   * save record page for the current asset.
   */
  readonly handleOpenSaveRecord = this.effect((event$) =>
    event$.pipe(
      withLatestFrom(this.asset$),
      tap(([_, asset]) => {
        this.router.navigate(['asset', asset.id, 'records', 'save']);
      }),
    ),
  );

  /**
   * Handle Open Submit Record.
   * Handles the open submit record button click event. Navigates to the
   * submit record page for the current asset.
   */
  readonly handleOpenSubmitRecord = this.effect((event$) =>
    event$.pipe(
      withLatestFrom(this.asset$),
      tap(([_, asset]) => {
        this.router.navigate(['asset', asset.id, 'records', 'submit']);
      }),
    ),
  );

  /**
   * Handle Open Update Design Stage.
   * Handles the open update design stage button click event. Opens the update
   * design stage modal. If the modal is closed with the save asset record
   * option, then the user is navigated to the save record page. If the modal is
   * closed with the save design stage option, then the design stage is updated
   * for the current asset. A loading modal is opened while the design stage is
   * being updated.
   */
  readonly handleUpdateDesignStage = this.effect((event$) =>
    event$.pipe(
      withLatestFrom(this.asset$),
      switchMap(([_, asset]) => {
        return this.dialog
          .open(UpdateDesignStageModalComponent, {
            ...UPDATE_DESIGN_STAGE_MODAL_CONFIG,
            data: <UpdateDesignStageModalData>{
              currentDesignStage: asset.status,
            },
          })
          .afterClosed()
          .pipe(
            switchMap((result: UpdateDesignStageModalResult) => {
              const loadingModalOperation = Symbol();
              return iif(
                () => result !== 'cancel',
                this.loadingModalService
                  .open({
                    operation: loadingModalOperation,
                  })
                  .afterOpened()
                  .pipe(
                    switchMap(() =>
                      this.assetService.updateAsset(asset.id, {
                        status: result,
                      }),
                    ),
                    this.loadingModalService.close(loadingModalOperation),
                    this.loadingModalService.closeOnError(
                      loadingModalOperation,
                    ),
                    tapResponse(
                      () => {
                        this.loadAsset(asset.id);
                        this.snackBar.openFromComponent(
                          SystemNotificationComponent,
                          {
                            ...SYSTEM_NOTIFICATION_CONFIG,
                            data: <SystemNotificationData>{
                              type: 'success',
                              title: 'Design phase updated',
                              message: `The design phase was successfully updated.`,
                            },
                          },
                        );
                      },
                      () => {
                        this.snackBar.openFromComponent(
                          SystemNotificationComponent,
                          {
                            ...SYSTEM_NOTIFICATION_CONFIG,
                            data: <SystemNotificationData>{
                              type: 'error',
                              title: 'Failed to update design phase',
                              message:
                                'An unexpected error occurred while updating the design phase. Please try again later.',
                            },
                          },
                        );
                      },
                    ),
                  ),
                EMPTY,
              );
            }),
          );
      }),
    ),
  );
}
