<p>Select campaign:</p>
<zero-loading-select-control
  class="upcoming-campaign"
  label="Upcoming Campaign"
  [options]="campaigns.data ?? []"
  [loading]="campaigns.status === 'loading'"
  [disabled]="campaigns.status !== 'success'"
  [required]="true"
  [formatOption]="formatCampaignOption"
  [formControl]="control"
></zero-loading-select-control>
