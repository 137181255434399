/**
 * Carbon Stage.
 */
export const CARBON_STAGE = {
  A1_A3: 'A1-A3',
  A4: 'A4',
  A5: 'A5',
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
  B4: 'B4',
  B5: 'B5',
  B6: 'B6',
  B7: 'B7',
  C1: 'C1',
  C2: 'C2',
  C3: 'C3',
  C4: 'C4',
  B: 'Bio',
  D: 'D',
} as const;

/**
 * Carbon Stages.
 */
export const CARBON_STAGES: CarbonStage[] = Object.values(CARBON_STAGE);

/**
 * Carbon Stage.
 */
export type CarbonStage = typeof CARBON_STAGE[keyof typeof CARBON_STAGE];
