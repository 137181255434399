import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

/**
 * Injection token for the campaign endpoint.
 */
export const CAMPAIGN_ENDPOINT = new InjectionToken<string>(
  'CAMPAIGN_ENDPOINT',
  {
    factory: () => `${inject(ConfigService).config.apiURL}/campaigns`,
  },
);
