import { Inject, Injectable } from '@angular/core';
import { EmbodiedModel } from '@embodied-assessment/models/embodied.model';
import { SystemName } from '@asset/enums/system-name';
import { HttpService } from '@core/services/http.service';
import { HttpClient } from '@angular/common/http';
import { EmbodiedUploadModel } from '@embodied-assessment/models/embodied-upload.model';
import { UtilsService } from '@core/services/utils.service';
import { Assessment } from '@core/types/assessment';
import { EMBODIED_ENDPOINT } from '@embodied-assessment/tokens/embodied-endpoint.token';
import { ASSESSMENT_TYPE } from '@core/types/assessment-type';

/**
 * Embodied Service.
 * Responsible for embodied assessment related operations.
 */
@Injectable({ providedIn: 'root' })
export class EmbodiedService {
  constructor(
    private readonly httpSerivce: HttpService,
    private readonly utilsService: UtilsService,
    @Inject(EMBODIED_ENDPOINT) private readonly embodiedEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Embodied.
   * Makes HTTP call to API to get embodied assessment for given asset id.
   */
  getEmbodied(assetId: number) {
    return this.http
      .get<EmbodiedModel>(`${this.embodiedEndpoint}/${assetId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpSerivce.openSystemNotification(),
        this.httpSerivce.performAction(),
        this.httpSerivce.catchAssessmentError(ASSESSMENT_TYPE.Embodied),
        this.httpSerivce.redirectForbidden(),
        this.httpSerivce.body(),
        this.httpSerivce.trackError(),
      );
  }

  /**
   * Upload Embodied.
   * Makes HTTP call to API to upload embodied assessment.
   */
  uploadEmbodied(embodied: EmbodiedUploadModel) {
    return this.http
      .put<EmbodiedModel>(
        this.embodiedEndpoint,
        this.utilsService.convertObjectPropsToApiDateFormat(embodied, [
          'assessment_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpSerivce.openSystemNotification(),
        this.httpSerivce.performAction(),
        this.httpSerivce.redirectForbidden(),
        this.httpSerivce.body(),
        this.httpSerivce.trackError(),
      );
  }

  /**
   * Subsystems Covered.
   * Returns an array of subsystem names that are covered by the given embodied assessment.
   */
  subsystemsCovered(
    embodied: Assessment<EmbodiedModel>,
    systemName: SystemName,
  ) {
    return (
      embodied?.systems
        ?.find((system) => system.system_name === systemName)
        ?.subsystems.map((subsystem) => subsystem.subsystem_name) ?? []
    );
  }
}
