import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

/**
 * System Notification Config.
 * Defines the common configuration for the system notification component.
 */
export const SYSTEM_NOTIFICATION_CONFIG: MatSnackBarConfig = {
  verticalPosition: 'top',
};

/**
 * System Notification Data.
 * Defined the data that is passed to the system notification component.
 */
export interface SystemNotificationData {
  /* Notification type. */
  type: 'info' | 'success' | 'warning' | 'error';
  /* Notification title. */
  title: string;
  /* Notification message. */
  message: string;
}

/**
 * System Notification Component.
 * Responsible for displaying a system notification. This component is opened
 * using the MatSnackBar service. The data passed to the component is defined
 * in the SystemNotificationData interface.
 *
 * @example ```
 * this.snackBar.openFromComponent(SystemNotificationComponent, {
 *   ...SYSTEM_NOTIFICATION_CONFIG,
 *   data: <SystemNotificationData>{ ... },
 * });
 * ```
 */
@Component({
  selector: 'zero-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemNotificationComponent {
  constructor(
    private readonly matSnackBarRef: MatSnackBarRef<SystemNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: SystemNotificationData,
  ) {}

  /**
   * Handle Dismiss.
   * Handles the dismiss click event for the notification.
   */
  handleDismiss() {
    this.matSnackBarRef.dismiss();
  }
}
