import { inject, InjectionToken } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

/**
 * System Endpoint.
 * Injection token for the system endpoint.
 */
export const SYSTEM_ENDPOINT = new InjectionToken<string>('SYSTEM_ENDPOINT', {
  factory: () => `${inject(ConfigService).config.apiURL}/systems`,
});
