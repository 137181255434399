import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@core/tokens/window.token';

/**
 * Di token for the isIframe boolean.
 * Represents whether the app is running in an iframe.
 */
export const IS_IFRAME = new InjectionToken<boolean>('Is Iframe', {
  factory: () => {
    const window = inject(WINDOW);
    return window !== window.parent && !window.opener;
  },
});
