import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@material/material.module';
import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { UnderConstructionComponent } from '@core/components/under-construction/under-construction.component';
import { UnauthorizedComponent } from '@core/components/unauthorized/unauthorized.component';
import { MaintenancePageComponent } from '@core/containers/maintenance-page/maintenance-page.component';
import { LocalStorageService } from '@core/services/local-storage.service';
import { PatchFormGroupValueDirective } from '@core/directives/patch-form-group-value.directive';
import { HttpClientModule } from '@angular/common/http';
import { SystemNotificationComponent } from '@core/components/system-notification/system-notification.component';
import { LoadingModalComponent } from '@core/components/loading-modal/loading-modal.component';
import { AcknowledgementModalComponent } from '@core/components/acknowledgement-modal/acknowledgement-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExcelService } from '@core/services/excel.service';
import { LayoutModule } from '@angular/cdk/layout';
import { SmallScreenComponent } from './components/small-screen/small-screen.component';

/**
 * The core module
 *
 * This module contains app level components or shared singleton services
 */
@NgModule({
  declarations: [
    PageNotFoundComponent,
    UnderConstructionComponent,
    UnauthorizedComponent,
    MaintenancePageComponent,
    PatchFormGroupValueDirective,
    SystemNotificationComponent,
    LoadingModalComponent,
    AcknowledgementModalComponent,
    SmallScreenComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    LayoutModule,
  ],
  providers: [LocalStorageService, ExcelService],
  exports: [
    UnderConstructionComponent,
    MaintenancePageComponent,
    PatchFormGroupValueDirective,
    SmallScreenComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
