import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ASSET_STATUSES, AssetStatus } from '@asset/enums/asset-status';

/**
 * Update Design Stage Modal Config.
 * Defines the configuration for the update design stage modal.
 */
export const UPDATE_DESIGN_STAGE_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
};

/**
 * Update Design Stage Modal Data.
 * Defines the data that is passed to the update design stage modal.
 */
export interface UpdateDesignStageModalData {
  /* The current design stage of the asset. */
  currentDesignStage: AssetStatus;
}

/**
 * Update Design Stage Modal Result.
 * Defines the result that is returned from the update design stage modal.
 */
export type UpdateDesignStageModalResult =
  /* The user cancelled the modal. */
  | 'cancel'
  /* The user selected to update design stage. */
  | AssetStatus;

/**
 * Update Design Stage Modal Component.
 * Responsible for rendering the update design stage modal with a form control
 * for selecting a new design stage. The modal will return the new design stage
 * or 'cancel' if the user cancels the modal. If the user selects the 'save
 * asset record' option, the modal will return 'save-asset-record'.
 */
@Component({
  selector: 'zero-update-design-stage-modal',
  templateUrl: './update-design-stage-modal.component.html',
  styleUrls: ['./update-design-stage-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateDesignStageModalComponent implements OnInit {
  /**
   * Design Stage Control.
   * The form control for the design stage.
   */
  readonly designStageControl = new FormControl<AssetStatus | null>(null);

  /**
   * Asset Statuses.
   * All the possible asset statuses.
   */
  readonly assetStatuses = ASSET_STATUSES;

  constructor(
    public readonly dialogRef: MatDialogRef<UpdateDesignStageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: UpdateDesignStageModalData,
  ) {}

  /**
   * On Init.
   * Sets the initial value of the design stage control with the current design
   * stage value from the data passed in to the modal.
   */
  ngOnInit() {
    this.designStageControl.setValue(this.data.currentDesignStage);
  }
}
