/**
 * Assessment Type.
 */
export const ASSESSMENT_TYPE = {
  /* Benchmark assessment. */
  Benchmark: 'benchmark',
  /* High level embodied assessment. */
  Embodied: 'embodied',
  /* High level operational assessment. */
  Operational: 'operational',
  /* Detailed energy model assessment. */
  EnergyModel: 'energy-model',
  /* Detailed assessment. */
  Detailed: 'detailed',
} as const;

/**
 * Assessment Type.
 */
export type AssessmentType =
  typeof ASSESSMENT_TYPE[keyof typeof ASSESSMENT_TYPE];

/**
 * Assessment Type Display.
 * Returns a display string for the given assessment type.
 */
export function assessmentTypeDisplayString(assessmentType: AssessmentType) {
  switch (assessmentType) {
    case ASSESSMENT_TYPE.Benchmark:
      return 'Benchmark';
    case ASSESSMENT_TYPE.Embodied:
      return 'Embodied';
    case ASSESSMENT_TYPE.Operational:
      return 'Operational';
    case ASSESSMENT_TYPE.EnergyModel:
      return 'Energy Model';
    case ASSESSMENT_TYPE.Detailed:
      return 'Detailed';
  }
}
