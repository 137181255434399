import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RegionModel } from '@ads/models/region.model';
import { GroupOfGroupsModel } from '@ads/models/group-of-groups.model';
import { AccountingCentreModel } from '@ads/models/accounting-centre.model';
import { BusinessModel } from '@ads/models/business.model';
import { CampaignProjectModel } from '@project/models/campaign-project.model';
import { CampaignModel } from '@campaign/models/campaign.model';
import {
  ProjectModel,
  ProjectUpdateModel,
  ProjectWithFinancialsModel,
  ProjectWithProgressModel,
} from '@project/models/project.model';
import { PROJECT_ENDPOINT } from '@project/tokens/project-endpoint.token';
import { warn } from 'console';

/**
 * Project Service.
 * Responsible for all project related operations.
 */
@Injectable({ providedIn: 'root' })
export class ProjectService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(PROJECT_ENDPOINT) private readonly projectEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Project.
   * Get a project by project number.
   */
  getProject(projectNumber: ProjectModel['project_number']) {
    return this.http
      .get<ProjectModel>(`${this.projectEndpoint}/${projectNumber}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Update Project.
   * Update a project in the the Zero API.
   */
  updateProject(project: ProjectUpdateModel) {
    return this.http
      .put<ProjectModel>(
        `${this.projectEndpoint}/${project.project_number}`,
        project,
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Update Campaign Project.
   * Update the relationship between a project and a campaign in the Zero API.
   */
  updateCampaignProject(
    project: Pick<ProjectModel, 'project_number'>,
    campaignProject: CampaignProjectModel,
  ) {
    return this.http
      .put<CampaignProjectModel>(
        `${this.projectEndpoint}/${project.project_number}/campaigns/${campaignProject.campaign_id}`,
        campaignProject,
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Search Projects with Progress.
   * Search projects with progress towards the quired campaign given a region, geography, accounting centre and business.
   */
  searchProjectsWithProgres(
    campaign: CampaignModel,
    region: RegionModel,
    groupOfGroups: GroupOfGroupsModel,
    accountingCentre: AccountingCentreModel | null,
    business: BusinessModel | null,
  ) {
    return this.http
      .get<ProjectWithProgressModel[]>(`${this.projectEndpoint}/progress`, {
        params: new HttpParams({
          fromObject: {
            campaign_id: campaign.id,
            region_code: region.RegionCode,
            group_of_groups_code: groupOfGroups.GroupOfGroupsCode,
            ...(business && { business_code: business.BusinessCode }),
            ...(accountingCentre && {
              accounting_centre_code: accountingCentre.AccountingCentreCode,
            }),
          },
        }),
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Search Projects with Financials.
   * Search projects with financials given a region, geography, accounting centre and business.
   */
  searchProjectsWithFinancials(
    region: RegionModel,
    groupOfGroups: GroupOfGroupsModel,
    accountingCentre: AccountingCentreModel | null,
    business: BusinessModel | null,
  ) {
    return this.http
      .get<ProjectWithFinancialsModel[]>(`${this.projectEndpoint}/full`, {
        params: new HttpParams({
          fromObject: {
            region_code: region.RegionCode,
            group_of_groups_code: groupOfGroups.GroupOfGroupsCode,
            ...(business && { business_code: business.BusinessCode }),
            ...(accountingCentre && {
              accounting_centre_code: accountingCentre.AccountingCentreCode,
            }),
          },
        }),
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Calculate Income.
   * Calculate the total income for financial periods within FY 2022/2023 Q1-Q3.
   */
  calculateIncome(project: ProjectWithFinancialsModel) {
    return project.ProjectFinancialPeriods.map(
      (financialPeriod) => financialPeriod.FeesInvoiced ?? 0,
    ).reduce((previousFee, currentFee) => previousFee + currentFee, 0);
  }
}
