import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { HttpClient } from '@angular/common/http';
import { SiteModel } from '@asset/models/site/site.model';
import { SiteUploadModel } from '@asset/models/site-upload/site-upload.model';
import { UtilsService } from '@core/services/utils.service';
import { SITE_ENDPOINT } from '@asset/tokens/site-endpoint.token';
import { of } from 'rxjs';

/**
 * Site Service.
 * Responsible for DDB site related operations.
 */
@Injectable({ providedIn: 'root' })
export class SiteService {
  constructor(
    private readonly httpService: HttpService,
    private readonly utils: UtilsService,
    @Inject(SITE_ENDPOINT) private readonly siteEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Sites.
   * Makes an HTTP call to the API to get sites in DDB for given project number.
   */
  getSites(projectNumber: string) {
    return this.http
      .get<Array<SiteModel>>(`${this.siteEndpoint}/${projectNumber}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Create Site.
   * Makes an HTTP call to the API to create a new site in DDB.
   */
  createSite(site: SiteUploadModel) {
    return this.http
      .post<SiteModel>(this.siteEndpoint, site, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Ensure site exists by checking if site is of type `SiteModel` or
   * `SiteUploadModel` and uses `SiteService.createSite()` to create a
   * new site if the site is not of type `SiteModel`.
   */
  ensureSiteExists(site: SiteModel | SiteUploadModel) {
    if (this.utils.isInstance<SiteModel>(site, ['ddb_ref_id'])) return of(site);
    else return this.createSite(site);
  }
}
