import { Component } from '@angular/core';

/**
 * Carbon Table Cell.
 *
 * Responsible for rending a cell within
 * the carbon parameter table. Provide the
 * cells content with the use of content
 * projection.
 */
@Component({
  selector: 'zero-carbon-table-cell',
  templateUrl: './carbon-table-cell.component.html',
  styleUrls: ['./carbon-table-cell.component.scss'],
})
export class CarbonTableCellComponent {}
