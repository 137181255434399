import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BenchmarkModel } from '@asset/models/benchmark.model';
import { HttpService } from '@core/services/http.service';
import { BENCHMARK_ENDPOINT } from '@asset/tokens/benchmark-endpoint.token';
import { ASSESSMENT_TYPE } from '@core/types/assessment-type';

/**
 * Benchmark Service.
 * Handles all benchmark related operations.
 */
@Injectable({ providedIn: 'root' })
export class BenchmarkService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(BENCHMARK_ENDPOINT) private readonly benchmarkEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Benchmark.
   * Makes an HTTP call to the API to get benchmark assessment for given asset id.
   */
  getBenchmark(assetId: number) {
    return this.http
      .get<BenchmarkModel>(`${this.benchmarkEndpoint}/${assetId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssessmentError(ASSESSMENT_TYPE.Benchmark),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }
}
