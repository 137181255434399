/**
 * Total Carbon Stage.
 */
export const TOTAL_CARBON_STAGE = {
  A1_A5: 'A1-A5',
  B1_B5: 'B1-B5',
  B6_B7: 'B6-B7',
  C1_C4: 'C1-C4',
} as const;

/**
 * Carbon Stages.
 */
export const TOTAL_CARBON_STAGES: TotalCarbonStage[] =
  Object.values(TOTAL_CARBON_STAGE);

/**
 * Total Carbon Stage.
 */
export type TotalCarbonStage =
  typeof TOTAL_CARBON_STAGE[keyof typeof TOTAL_CARBON_STAGE];
