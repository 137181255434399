import { Injectable } from '@angular/core';
import { SubsystemName } from '@core/models/subsystem';
import { SystemName } from '@asset/enums/system-name';
import { CarbonParameter } from '@core/types/carbon-parameter';
import {
  MAPPING_VARIABLE,
  MappingVariable,
} from '@veracity/types/mapping-variable';

/**
 * Service for methods that aren't specifically
 * related to any particular app feature module
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   *
   */
  convertObjectPropsToApiDateFormat<T>(obj: T, keys: Array<keyof T>) {
    (Object.keys(obj) as Array<keyof T>).forEach((key) => {
      if (keys.includes(key)) {
        (obj as any)[key] = new Date((obj as any)[key])
          .toISOString()
          .split('T')[0];
      }
    });
    return obj as T;
  }

  /**
   * Convert string to safe css class name string
   * @param className
   */
  safeCSSClassName(className: string): string {
    return className
      .toString()
      .replace(/&/g, 'and')
      .replace(/ /g, '-')
      .replace(/\//g, '-')
      .toLowerCase()!;
  }

  /**
   * Get abbreviation for assembly mapping variable
   */
  mappingVariableAbbreviation(variable: MappingVariable): string {
    switch (variable) {
      case MAPPING_VARIABLE.Absolute:
        return 'UNITS';
      case MAPPING_VARIABLE.GrossFloorArea:
        return 'GFA';
      case MAPPING_VARIABLE.BelowGroundArea:
        return 'BGA';
      case MAPPING_VARIABLE.AboveGroundArea:
        return 'AGA';
      case MAPPING_VARIABLE.GrossFacadeArea:
        return 'FA';
    }
  }

  /**
   * Get subsystem names for given system
   * @param systemName
   */
  getSubsystemsNames(systemName: SystemName) {
    switch (systemName) {
      case SystemName.SubStructure:
        return [
          SubsystemName.BasementFrame,
          SubsystemName.Foundation,
          SubsystemName.BasementPerimeter,
        ];
      case SystemName.SuperStructure:
        return [
          SubsystemName.Floorplate,
          SubsystemName.VerticalStructure,
          SubsystemName.Stability,
          SubsystemName.Roof,
          SubsystemName.Stairs,
        ];
      case SystemName.MechanicalServices:
        return [
          SubsystemName.Heating,
          SubsystemName.Cooling,
          SubsystemName.Ventilation,
          SubsystemName.Process,
        ];
      case SystemName.ElectricalServices:
        return [
          SubsystemName.Electrical,
          SubsystemName.Telecomms,
          SubsystemName.Lighting,
          SubsystemName.Renewables,
          SubsystemName.VerticalTransport,
          SubsystemName.Security,
        ];
      case SystemName.PublicHealthAndHydraulics:
        return [
          SubsystemName.HotWater,
          SubsystemName.ColdWater,
          SubsystemName.WasteWater,
          SubsystemName.Fire,
          SubsystemName.Fuel,
          SubsystemName.Specialist,
        ];
      case SystemName.BuildingEnvelope:
        return [SubsystemName.Facade, SubsystemName.RoofFinishes];
      case SystemName.SpacePlan:
        return [
          SubsystemName.Partitions,
          SubsystemName.FloorFinishes,
          SubsystemName.CeilingFinishes,
          SubsystemName.WallFinishes,
          SubsystemName.ArchitecturalMetalwork,
        ];
    }
  }

  /**
   * Filter an array of items
   * @param items Items to filter
   * @param term search term to filter by
   * @param excludes any item properties to be excluded from filtering
   * @returns array of filtered items
   */
  filter(
    items: Array<{ [key: string]: any }>,
    term: string | null | undefined,
    excludes: any = [],
  ): Array<{ [key: string]: any }> {
    if (!term || !items) return items;
    const toCompare = term.toLowerCase();

    const checkItem = (item: any, term: string) => {
      if (
        typeof item === 'string' &&
        item.toString().toLowerCase().includes(toCompare)
      ) {
        return true;
      }

      for (let property in item) {
        if (
          item[property] === null ||
          item[property] == undefined ||
          excludes.includes(property)
        ) {
          continue;
        }
        // special conditions for confidential/public
        // and active/inactive
        // search terms as condition property is a
        // boolean value
        // confidential
        if (
          property === 'confidential' &&
          'confidential'.includes(toCompare) &&
          item[property]
        ) {
          return true;
        }
        // public
        if (
          property === 'confidential' &&
          'public'.includes(toCompare) &&
          !item[property]
        ) {
          return true;
        }
        // active
        if (
          property === 'active' &&
          'active'.includes(toCompare) &&
          item[property]
        ) {
          return true;
        }
        // inactive
        if (
          property === 'active' &&
          toCompare.startsWith('in') &&
          !item[property]
        ) {
          return true;
        }
        // all other properties
        if (typeof item[property] === 'object') {
          if (checkItem(item[property], term)) {
            return true;
          }
        } else if (
          item[property].toString().toLowerCase().includes(toCompare)
        ) {
          return true;
        }
      }
      return false;
    };

    const filteredItems = items.filter(function (item) {
      return checkItem(item, term);
    });
    return filteredItems;
  }

  /**
   * Sort an array of objects by a boolean property
   * @param values objects to sort
   * @param column object key to sort by
   * @param order order of array.  'asc' will order false values first
   * @param parentKey parent key if the object has nested properties
   * @returns ordered array
   */
  sortByBoolean<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      if (aValue === undefined || bValue === undefined) return 0;
      if (aValue === bValue) return 0;
      return aValue < bValue ? -1 : 1;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  /**
   * Sort an array of objects by a string property
   * @param values objects to sort
   * @param column object key to sort by
   * @param order order of array.  'asc' will order alpabetically
   * @param parentKey parent key if the object has nested properties
   * @returns ordered array
   */
  sortByString<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      if (aValue === undefined || bValue === undefined) return 0;
      if (aValue === bValue) return 0;
      return aValue < bValue ? -1 : 1;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  /**
   * Sort an array of objects by a string property
   * @param values objects to sort
   * @param column object key to sort by
   * @param order order of array.  'asc' will order alpabetically
   * @param parentKey parent key if the object has nested properties
   * @returns ordered array
   */
  sortByDate<T extends Record<string, any>>(
    values: Array<T>,
    column: string,
    order: 'asc' | 'dsc',
    parentKey?: string[],
  ) {
    const sortedValues = values.sort((a, b) => {
      const aValue: string = parentKey
        ? this.getNestedProperty(a, parentKey)[column]
        : a[column];
      const bValue: string = parentKey
        ? this.getNestedProperty(b, parentKey)[column]
        : b[column];
      const aDate = new Date(aValue).getTime();
      const bDate = new Date(bValue).getTime();
      if (
        aValue === undefined ||
        aDate === undefined ||
        bValue === undefined ||
        bDate === undefined
      )
        return 0;
      return bDate - aDate;
    });
    return order === 'asc' ? sortedValues : sortedValues.reverse();
  }

  /**
   * Get nested property
   * @param object
   * @param prop
   * @private
   */
  private getNestedProperty(object: any, prop: string[]) {
    return prop.reduce(
      (object: any, key: string) =>
        object && object[key] !== 'undefined' ? object[key] : undefined,
      object,
    );
  }

  /**
   * Check is object is instance of Type `T`.
   */
  isInstance<T>(obj: T | unknown, keys: Array<keyof T>): obj is T {
    for (const key of keys) {
      if ((obj as T)[key] === undefined) return false;
    }
    return true;
  }

  /**
   * Check if value is defined (not null or undefined)
   */
  isDefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
  }

  /**
   * Overrider the full carbon parameter set with
   * provided total carbon parameters.
   */
  carbonParameters(totalParameters: Array<CarbonParameter> = []) {
    return new Set([
      ...new Set(
        totalParameters.includes(CarbonParameter.A1_A5)
          ? [CarbonParameter.A1_A5]
          : [CarbonParameter.A1_A3, CarbonParameter.A4, CarbonParameter.A5],
      ),
      ...new Set(
        totalParameters.includes(CarbonParameter.B1_B5)
          ? [CarbonParameter.B1_B5]
          : [
              CarbonParameter.B1,
              CarbonParameter.B2,
              CarbonParameter.B3,
              CarbonParameter.B4,
              CarbonParameter.B5,
            ],
      ),
      ...new Set(
        totalParameters.includes(CarbonParameter.B6_B7)
          ? [CarbonParameter.B6_B7]
          : [CarbonParameter.B6, CarbonParameter.B7],
      ),
      ...new Set(
        totalParameters.includes(CarbonParameter.C1_C4)
          ? [CarbonParameter.C1_C4]
          : [
              CarbonParameter.C1,
              CarbonParameter.C2,
              CarbonParameter.C3,
              CarbonParameter.C4,
            ],
      ),
      CarbonParameter.B,
      CarbonParameter.D,
    ]);
  }

  /**
   * pull css variable out for use in ts
   */
  getCSSVar(varName: string) {
    const style = getComputedStyle(document.body);
    return style.getPropertyValue(varName);
  }
}
