import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  DoBootstrap,
  NgModule,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth/auth.module';
import { CoreModule } from '@core/core.module';
import { ConfigService } from '@core/services/config.service';
import { MonitoringModule } from '@monitoring/monitoring.module';
import { MgtModule } from '@mgt/mgt.module';
import { BrowserUtils } from '@azure/msal-browser';
import { Integrations } from '@sentry/tracing';
import {
  popperVariation,
  TippyModule,
  tooltipVariation,
} from '@ngneat/helipopper';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { environment } from '../environments/environment';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import * as Sentry from '@sentry/angular';
import '@arc-web/components';

/**
 * Zero Root Application Module.
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,
    MonitoringModule,
    MgtModule,
    MaterialModule,
    SharedModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    RouterModule.forRoot(APP_ROUTES, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: ApplicationRef) {
    // Load runtime configuration.
    ConfigService.load().then((config) => {
      // Initialize Sentry.
      Sentry.init({
        dsn: config.sentryDSN,
        tracesSampleRate: 0.1,
        sampleRate: 0.1,
        release: environment.appVersion,
        environment: config.env,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: config.sentryTracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
      });
      // Bootstrap the application.
      appRef.bootstrap(AppComponent);
    });
  }
}
