import { inject, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Di token for the window object.
 */
export const WINDOW = new InjectionToken<Window>('Window', {
  factory: () => {
    const { defaultView } = inject(DOCUMENT);
    if (!defaultView) throw new Error('Window is not available');
    return defaultView;
  },
});
