import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { SystemModel } from '@asset/models/system/system.model';
import { AssetModel } from '@asset/models/asset.model';
import { SYSTEM_ENDPOINT } from '@system/tokens/system-endpoint.token';

/**
 * System Service.
 * Responsible for handling all system related operations.
 */
@Injectable({ providedIn: 'root' })
export class SystemService {
  constructor(
    private readonly httpService: HttpService,
    @Inject(SYSTEM_ENDPOINT) private readonly systemEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Update systems.
   * Update system for a given asset ddb_ref_id.
   */
  updateSystems(
    systems: Array<Omit<SystemModel, 'ddb_ref_id'>>,
    assetDdbRefId: AssetModel['ddb_ref_id'],
  ) {
    return this.http
      .put<Array<SystemModel>>(
        `${this.systemEndpoint}/${assetDdbRefId}`,
        systems,
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * System Scope.
   * Check if system is in Arup scope for given asset and system name.
   */
  systemScope(asset: AssetModel, systemName: SystemModel['name']) {
    const system = asset.systems.find((s) => s.name === systemName);
    return system ? system.scope : false;
  }
}
