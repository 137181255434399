import { Inject, Injectable } from '@angular/core';
import {
  LocalStorageKey,
  LocalStorageDataKey,
} from '@core/types/local-storage-key';
import { LOCAL_STORAGE } from '@core/tokens/local-storage.token';

/**
 * Local Storage Service.
 * Responsible for interacting with the local
 * storage object and provides a typed interface.
 */
@Injectable()
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private localStorage: Storage) {}

  /**
   * Get item from local storage.
   */
  getItem<T>(key: LocalStorageKey | LocalStorageDataKey) {
    const data = localStorage.getItem(key);
    return (data ? JSON.parse(data) : data) as T;
  }

  /**
   * Set item in local storage.
   */
  setItem(key: LocalStorageKey | LocalStorageDataKey, data: unknown) {
    localStorage.setItem(key, JSON.stringify(data));
  }
}
