import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material/material.module';
import { NgChartsModule } from 'ng2-charts';
import { TippyModule } from '@ngneat/helipopper';
import { SystemScopePipe } from '@shared/pipes/system-scope.pipe';
import { AssignedUsersLengthFilterPipe } from '@shared/pipes/assigned-users-length-filter.pipe';
import { AssetCardComponent } from '@shared/components/asset-card/asset-card.component';
import { CreateAssetCardComponent } from '@shared/components/create-asset-card/create-asset-card.component';
import { PeoplePickerComponent } from '@shared/components/people-picker/people-picker.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { CarbonStageChartComponent } from '@shared/components/carbon-stage-chart/carbon-stage-chart.component';
import { AssemblyModalComponent } from '@shared/components/assembly-modal/assembly-modal.component';
import { ProjectNumberPipe } from '@shared/pipes/project-number.pipe';
import { ReadMoreComponent } from '@shared/components/read-more/read-more.component';
import { SystemChipComponent } from '@shared/components/system-chip/system-chip.component';
import { ExternalLinkComponent } from '@shared//components/external-link/external-link.component';
import { AssetImportCardComponent } from '@shared/components/asset-import-card/asset-import-card.component';
import { CarbonTableHeaderComponent } from '@shared/components/carbon-table-header/carbon-table-header.component';
import { CarbonTableComponent } from '@shared/components/carbon-table/carbon-table.component';
import { CarbonTableRowComponent } from '@shared/components/carbon-table-row/carbon-table-row.component';
import { CarbonTableCellComponent } from '@shared/components/carbon-table-cell/carbon-table-cell.component';
import { CarbonTableCellsComponent } from '@shared/components/carbon-table-cells/carbon-table-cells.component';
import { WarningComponent } from './components/warning/warning.component';
import { RequiredFieldsComponent } from '@shared/components/required-fields/required-fields.component';
import { FormHelpComponent } from './components/form-help/form-help.component';
import { CreateAssessmentComponent } from './components/create-assessment/create-assessment.component';
import { TotalParameterIconComponent } from './components/total-parameter-icon/total-parameter-icon.component';
import { TagComponent } from './components/tag/tag.component';
import { KeyComponent } from '@shared/components/key/key.component';
import { LoadingSelectControlComponent } from '@shared/components/loading-select-control/loading-select-control.component';
import { CampaignControlComponent } from '@shared/components/campaign-control/campaign-control.component';
import { StatusPipe } from '@shared/pipes/status.pipe';
import { ExcelUploadModalComponent } from './components/excel-upload-modal/excel-upload-modal.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CopyToClipboardComponent } from '@shared/components/copy-to-clipboard/copy-to-clipboard.component';

/**
 * Shared Components.
 * Components that are used in multiple modules in the application.
 */
const SHARED_COMPONENTS: Type<any>[] = [
  AssetCardComponent,
  CreateAssetCardComponent,
  AssetImportCardComponent,
  PeoplePickerComponent,
  PageHeaderComponent,
  CarbonStageChartComponent,
  AssemblyModalComponent,
  ReadMoreComponent,
  SystemChipComponent,
  ExternalLinkComponent,
  CarbonTableComponent,
  CarbonTableRowComponent,
  CarbonTableHeaderComponent,
  CarbonTableCellComponent,
  CarbonTableCellsComponent,
  WarningComponent,
  RequiredFieldsComponent,
  FormHelpComponent,
  CreateAssessmentComponent,
  TotalParameterIconComponent,
  TagComponent,
  KeyComponent,
  LoadingSelectControlComponent,
  CampaignControlComponent,
  ExcelUploadModalComponent,
  CheckboxComponent,
  CopyToClipboardComponent,
];

/**
 * Shared Pipes.
 * Pipes that are used in multiple modules.
 */
const SHARED_PIPES: Type<any>[] = [
  AssignedUsersLengthFilterPipe,
  ProjectNumberPipe,
  SystemScopePipe,
  StatusPipe,
  OrderByPipe,
];

/**
 * Shared module.
 * Responsible for providing shared components, pipes, directives, and services
 * to other modules in the application.
 */
@NgModule({
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  exports: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  imports: [
    CommonModule,
    MaterialModule,
    NgChartsModule,
    TippyModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
