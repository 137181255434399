import { Component } from '@angular/core';

/**
 * Carbon Table Row Component.
 *
 * Responsible for rending a row for a carbon
 * parameter table. Content for the row is provided
 * with the use of content projection.
 */
@Component({
  selector: 'zero-carbon-table-row',
  templateUrl: './carbon-table-row.component.html',
  styleUrls: ['./carbon-table-row.component.scss'],
})
export class CarbonTableRowComponent {}
