import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatRipple } from '@angular/material/core';

/**
 * Copy to Clipboard.
 *
 * Responsible for rending a copy to clipboard link. This component uses the
 * Angular CDK Clipboard service to copy text to the clipboard. The text to
 * copy to the clipboard is passed in as an input and the user can click on
 * the link to copy the text to the clipboard. The link text is provided by
 * content projection.
 */
@Component({
  selector: 'zero-copy-to-clipboard[text]',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {
  /**
   * Text.
   *
   * The text to copy to the clipboard.
   */
  @Input() text!: string;

  /**
   * Ripple.
   *
   * The ripple directive. Used to launch the ripple effect when the user
   * clicks on the link.
   */
  @ViewChild(MatRipple) ripple!: MatRipple;

  constructor(private readonly clipboard: Clipboard) {}

  /**
   * Handle click.
   *
   * When the user clicks on the link, the text is copied to the clipboard.
   */
  @HostListener('click')
  handleClick() {
    this.clipboard.copy(this.text);
    this.ripple.launch({});
  }
}
