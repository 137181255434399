import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DeviceType } from '@core/types/device-type';
import { DEVICE_TYPE } from '@core/tokens/device-type.token';

/**
 * Small Screen Component.
 * Responsible for rendering the small screen usage message. This
 * message is dependent on the users device type.
 */
@Component({
  selector: 'zero-small-screen',
  templateUrl: './small-screen.component.html',
  styleUrls: ['./small-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallScreenComponent {
  constructor(@Inject(DEVICE_TYPE) public readonly deviceType: DeviceType) {}
}
