import { Inject, Injectable } from '@angular/core';
import { OperationalModel } from '@operational-assessment/models/operational/operational.model';
import { SystemName } from '@asset/enums/system-name';
import { HttpClient } from '@angular/common/http';
import { OperationalUploadModel } from '@operational-assessment/models/operational-upload/operational-upload.model';
import { UtilsService } from '@core/services/utils.service';
import { Assessment } from '@core/types/assessment';
import { OPERATIONAL_ENDPOINT } from '@operational-assessment/tokens/operational-endpoint.token';
import { ASSESSMENT_TYPE } from '@core/types/assessment-type';
import { HttpService } from '@core/services/http.service';

/**
 * Operational Service.
 * Responsible for handling all operational assessment related operations.
 */
@Injectable({ providedIn: 'root' })
export class OperationalService {
  constructor(
    private readonly httpService: HttpService,
    private readonly utilsService: UtilsService,
    @Inject(OPERATIONAL_ENDPOINT) private readonly operationalEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Get Operational.
   * Makes API call to get operational assessment for given asset id.
   */
  getOperational(assetId: number) {
    return this.http
      .get<OperationalModel>(`${this.operationalEndpoint}/${assetId}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssessmentError(ASSESSMENT_TYPE.Operational),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Upload Operational.
   * Makes API call to upload operational assessment.
   */
  uploadOperational(operational: OperationalUploadModel) {
    return this.http
      .put<OperationalModel>(
        this.operationalEndpoint,
        this.utilsService.convertObjectPropsToApiDateFormat(operational, [
          'assessment_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Systems Covered.
   * Returns array of system names covered in operational assessment.
   */
  systemsCovered(operational: Assessment<OperationalModel>) {
    return operational === null
      ? []
      : [
          SystemName.MechanicalServices,
          SystemName.ElectricalServices,
          SystemName.PublicHealthAndHydraulics,
        ];
  }
}
