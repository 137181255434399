<h1 mat-dialog-title>Excel Upload</h1>
<div mat-dialog-content>
  <zero-form-help>1. Download Excel template</zero-form-help>
  <a
    mat-flat-button
    color="primary"
    name="download-template"
    aria-label="Download Excel Template"
    [download]="data.excelTemplateFileName"
    [href]="data.excelTemplateUrl"
  >
    Download Template
  </a>
  <zero-form-help>2. Edit your excel file</zero-form-help>
  <zero-form-help>3. Upload the Excel file</zero-form-help>
  <mat-form-field
    (dragenter)="$event.preventDefault()"
    (dragover)="$event.preventDefault()"
    (dragleave)="$event.preventDefault()"
    (drop)="handleExcelFileDrop($event)"
  >
    <mat-label>Excel File</mat-label>
    <input
      type="text"
      readonly
      matInput
      #excelFileInputText
      (click)="excelFileInput.click()"
    />
    <input
      type="file"
      accept=".ods"
      hidden
      #excelFileInput
      (change)="handleExcelFileInputChange($event)"
    />
    <button mat-icon-button matSuffix (click)="excelFileInput.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </mat-form-field>
  <!--  <zero-external-link href="https://docs.zero.arup.com"-->
  <!--    >Learn more about excel uploads</zero-external-link-->
  <!--  >-->
</div>
<div mat-dialog-actions>
  <button
    mat-stroked-button
    name="cancel"
    aria-label="Cancel Upload"
    (click)="handleCancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="accent"
    name="upload"
    aria-label="Upload Excel File"
    [disabled]="!excelFile"
    (click)="handleUpload()"
  >
    Upload
  </button>
</div>
