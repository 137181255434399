import {
  TOTAL_CARBON_STAGE,
  TotalCarbonStage,
} from '@core/types/total-carbon-stage';
import { CARBON_STAGE, CarbonStage } from '@core/types/carbon-stage';
import {
  DetailedParameterName,
  DETAILED_PARAMETER_NAME,
} from '@detailed-assessment/types/detailed-paramter-name';

/**
 * Carbon Parameter.
 */
export class CarbonParameter {
  /* Carbon parameter for stage A1-A3. */
  static readonly A1_A3 = new CarbonParameter(
    CARBON_STAGE.A1_A3,
    'a1a2a3',
    DETAILED_PARAMETER_NAME.A1_A3,
  );
  /* Carbon parameter for stage A4. */
  static readonly A4 = new CarbonParameter(
    CARBON_STAGE.A4,
    'a4',
    DETAILED_PARAMETER_NAME.A4,
  );
  /* Carbon parameter for stage A5. */
  static readonly A5 = new CarbonParameter(
    CARBON_STAGE.A5,
    'a5',
    DETAILED_PARAMETER_NAME.A5,
  );
  /**
   * Rolled total carbon parameters for stages:
   * A1-A3, A4 and A5. Submitting an intertidal
   * carbon parameter will invalidate and override
   * this total.
   */
  static readonly A1_A5 = new CarbonParameter(
    TOTAL_CARBON_STAGE.A1_A5,
    'a1_a5',
    DETAILED_PARAMETER_NAME.A1_A5,
  );
  /* Carbon parameter for stage B1. */
  static readonly B1 = new CarbonParameter(
    CARBON_STAGE.B1,
    'b1',
    DETAILED_PARAMETER_NAME.B1,
  );
  /* Carbon parameter for stage B2. */
  static readonly B2 = new CarbonParameter(
    CARBON_STAGE.B2,
    'b2',
    DETAILED_PARAMETER_NAME.B2,
  );
  /* Carbon parameter for stage B3. */
  static readonly B3 = new CarbonParameter(
    CARBON_STAGE.B3,
    'b3',
    DETAILED_PARAMETER_NAME.B3,
  );
  /* Carbon parameter for stage B4. */
  static readonly B4 = new CarbonParameter(
    CARBON_STAGE.B4,
    'b4',
    DETAILED_PARAMETER_NAME.B4,
  );
  /* Carbon parameter for stage B5. */
  static readonly B5 = new CarbonParameter(
    CARBON_STAGE.B5,
    'b5',
    DETAILED_PARAMETER_NAME.B5,
  );
  /**
   * Rolled total carbon parameters for stages:
   * B1, B2, B3, B4 and B5. Submitting an intertidal
   * carbon parameter will invalidate and override
   * this total.
   */
  static readonly B1_B5 = new CarbonParameter(
    TOTAL_CARBON_STAGE.B1_B5,
    'b1_b5',
    DETAILED_PARAMETER_NAME.B1_B5,
  );
  /* Carbon parameter for stage B6. */
  static readonly B6 = new CarbonParameter(
    CARBON_STAGE.B6,
    'b6',
    DETAILED_PARAMETER_NAME.B6,
  );
  /* Carbon parameter for stage B7. */
  static readonly B7 = new CarbonParameter(
    CARBON_STAGE.B7,
    'b7',
    DETAILED_PARAMETER_NAME.B7,
  );
  /**
   * Rolled total carbon parameters for stages:
   * B6 and B7. Submitting an intertidal
   * carbon parameter will invalidate and override
   * this total.
   */
  static readonly B6_B7 = new CarbonParameter(
    TOTAL_CARBON_STAGE.B6_B7,
    'b6_b7',
    DETAILED_PARAMETER_NAME.B6_B7,
  );
  /* Carbon parameter for stage C1. */
  static readonly C1 = new CarbonParameter(
    CARBON_STAGE.C1,
    'c1',
    DETAILED_PARAMETER_NAME.C1,
  );
  /* Carbon parameter for stage C2. */
  static readonly C2 = new CarbonParameter(
    CARBON_STAGE.C2,
    'c2',
    DETAILED_PARAMETER_NAME.C2,
  );
  /* Carbon parameter for stage C3. */
  static readonly C3 = new CarbonParameter(
    CARBON_STAGE.C3,
    'c3',
    DETAILED_PARAMETER_NAME.C3,
  );
  /* Carbon parameter for stage C4. */
  static readonly C4 = new CarbonParameter(
    CARBON_STAGE.C4,
    'c4',
    DETAILED_PARAMETER_NAME.C4,
  );
  /**
   * Rolled total carbon parameters for stages:
   * C1, C2, C3 and C4. Submitting an intertidal
   * carbon parameter will invalidate and override
   * this total.
   */
  static readonly C1_C4 = new CarbonParameter(
    TOTAL_CARBON_STAGE.C1_C4,
    'c1_c4',
    DETAILED_PARAMETER_NAME.C1_C4,
  );
  /* Carbon parameter for stage B. */
  static readonly B = new CarbonParameter(
    CARBON_STAGE.B,
    'b',
    DETAILED_PARAMETER_NAME.B,
  );
  /** Carbon parameter for stage D. */
  static readonly D = new CarbonParameter(
    CARBON_STAGE.D,
    'd',
    DETAILED_PARAMETER_NAME.D,
  );

  private constructor(
    /* The carbon stage for the carbon parameter */
    public readonly stage: CarbonStage | TotalCarbonStage,
    /* The form control name for the carbon parameter */
    public readonly formControlName: string,
    /* The detailed parameter name for the carbon parameter */
    public readonly detailedParameterName: DetailedParameterName,
  ) {}
}

/**
 * Carbon Parameters.
 * An array of all carbon parameters.
 */
export const CARBON_PARAMETERS = [
  CarbonParameter.A1_A3,
  CarbonParameter.A4,
  CarbonParameter.A5,
  CarbonParameter.B1,
  CarbonParameter.B2,
  CarbonParameter.B3,
  CarbonParameter.B4,
  CarbonParameter.B5,
  CarbonParameter.B6,
  CarbonParameter.B7,
  CarbonParameter.C1,
  CarbonParameter.C2,
  CarbonParameter.C3,
  CarbonParameter.C4,
  CarbonParameter.B,
  CarbonParameter.D,
];
