import {
  APP_INITIALIZER,
  ErrorHandler,
  inject,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TraceService } from '@sentry/angular';
import { MonitorRequestTimeInterceptor } from '@monitoring/interceptors/monitor-request-time.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PosthogService } from '@monitoring/services/posthog.service';
import { MONITOR_REQUEST_CONFIG } from '@monitoring/tokens/monitor-request-config.token';
import { ADS_ENDPOINT } from '@ads/tokens/ads-endpoint.token';
import { ASSET_ENDPOINT } from '@asset/tokens/asset-endpoint.token';
import { BENCHMARK_ENDPOINT } from '@asset/tokens/benchmark-endpoint.token';
import { SITE_ENDPOINT } from '@asset/tokens/site-endpoint.token';
import { CAMPAIGN_ENDPOINT } from '@campaign/tokens/campaign-endpoint.token';
import { COLLECTION_ENDPOINT } from '@collection/tokens/collection-endpoint.token';
import { DETAILED_ENDPOINT } from '@detailed-assessment/tokens/detailed-endpoint.token';
import { EMBODIED_ENDPOINT } from '@embodied-assessment/tokens/embodied-endpoint.token';
import { ENERGY_MODEL_ENDPOINT } from '@energy-model-assessment/tokens/energy-model-endpoint.token';
import { VERACITY_ENDPOINT } from '@veracity/tokens/veracity-endpoint.token';
import { PROJECT_ENDPOINT } from '@project/tokens/project-endpoint.token';

/**
 * Factory function for MONITOR_REQUEST_CONFIG.
 */
function monitorRequestConfigFactory(): RegExp[] {
  return [
    new RegExp(inject(ADS_ENDPOINT)),
    new RegExp(inject(ASSET_ENDPOINT)),
    new RegExp(inject(BENCHMARK_ENDPOINT)),
    new RegExp(inject(COLLECTION_ENDPOINT)),
    new RegExp(inject(CAMPAIGN_ENDPOINT)),
    new RegExp(inject(ENERGY_MODEL_ENDPOINT)),
    new RegExp(inject(EMBODIED_ENDPOINT)),
    new RegExp(inject(DETAILED_ENDPOINT)),
    new RegExp(inject(PROJECT_ENDPOINT)),
    new RegExp(inject(SITE_ENDPOINT)),
    new RegExp(inject(VERACITY_ENDPOINT)),
  ];
}

/**
 * Monitoring Module.
 * Responsible for providing Sentry and Application Insights.
 */
@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: MONITOR_REQUEST_CONFIG,
      useFactory: monitorRequestConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorRequestTimeInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    TraceService,
    PosthogService,
  ],
})
export class MonitoringModule {
  constructor(@Optional() @SkipSelf() parentModule: MonitoringModule) {
    if (parentModule) {
      throw new Error(
        'MonitoringModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
