import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetModel } from '@asset/models/asset.model';

/**
 * Asset Import Card Component.
 *
 * Responsible for displaying an asset card for
 * an importable asset from DDB along with a
 * button that emits an event for importing an
 * asset.
 */
@Component({
  selector: 'zero-asset-import-card',
  templateUrl: './asset-import-card.component.html',
  styleUrls: ['./asset-import-card.component.scss'],
})
export class AssetImportCardComponent {
  /**
   * Assets name.
   */
  @Input() name!: AssetModel['name'];

  /**
   * Import asset event.
   */
  @Output() import = new EventEmitter<void>();
}
