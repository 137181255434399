import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionsService } from '@auth/services/permissions.service';
import { tap } from 'rxjs';

/**
 * Asset Manager Guard.
 * Checks if the user has permission to manage the current asset.
 */
@Injectable()
export class AssetManagerGuard implements CanActivate {
  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
  ) {}

  /**
   * Can Activate.
   * Checks if the user has permission to manage the current asset.
   */
  canActivate() {
    return this.permissionsService.userCanManageAsset$.pipe(
      tap((userCanManageAsset) => {
        if (!userCanManageAsset) {
          this.router.navigate(['/unauthorized']);
        }
      }),
    );
  }
}
