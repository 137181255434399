import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

/**
 * Loading Select Control Component.
 *
 * This component is a wrapper around the Angular Material `MatSelect` component.
 * It adds a loading state and a clearable state. It also implements the `ControlValueAccessor`
 * interface so that it can be used with Angular Forms.
 */
@Component({
  selector: 'zero-loading-select-control[label]',
  templateUrl: './loading-select-control.component.html',
  styleUrls: ['./loading-select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadingSelectControlComponent),
      multi: true,
    },
  ],
})
export class LoadingSelectControlComponent<T> implements ControlValueAccessor {
  /**
   * Label for the select element.
   * This is required.
   */
  @Input() label: string = '';

  /**
   * Whether the select element should render as loading.
   */
  @Input() loading: boolean = false;

  /**
   * Whether the select element should be disabled.
   */
  @Input() disabled: boolean = false;

  /**
   * Whether the select element should be required.
   */
  @Input() required: boolean = false;

  /**
   * Whether the select element should be clearable.
   */
  @Input() clearable: boolean = false;

  /**
   * The options to render in the select element.
   */
  @Input() options: T[] = [];

  /**
   * Format Option.
   * Format the options `T` to a string for display in the select element.
   * Defaults to `JSON.stringify`.
   */
  @Input() formatOption(option: T) {
    return JSON.stringify(option);
  }

  /**
   * Reference to the MatSelect element.
   */
  @ViewChild('select') select!: MatSelect;

  /**
   * Handle Blur.
   * This is called when the select element is blurred. It calls the `ControlValueAccessor`
   * `onTouched` callback.
   */
  handleBlur() {
    this._onTouched();
  }

  /**
   * Handle value change.
   * This is called when the select elements value changes. It calls the `ControlValueAccessor`
   * `onChange` and `onTouched` callbacks.
   */
  handleValueChange(value: T | null) {
    this._onTouched();
    this._onChange(value);
  }

  /**
   * Handle Clear Selection.
   * This is called when the clear button is clicked. It stops the event from propagating
   * so that the select element does not open. It then calls the `writeValue` method to
   * clear the select element.
   */
  handleClearSelection(event: Event) {
    event.stopPropagation();
    this.select.writeValue(null);
    this._onTouched();
    this._onChange(null);
  }

  /**
   * Callback to be called when the select element changes.
   */
  private _onChange(value: T | null) {}

  /**
   * Callback to be called when the select element is touched.
   */
  private _onTouched() {}

  /**
   * Register a callback to be called when the select element changes.
   */
  registerOnChange(onChange: (value: T | null) => void) {
    this._onChange = onChange;
  }

  /**
   * Register a callback to be called when the select element is touched.
   */
  registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  /**
   * Write the value to the select element.
   */
  writeValue(obj: T | null) {
    this.select?.writeValue(obj);
  }
}
