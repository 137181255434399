<zero-carbon-table-cells class="carbon-table-header--categories">
  <zero-carbon-table-cell>
    <span class="carbon-table-header--category-name">Product</span>
    <span class="carbon-table-header--category--unit"
      >kgCO<sub>2</sub>e/m<sup>2</sup></span
    >
  </zero-carbon-table-cell>
  <zero-carbon-table-cell>
    <span class="carbon-table-header--category-name">Construction</span>
    <span class="carbon-table-header--category--unit"
      >kgCO<sub>2</sub>e/m<sup>2</sup></span
    >
  </zero-carbon-table-cell>
  <zero-carbon-table-cell>
    <span class="carbon-table-header--category-name">In Use</span>
    <span class="carbon-table-header--category--unit"
      >kgCO<sub>2</sub>e/m<sup>2</sup></span
    >
  </zero-carbon-table-cell>
  <zero-carbon-table-cell>
    <span class="carbon-table-header--category-name">Operational</span>
    <span class="carbon-table-header--category--unit"
      >kgCO<sub>2</sub>e/m<sup>2</sup></span
    >
  </zero-carbon-table-cell>
  <zero-carbon-table-cell>
    <span class="carbon-table-header--category-name">End of life</span>
    <span class="carbon-table-header--category--unit"
      >kgCO<sub>2</sub>e/m<sup>2</sup></span
    >
  </zero-carbon-table-cell>
  <zero-carbon-table-cell></zero-carbon-table-cell>
  <zero-carbon-table-cell></zero-carbon-table-cell>
</zero-carbon-table-cells>

<zero-carbon-table-cells class="carbon-table-header--stages">
  <zero-carbon-table-cell
    *ngFor="let carbonParameter of carbonParameters$ | async"
    [ngClass]="utilsService.safeCSSClassName(carbonParameter.stage)"
  >
    {{ carbonParameter.stage | titlecase }}
  </zero-carbon-table-cell>
</zero-carbon-table-cells>
