import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemName } from '@asset/enums/system-name';
import { SubsystemName } from '@core/models/subsystem';
import { UtilsService } from '@core/services/utils.service';

/**
 * Key Component.
 * Responsible for rending a key for a system name or subsystem name. A key
 * is a filled colored circle to represent in scope and a outlined circle icon
 * to represent out of scope system or subsystems followed by the name.
 */
@Component({
  selector: 'zero-key[name]',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyComponent {
  /**
   * Name.
   * The system or subsystem name to render key for.
   */
  @Input() name!: SystemName | SubsystemName;

  /**
   * Scope.
   * The scope fo the system or subsystem to render key for.
   */
  @Input() scope = false;

  constructor(private readonly utilsService: UtilsService) {}

  /**
   * Safe CSS Class Name.
   * Converts string to safe css class name.
   */
  readonly safeCSSClassName = this.utilsService.safeCSSClassName;
}
