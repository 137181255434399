<h1 mat-dialog-title>{{ data.title }}</h1>
<p mat-dialog-content [innerHTML]="data.message"></p>
<mat-dialog-actions align="end">
  <mat-checkbox *ngIf="data.skippable" [formControl]="checkboxControl"
    >Skip next time
  </mat-checkbox>
  <ng-container *ngIf="actionType() === 'string'; else confirmAndReject">
    <button
      mat-flat-button
      color="accent"
      name="confirm"
      cdkFocusInitial
      (click)="handleAcknowledgement('confirm')"
    >
      {{ data.action }}
    </button>
  </ng-container>
  <ng-template #confirmAndReject>
    <button
      mat-flat-button
      name="reject"
      (click)="handleAcknowledgement('reject')"
    >
      {{ data.action.reject }}
    </button>
    <button
      mat-flat-button
      color="accent"
      name="confirm"
      cdkFocusInitial
      (click)="handleAcknowledgement('confirm')"
    >
      {{ data.action.confirm }}
    </button>
  </ng-template>
</mat-dialog-actions>
