import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { UtilsService } from '@core/services/utils.service';
import { AssetDesignLifespan } from '@asset/enums/asset-design-lifespan';
import { ASSET_ENDPOINT } from '@asset/tokens/asset-endpoint.token';
import {
  AssetModel,
  AssetUploadModel,
  AssetImportableModel,
} from '@asset/models/asset.model';
import {
  HttpRequestConfiguration,
  HttpService,
} from '@core/services/http.service';
import { map } from 'rxjs';

/**
 * Asset Service.
 * Responsible for all asset related operations.
 */
@Injectable({ providedIn: 'root' })
export class AssetService {
  constructor(
    private readonly httpService: HttpService,
    private readonly utilsService: UtilsService,
    @Inject(ASSET_ENDPOINT) private readonly assetEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Create Asset.
   * Makes an HTTP request to the API to create an asset.
   * Calls the utils service to convert the expected construction date to the
   * correct format and converts the design lifespan enum value to the correct
   * number value.
   */
  createAsset(
    asset: AssetUploadModel &
      (Record<'site_ref_id', string> | Record<'building_ref_id', string>),
  ) {
    asset = this.utilsService.convertObjectPropsToApiDateFormat(asset, [
      'expected_construction_date',
    ]);
    asset.design_lifespan = Number(
      asset.design_lifespan,
    ) as unknown as AssetDesignLifespan;
    return this.http
      .put<AssetModel>(this.assetEndpoint, asset, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Update Asset.
   * Makes an HTTP request to the API to update an asset.
   * Calls the utils service to convert the expected construction date to the
   * correct format and converts the design lifespan enum value to the correct
   * number value.
   */
  updateAsset(assetId: number, asset: Partial<AssetUploadModel>) {
    return this.http
      .patch<AssetModel>(
        `${this.assetEndpoint}/${assetId}`,
        this.utilsService.convertObjectPropsToApiDateFormat(asset, [
          'expected_construction_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Get Asset.
   * Makes an HTTP request to the API to get an asset.
   * Maps the design lifespan to the correct enum value.
   */
  getAsset(id: number, config?: HttpRequestConfiguration) {
    return this.http
      .get<AssetModel>(`${this.assetEndpoint}/${id}`, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(config),
        this.httpService.performAction(config),
        this.httpService.catchAssetError(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Get Importable Asset.
   * Makes a HTTP request to the API to get an importable asset from DDB.
   */
  getImportableAsset(ddb_ref_id: string) {
    return this.http
      .get<AssetImportableModel>(`${this.assetEndpoint}/ddb/${ddb_ref_id}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssetError(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  /**
   * Search Assets.
   * Makes an HTTP request to the API to search for assets in Zero and DDB.
   * If the response status is 204, returns an empty array for both existing
   * assets and assets to import.
   */
  searchAssets(projectNumber: string) {
    return this.http
      .get<{
        existing_assets: Pick<AssetModel, 'id' | 'name' | 'confidential'>[];
        assets_to_import: Pick<AssetModel, 'ddb_ref_id' | 'name'>[];
      }>(this.assetEndpoint, {
        params: new HttpParams().set('project_number', projectNumber),
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        map((result) => {
          return result.status === HttpStatusCode.NoContent
            ? { existing_assets: [], assets_to_import: [] }
            : result.body ?? { existing_assets: [], assets_to_import: [] };
        }),
        this.httpService.trackError(),
      );
  }

  /**
   * Calculate Gross Area.
   * Calculates the gross area of an asset based on the below ground gross area
   * and above ground gross area.
   */
  calculateGrossArea(asset: AssetModel) {
    return asset.below_grade_gross_area + asset.above_grade_gross_area;
  }
}
