import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { finalize } from 'rxjs';
import { PERFORMANCE } from '@core/tokens/performance.token';
import { MONITOR_REQUEST_CONFIG } from '@monitoring/tokens/monitor-request-config.token';

/**
 * Monitor Request Time Interceptor.
 * Intercepts all requests and monitors the time it takes to complete.
 */
@Injectable()
export class MonitorRequestTimeInterceptor implements HttpInterceptor {
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
    @Inject(MONITOR_REQUEST_CONFIG)
    private readonly monitorRequestConfig: RegExp[],
    @Inject(PERFORMANCE) private readonly performance: Performance,
  ) {}

  /**
   * Intercept.
   * Intercepts all requests and monitors the time it takes to complete.
   * If the request url matches any of the regex patterns in the
   * monitorRequestConfig array, the time it takes to complete is tracked
   * as a metric in Application Insights.
   */
  intercept<T>(request: HttpRequest<T>, next: HttpHandler) {
    const start = this.performance.now();
    return next.handle(request).pipe(
      finalize(() => {
        if (this._shouldMonitor(request)) {
          this.applicationInsightsService.trackMetric({
            name: `Request Time: ${request.method} ${request.url}`,
            average: this.performance.now() - start,
            sampleCount: 1,
          });
        }
      }),
    );
  }

  /**
   * Should Monitor.
   * Check if the request url matches any of the regex patterns in the
   * monitorRequestConfig array.
   */
  private _shouldMonitor<T>(request: HttpRequest<T>) {
    for (const url of this.monitorRequestConfig) {
      if (url.test(request.url)) return true;
    }
    return false;
  }
}
