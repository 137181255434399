<div class="zero-chart zero-carbon-stage-chart">
  <div id="legend-container" #legendContainer></div>
  <canvas
    baseChart
    [data]="barChartData"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [type]="'bar'"
  >
  </canvas>
</div>
