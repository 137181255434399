import { Injectable } from '@angular/core';
import { AuthStore } from '@auth/state/auth.store';
import { AssetStore } from '@asset/state/asset.store';
import { filterNullish } from '@core/utils/filter-nullish';
import { map, combineLatest } from 'rxjs';

/**
 * Permissions Service.
 * Responsible for checking if a user has permissions to perform an action.
 */
@Injectable()
export class PermissionsService {
  /**
   * User can manage projects.
   * Observable that emits true if the user can manage projects and false
   * otherwise.
   */
  userCanManageProjects$ = this.authStore.activeAccount$.pipe(
    map((user) => {
      return user?.idTokenClaims?.roles?.includes('Projects.Manage') ?? false;
    }),
  );

  /**
   * User Can Contribute To Asset.
   * Observable that emits tru if the user can manage the current asset and
   * false otherwise.
   */
  userCanContributeToAsset$ = combineLatest([
    this.authStore.user$.pipe(filterNullish()),
    this.assetStore.asset$,
    this.assetStore.collections$,
  ]).pipe(
    map(([user, asset, collections]) => {
      const userEmail = user.userPrincipalName!.toLowerCase();
      return (
        collections
          .map((collection) => collection.assigned_to.toLowerCase())
          .includes(userEmail) || asset.created_by.toLowerCase() === userEmail
      );
    }),
  );

  /**
   * User Can Manage Asset.
   * Observable that emits true if the user can manage the current asset and false
   * otherwise. The user must be the project manager or project director of the
   * current assets project.
   */
  userCanManageAsset$ = combineLatest([
    this.authStore.user$.pipe(filterNullish()),
    this.assetStore.project$,
  ]).pipe(
    map(([user, project]) => {
      const userEmail = user.userPrincipalName!.toLowerCase();
      return (
        project.ProjectManagerEmail?.toLowerCase() === userEmail ||
        project.ProjectDirectorEmail?.toLowerCase() === userEmail ||
        project.replacement_project_manager?.toLowerCase() === userEmail ||
        project.replacement_project_director?.toLowerCase() === userEmail
      );
    }),
  );

  constructor(
    private readonly authStore: AuthStore,
    private readonly assetStore: AssetStore,
  ) {}
}
