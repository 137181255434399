import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Checkbox Component.
 *
 * Responsible for rendering a checkbox that is non interactive.
 */
@Component({
  selector: 'zero-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  /**
   * Checked.
   *
   * Whether the checkbox is checked or not.
   */
  @Input() checked = false;
}
