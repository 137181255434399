import { Pipe, PipeTransform } from '@angular/core';

/**
 * Order By Type.
 * Defines the type of the value to be ordered.
 */
type OrderByType = string;

/**
 * Order By Direction.
 * Defines the direction of the order to be applied.
 */
type OrderByDirection = 'asc' | 'desc';

/**
 * Order By Pipe.
 * Orders the given value by the given direction.
 */
@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  /**
   * Transform.
   * Orders the given value by the given direction. If the value is an array of
   * strings, it will be ordered by a local compare, unless it contains numbers,
   * in which case it will be ordered by the numbers first, then by the strings.
   */
  transform(
    value: OrderByType[],
    direction: OrderByDirection = 'asc',
  ): OrderByType[] {
    return value.sort((a, b) => {
      if (a.match(/\d+/g) && b.match(/\d+/g)) {
        const aNumbers = a.match(/\d+/g)!.map(Number);
        const bNumbers = b.match(/\d+/g)!.map(Number);
        const aStrings = a.split(/\d+/g);
        const bStrings = b.split(/\d+/g);
        let i = 0;
        while (i < aNumbers.length || i < bNumbers.length) {
          if (
            i >= aNumbers.length ||
            isNaN(aNumbers[i]) ||
            i >= bNumbers.length ||
            isNaN(bNumbers[i])
          ) {
            return direction === 'asc' ? -1 : 1;
          }
          if (aNumbers[i] !== bNumbers[i]) {
            return direction === 'asc'
              ? aNumbers[i] - bNumbers[i]
              : bNumbers[i] - aNumbers[i];
          }
          if (aStrings[i] !== bStrings[i]) {
            return direction === 'asc'
              ? aStrings[i].localeCompare(bStrings[i])
              : bStrings[i].localeCompare(aStrings[i]);
          }
          i++;
        }
      }
      return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    });
  }
}
