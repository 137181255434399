import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionsService } from '@auth/services/permissions.service';
import { tap } from 'rxjs';

/**
 * Asset Contributor Guard.
 * Checks if the user has permission to contribute to the current asset.
 */
@Injectable()
export class AssetContributorGuard implements CanActivate {
  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
  ) {}

  /**
   * Can Activate.
   * Checks if the user has permission to contribute to the current asset.
   */
  canActivate() {
    return this.permissionsService.userCanContributeToAsset$.pipe(
      tap((userCanContributeToAsset) => {
        if (!userCanContributeToAsset) {
          this.router.navigate(['/unauthorized']);
        }
      }),
    );
  }
}
