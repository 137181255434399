import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

/**
 * Excel Upload Modal ID.
 * Defines the ID of the Excel upload modal.
 */
export const EXCEL_UPLOAD_MODAL_ID = 'excel-upload-modal';

/**
 * Excel Upload Modal Config.
 * Defines the configuration of the Excel upload modal.
 */
export const EXCEL_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true,
  id: EXCEL_UPLOAD_MODAL_ID,
  minWidth: '380px',
};

/**
 * Excel Upload Modal Data.
 * Defines the data that is passed to the Excel upload modal.
 */
export interface ExcelUploadModalData {
  /* The name of the Excel template file. */
  excelTemplateFileName: string;
  /* The URL of the Excel template file. */
  excelTemplateUrl: string;
}

/**
 * Excel Upload Modal Result.
 * Defines the result that is returned from the Excel upload modal.
 */
export type ExcelUploadModalResult = File | undefined;

/**
 * Excel Upload Modal Component.
 * Responsible for rendering a modal for uploading Excel files.
 *
 * @example ```
 * this.dialog.open(ExcelUploadModalComponent, {
 *   ...EXCEL_MODAL_CONFIG,
 *   data: <ExcelUploadModalData>{
 *     excelTemplateFileName: 'foo',
 *     excelTemplateUrl: 'foo',
 *   },
 * }).afterClosed().subscribe((file: ExcelUploadModalResult) => {
 *   if (file) {
 *     ...
 *   }
 * });
 *  ```
 */
@Component({
  selector: 'zero-excel-upload-modal',
  templateUrl: './excel-upload-modal.component.html',
  styleUrls: ['./excel-upload-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExcelUploadModalComponent {
  /**
   * Excel File Input Text.
   * The native element of the Excel file input text.
   */
  @ViewChild('excelFileInputText')
  excelFileInputText!: ElementRef<HTMLInputElement>;

  /**
   * Excel File.
   * The uploaded Excel file.
   */
  excelFile?: File;

  constructor(
    private readonly dialogRef: MatDialogRef<ExcelUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ExcelUploadModalData,
  ) {}

  /**
   * Handle Excel File Input Change.
   * Handles the change event of the Excel file input. Gets the file from the
   * target of the event. Sets the value of the Excel file input text to the
   * name of the file if it exists to display the file name within the material
   * form field input.
   */
  handleExcelFileInputChange(event: Event) {
    const excelFileInput = event.target as HTMLInputElement;
    const excelFile = excelFileInput.files?.[0];
    if (
      excelFile &&
      excelFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      this.excelFileInputText.nativeElement.value = excelFile.name;
      this.excelFile = excelFile;
    }
  }

  /**
   * Handle Excel File Drop.
   * Handles the drop event of the Excel file input. Gets the file from the
   * data transfer of the event. Sets the value of the Excel file input text to
   * the name of the file if it exists to display the file name within the
   * material form field input.
   */
  handleExcelFileDrop(event: DragEvent) {
    event.preventDefault();
    const excelFile = event.dataTransfer?.files?.[0];
    if (
      excelFile &&
      excelFile.type === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      this.excelFileInputText.nativeElement.value = excelFile.name;
      this.excelFile = excelFile;
    }
  }

  /**
   * Handle Cancel.
   * Handles the cancel button click event. Closes the modal.
   */
  handleCancel() {
    this.dialogRef.close();
  }

  /**
   * Handle Upload.
   * Handles the upload button click event. Closes the modal and returns the
   * Excel file.
   */
  handleUpload() {
    this.dialogRef.close(this.excelFile);
  }
}
