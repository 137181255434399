import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { MsalService } from '@azure/msal-angular';
import { GraphService } from '@mgt/services/graph.service';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { PosthogService } from '@monitoring/services/posthog.service';
import { AccountInfo } from '@azure/msal-browser';
import { User } from '@microsoft/microsoft-graph-types';
import { Providers } from '@microsoft/mgt';
import { ProviderState } from '@microsoft/mgt-element';
import { combineLatestWith, iif, of, switchMap, tap } from 'rxjs';

/**
 * Auth Store State.
 * Defines the state of the auth store.
 */
interface AuthStoreState {
  /* Current active account. */
  activeAccount: AccountInfo | null;
  /* Current user information. */
  user: User | null;
}

/**
 * Auth Store.
 * Responsible for handling the state of authentication and the current user.
 */
@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthStoreState> {
  /**
   * The current active account.
   */
  readonly activeAccount$ = this.select(({ activeAccount }) => activeAccount);

  /**
   * The current user information.
   */
  readonly user$ = this.select(({ user }) => user);

  /**
   * Is the current user authenticated. This is true if the active account is not
   * null, otherwise false.
   */
  readonly authenticated$ = this.select(
    ({ activeAccount }) => activeAccount !== null,
  );

  constructor(
    private readonly appInsightsService: ApplicationInsightsService,
    private readonly postHostService: PosthogService,
    private readonly graphService: GraphService,
    private readonly msalService: MsalService,
  ) {
    super({ activeAccount: null, user: null });
  }

  /**
   * Update Active Account.
   * Checks if the user is authenticated and updates the authenticated state.
   * This includes updating the active account, MGT global provider state, PostHog
   * user information and application insights user id.
   */
  readonly updateActiveAccount = this.effect((event$) =>
    event$.pipe(
      switchMap(() => {
        const accounts = this.msalService.instance.getAllAccounts();
        return iif(
          () => accounts.length > 0,
          of(accounts[0]).pipe(
            combineLatestWith(this.graphService.getCurrentUser()),
            tap(([activeAccount, user]) => {
              Providers.globalProvider.setState(ProviderState.SignedIn);
              this.patchState({ activeAccount: activeAccount, user: user });
              this.msalService.instance.setActiveAccount(activeAccount);
              this.postHostService.identify(activeAccount);
              this.appInsightsService.setUserId(user);
            }),
          ),
          of(undefined).pipe(
            tap(() => {
              Providers.globalProvider.setState(ProviderState.SignedOut);
              this.patchState({ activeAccount: null, user: null });
              this.msalService.instance.setActiveAccount(null);
              this.postHostService.reset();
              this.appInsightsService.clearUserId();
            }),
          ),
        );
      }),
    ),
  );
}
