import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assignedUsersLengthFilter',
})
export class AssignedUsersLengthFilterPipe implements PipeTransform {
  transform(assignees: string[], created_by: string, max: number): string[] {
    return assignees
      .sort((assignee: string) => (assignee === created_by ? -1 : 1))
      .slice(0, max);
  }
}
