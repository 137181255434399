import { Component } from '@angular/core';

/**
 * Carbon Table Cells.
 *
 * Responsible for rending a group of `CarbonTableCellComponent`s.
 * Provide cells with the use of content projection.
 */
@Component({
  selector: 'zero-carbon-table-cells',
  templateUrl: './carbon-table-cells.component.html',
  styleUrls: ['./carbon-table-cells.component.scss'],
})
export class CarbonTableCellsComponent {}
