import { ConfigService } from '@core/services/config.service';
import { inject, InjectionToken } from '@angular/core';

/**
 * Site Endpoint.
 * Injection token for the site endpoint.
 */
export const SITE_ENDPOINT = new InjectionToken<string>('SITE_ENDPOINT', {
  factory: () => `${inject(ConfigService).config.apiURL}/sites`,
});
