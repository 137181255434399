/**
 * Mapping Variable.
 * Defines a mapping variable.
 */
export const MAPPING_VARIABLE = {
  /* The gross facade area mapping variable. */
  GrossFacadeArea: 'Gross facade area',
  /* The gross floor area mapping variable. */
  GrossFloorArea: 'Gross floor area',
  /* The above ground area mapping variable. */
  AboveGroundArea: 'Above ground area',
  /* The below ground area mapping variable. */
  BelowGroundArea: 'Below ground area',
  /* The absolute mapping variable. */
  Absolute: 'Absolute (x1)',
} as const;

/**
 * Mapping Variable.
 */
export type MappingVariable =
  typeof MAPPING_VARIABLE[keyof typeof MAPPING_VARIABLE];
