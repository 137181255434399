import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Required Fields Components.
 * Renders a required fields message.
 */
@Component({
  selector: 'zero-required-fields',
  templateUrl: './required-fields.component.html',
  styleUrls: ['./required-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequiredFieldsComponent {}
