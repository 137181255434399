import { Inject, Injectable } from '@angular/core';
import { AuthStore } from '@auth/state/auth.store';
import { PopupRequest } from '@azure/msal-browser';
import { EMPTY, firstValueFrom, switchMap, map, tap } from 'rxjs';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  MgtPerson,
  MgtPersonCard,
  Providers,
  SimpleProvider,
} from '@microsoft/mgt';

/**
 * Mgt Provider Service.
 * Responsible for setting up the MGT component configuration and the
 * MGT global provider.
 */
@Injectable()
export class MgtService {
  constructor(
    private authStore: AuthStore,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {}

  /* istanbul ignore next */
  /**
   * Initialize.
   * Sets up the MGT component configuration and the MGT global provider.
   * This is required to prevent the MGT components from making calls to
   * the Microsoft Graph API that the user is not authorized to make. The
   * MGT global provider is set to a simple provider that uses the MSAL
   * Angular acquireTokenSilent method to acquire an access token for the
   * Microsoft Graph API.
   */
  initialize() {
    MgtPersonCard.config.sections.organization = false;
    MgtPersonCard.config.sections.files = false;
    MgtPersonCard.config.sections.mailMessages = false;
    MgtPersonCard.config.sections.organization = false;
    MgtPersonCard.config.sections.profile = false;
    MgtPersonCard.config.useContactApis = false;
    MgtPerson.config.useContactApis = false;
    Providers.globalProvider = new SimpleProvider(
      async (scopes) =>
        await firstValueFrom(
          this.msalService
            .acquireTokenSilent({ scopes })
            .pipe(map((result) => result.accessToken)),
        ),
      async () =>
        await firstValueFrom(
          this.msalService
            .loginPopup(this.msalGuardConfig.authRequest as PopupRequest)
            .pipe(
              tap(() => this.authStore.updateActiveAccount()),
              switchMap(() => EMPTY),
            ),
        ),
      async () =>
        await firstValueFrom(
          this.msalService.logoutPopup().pipe(
            tap(() => this.authStore.updateActiveAccount()),
            switchMap(() => EMPTY),
          ),
        ),
    );
  }
}
