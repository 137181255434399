import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CarbonParameter } from '@core/types/carbon-parameter';
import { UtilsService } from '@core/services/utils.service';
import { map } from 'rxjs/operators';

/**
 * Carbon Param Table Header Component State.
 */
interface CarbonParamTableHeaderStateModel {
  /* Total carbon parameters */
  totalCarbonParameters: Array<CarbonParameter>;
}

/**
 * Carbon Table Header Component.
 *
 * Responsible for rendering the header of the carbon
 * parameter breakdown table. The header can be customized
 * to display all carbon params in the full breakdown or
 * the rollup total carbon params by setting the carbon stages
 * input.
 */
@Component({
  selector: 'zero-carbon-table-header',
  templateUrl: './carbon-table-header.component.html',
  styleUrls: ['./carbon-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore],
})
export class CarbonTableHeaderComponent {
  /**
   * Set total carbon stages to be rendered. A full breakdown
   * of all stages can be rendered or if provided the total carbon
   * stage will be rendered instead of its intertidal stages.
   */
  @Input() set totalCarbonParameters(value: Array<CarbonParameter> | null) {
    if (value) this.setCarbonParameters(value);
  }

  /**
   * Carbon parameters to be rendered.
   */
  carbonParameters$ = this.componentStore
    .select(({ totalCarbonParameters }) => totalCarbonParameters)
    .pipe(map(this.utilsService.carbonParameters));

  constructor(
    private componentStore: ComponentStore<CarbonParamTableHeaderStateModel>,
    public utilsService: UtilsService,
  ) {
    this.componentStore.setState({ totalCarbonParameters: [] });
  }

  /**
   * Set total carbon parameters state.
   */
  private readonly setCarbonParameters = this.componentStore.updater<
    Array<CarbonParameter>
  >((state, value) => ({
    ...state,
    totalCarbonParameters: value,
  }));
}
